import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';

import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { setCancelCurrentMemberShip, setGetPaymentPlanEnterpriseBusiness } from '../../slices/paymentPlans';
import { onErrorStopLoad } from '../../slices/paymentPlans';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* getPaymentPlansEnterprise(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PAYMENTPLANS.ENTERPRISE_PLAN}/?type=${action.payload.type}`);
    if (resp.status) {
      yield put(setGetPaymentPlanEnterpriseBusiness(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      //  toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetPaymentPlanEnterpriseBusiness({}));
    // yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getPaymentPlansSmall(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PAYMENTPLANS.SMALL_PLAN}/?type=${action.payload.type}`);
    if (resp.status) {
      yield put(setGetPaymentPlanEnterpriseBusiness(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      //  toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetPaymentPlanEnterpriseBusiness({}));
   yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* CancelcurrentMemberShip(action) {

  try {
    const dataToSend = { ...action.payload }
    delete dataToSend.cb

    const resp = yield call(ApiClient.patch, action.url = ApiPath.PAYMENTPLANS.CANCEL_CURRENT_MEMBERSHIP, action.params = dataToSend);
    if (resp.status) {
      yield put(setCancelCurrentMemberShip(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCancelCurrentMemberShip());
    yield put(onErrorStopLoad())
  }
}



function* paymentPlanSaga() {
  yield all([
    takeLatest('paymentPlan/getPaymentPlanEnterpriseBusiness', getPaymentPlansEnterprise),
    takeLatest('paymentPlan/getPaymentPlanSmallBusiness', getPaymentPlansSmall),
    takeLatest('paymentPlan/cancelcurrentMemberShip', CancelcurrentMemberShip),
  ])
}

export default paymentPlanSaga;

