import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../../utilities/api';
import { setRecentlyDeleteDocuments,onErrorStopLoad } from '../../../slices/documents/recentlyDeleteDocument';
import ApiPath from '../../../../constants/apiPath';
import { toast } from 'react-toastify';


function* RecentlyDeleteOpportunities(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.DocumentApiPath.RecentlyDeleteApiPath.RECENTLY_DELETE_DOCUMENTS}?page=${action.payload.page}&limit=${action.payload.limit}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setRecentlyDeleteDocuments(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setRecentlyDeleteDocuments({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* userRecentlyDeleteDocuments() {
  yield all([
    takeLatest("recentlyDeleteDocument/recentlyDeleteDocuments", RecentlyDeleteOpportunities),
  ])
}

export default userRecentlyDeleteDocuments;