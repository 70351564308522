import React from 'react'
import DatePicker from "react-datepicker";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import FadeInImage from '../../../utilities/FadeInImage';
import moment from 'moment';
import * as Images from "../../../utilities/images";
const UserAcclamation = ({ choosePlan,
    chooseBusinessType,
    authSelector,
    id,
    leaveEndorsements,
    companyInfo,
    leaveEndorsementsDate,
    onChangeDateLeaveEndorsement,
    leaveEndorsementsDescription,
    setLeaveEndorsementsDescription,
    CancleEndrosement,
    SubmitEndrosement,
    userById,
    UserProfileNavigation,
    setLeaveEndorsements
}) => {
    return (
        <>
            {(choosePlan == "Bronze" && chooseBusinessType === 1) ||
                (choosePlan == "Bronze" && chooseBusinessType === 2) ? (
                ""
            ) : (
                <div className="containerBoxright mt-4">
                    {leaveEndorsements == true ? (
                        <div className="newsBox_ endorsement_">
                            <h4 className="headingTitle_">
                                <b>Leave Acclamation</b>
                            </h4>
                            <form className="signupForm row justify-content-center mt-3">
                                <div className="form-group col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="customFormControl"
                                        placeholder="Company Name"
                                        disabled
                                        value={
                                            companyInfo?.companyDetails?.company_name
                                        }
                                    />
                                </div>
                                <div className="form-group col-md-5 offset-1 mb-3">
                                    <DatePicker
                                        className="customFormControl"
                                        selected={leaveEndorsementsDate}
                                        onChange={onChangeDateLeaveEndorsement}
                                        dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                        placeholderText="Month Day, Year" // Example placeholder text
                                    />
                                </div>
                                <div className="form-group col-md-12 mb-3">
                                    <textarea
                                        name="description"
                                        type="text"
                                        className="customFormControl"
                                        placeholder="Type here..."
                                        rows="4"
                                        value={leaveEndorsementsDescription}
                                        onChange={(e) =>
                                            setLeaveEndorsementsDescription(
                                                e.target.value
                                            )
                                        }
                                    ></textarea>
                                </div>
                                <div className="endorsementsButton text-end">
                                    <button
                                        type="button"
                                        className="subsmallBtnblue_"
                                        onClick={() => CancleEndrosement()}
                                    >
                                        Cancel Acclamation
                                    </button>
                                    <button
                                        type="button"
                                        className="subsmallBtngreen_"
                                        onClick={() => SubmitEndrosement()}
                                    >
                                        Submit Acclamation
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <>
                            <div className="newsBox_ endorsement_ ">
                                <Tabs defaultIndex={1}>
                                    <div className="endorTabs">
                                        <h4 className="headingTitle_">
                                            <b>Acclamations</b>
                                        </h4>
                                        <TabList>
                                            <Tab>Given</Tab>
                                            <Tab>Received</Tab>
                                        </TabList>
                                    </div>
                                    <div className="row mt-4">
                                        <TabPanel>
                                            {userById?.endorsement_given?.length > 0 ? (
                                                <>
                                                    {userById?.endorsement_given?.map(
                                                        (data, index) => {
                                                            var companyInfo =
                                                                data?.receiver_details
                                                                    ?.is_invited == 2
                                                                    ? data?.receiver_details
                                                                        ?.user_selected_company_information
                                                                        ?.user_company_information
                                                                    : data?.receiver_details
                                                                        ?.user_company_information;

                                                            let userProfile = data?.receiver_details?.user_profile
                                                            return (
                                                                <>
                                                                    <div className="messageDetail">
                                                                        <div
                                                                            className=" d-flex  align-items-center  mt-4"
                                                                            key={Math.random()}
                                                                        >
                                                                            <div className="imageDiv">
                                                                                <FadeInImage
                                                                                    onClick={() =>
                                                                                        // handlePageRedirect(
                                                                                        //   companyInfo?.company_name,
                                                                                        //   companyInfo?.id
                                                                                        // )
                                                                                        UserProfileNavigation(data.receiver_details, data.receiver_details?.is_invited == 2 ? false : "company")

                                                                                        //         UserProfileNavigation(
                                                                                        // `${data?.receiver_details?.user_profile?.first_name}-${data?.receiver_details?.user_profile?.last_name}`,
                                                                                        // data
                                                                                        // ?.receiver_details
                                                                                        // ?.id
                                                                                        // )
                                                                                    }
                                                                                    src={
                                                                                        companyInfo?.company_logo ??
                                                                                        Images.puzzleProfile
                                                                                    }
                                                                                    className="userProfile me-3"
                                                                                    style={{
                                                                                        border:
                                                                                            "1px solid #c3c3c3",
                                                                                        padding: "2px",
                                                                                        borderRadius: "8px",
                                                                                    }}
                                                                                    alt="UserImage"
                                                                                />
                                                                            </div>
                                                                            <div className="postsDetails">
                                                                                <h5 className="innerSubtextSmall d-flex align-items-center">
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            // handlePageRedirect(
                                                                                            //   companyInfo?.company_name,
                                                                                            //   companyInfo?.id
                                                                                            // )
                                                                                            UserProfileNavigation(data.receiver_details, data.receiver_details?.is_invited == 2 ? false : "company")

                                                                                            // UserProfileNavigation(
                                                                                            //     `${data?.receiver_details?.user_profile?.first_name}-${data?.receiver_details?.user_profile?.last_name}`,
                                                                                            //     data
                                                                                            //         ?.receiver_details
                                                                                            //         ?.id
                                                                                            // )
                                                                                        }
                                                                                    >
                                                                                        <strong>
                                                                                            {companyInfo?.company_name}
                                                                                        </strong>
                                                                                    </span>
                                                                                </h5>
                                                                                <p class="developerAcc mb-0">
                                                                                    {`${userProfile?.first_name} ${userProfile?.last_name}`}
                                                                                </p>
                                                                                <span class="weeksTxt">
                                                                                    {moment(
                                                                                        data?.created_at
                                                                                    ).fromNow()}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <p className="discription_ mt-2">
                                                                            {data?.endorsement}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            ) : (
                                                <p>No Acclamations Given</p>
                                            )}
                                        </TabPanel>
                                        <TabPanel>
                                            {userById?.endorsement_received?.length >
                                                0 ? (
                                                <>
                                                    {userById?.endorsement_received?.map(
                                                        (data, index) => {
                                                            var companyInfo =
                                                                data?.sender_details
                                                                    ?.is_invited == 2
                                                                    ? data?.sender_details
                                                                        ?.user_selected_company_information
                                                                        ?.user_company_information
                                                                    : data?.sender_details
                                                                        ?.user_company_information;
                                                            let userProfile = data?.sender_details?.user_profile
                                                            return (
                                                                <>
                                                                    <div className="messageDetail">
                                                                        <div
                                                                            className=" d-flex  align-items-center  mt-4"
                                                                            key={Math.random()}
                                                                        >
                                                                            <div className="imageDiv">
                                                                                <FadeInImage
                                                                                    onClick={() =>
                                                                                        // handlePageRedirect(
                                                                                        //   companyInfo?.company_name,
                                                                                        //   companyInfo?.id
                                                                                        // )
                                                                                        UserProfileNavigation(data?.sender_details, data?.sender_details?.is_invited == 2 ? false : "company")

                                                                                        // UserProfileNavigation(
                                                                                        //     `${data?.sender_details?.user_profile?.first_name}-${data?.sender_details?.user_profile?.last_name}`,
                                                                                        //     data?.sender_details
                                                                                        //         ?.id
                                                                                        // )
                                                                                    }
                                                                                    src={
                                                                                        companyInfo?.company_logo ??
                                                                                        Images.puzzleProfile
                                                                                    }
                                                                                    className="userProfile me-3"
                                                                                    style={{
                                                                                        border:
                                                                                            "1px solid #c3c3c3",
                                                                                        padding: "2px",
                                                                                        borderRadius: "8px",
                                                                                    }}
                                                                                    alt="UserImage"
                                                                                />
                                                                            </div>
                                                                            <div className="postsDetails">
                                                                                <h5 className="innerSubtextSmall d-flex align-items-center mb-1">
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            // handlePageRedirect(
                                                                                            //   companyInfo?.company_name,
                                                                                            //   companyInfo?.id
                                                                                            // )
                                                                                            UserProfileNavigation(data?.sender_details, data?.sender_details?.is_invited == 2 ? false : "company")
                                                                                            // UserProfileNavigation(
                                                                                            //         `${data?.sender_details?.user_profile?.first_name}-${data?.sender_details?.user_profile?.last_name}`,
                                                                                            //         data
                                                                                            //             ?.sender_details
                                                                                            //             ?.id
                                                                                            //     )
                                                                                        }
                                                                                    >
                                                                                        <strong>
                                                                                            {
                                                                                                companyInfo?.company_name
                                                                                            }{" "}
                                                                                        </strong>
                                                                                    </span>
                                                                                </h5>
                                                                                <p class="developerAcc mb-0">
                                                                                    {`${userProfile?.first_name || ""} ${userProfile?.last_name || "User Not Available"}`}
                                                                                </p>

                                                                                <span className="weeksTxt">
                                                                                    {moment(
                                                                                        data?.created_at
                                                                                    ).fromNow()}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <p className="discription_ mt-2">
                                                                            {data?.endorsement}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            ) : (
                                                <p>No Acclamations Received</p>
                                            )}
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                            {id &&
                                authSelector?.userInfo?.user
                                    ?.user_company_information?.id !==
                                companyInfo?.companyDetails?.id && (
                                    <p className="text-center mt-3">
                                        Have you worked with this company ?
                                        <span
                                            className="docText mx-2"
                                            onClick={(id) =>
                                                setLeaveEndorsements(true, id)
                                            }
                                        >
                                            <u>Click here to leave Acclamation</u>
                                        </span>
                                    </p>
                                )}
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default UserAcclamation