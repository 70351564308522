import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    addSupport: (state) => {
      state.loading = true;
    },
    setaddSupport: (state, action) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
    getAllResources: (state) => {
      state.loading = true;
    },
    setGetAllResources: (state) => {
      state.loading = false;
    },
    categoryData: (state) => {
      state.loading = true;
    },
    setCategoryData: (state) => {
      state.loading = false;
    },
    articleInfo: (state) => {
      state.loading = true;
    },
    setArticleInfo: (state) => {
      state.loading = false;
    }
  },
});

// Action creators are generated for each case reducer function
export const { addSupport, setaddSupport, onErrorStopLoad, getAllResources, setGetAllResources , categoryData, setCategoryData, articleInfo, setArticleInfo} =
  supportSlice.actions;

export default supportSlice.reducer;
