import React from 'react'
import * as Images from "../utilities/images";
import FadeInImage from './FadeInImage';
const SkeltonLoader = () => {
  return (
    <>
      <div class="authNavBar">
        <nav class="navbarContain navInnerhaed">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-6 col-sm-3 col-md-4 col-lg-2 text-center">
                <div class="navbar-brand" >
                  <FadeInImage src={Images.logoWhite} />
                </div>
              </div>
              <div class="col-6 col-sm-9 col-md-8 col-lg-10 text-end">

              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-4 col-xl-6 col-xxl-3'>
            <div class="card loading">
              <div class="image"></div>
              <div class="content">
                <h4></h4>
                <div class="description"></div>
              </div>
              <div class="image mt-3"></div>
              <div class="content">
                <h4></h4>
                <h4></h4>
                <h4></h4>
                <h4></h4>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-8 col-xl-6 col-xxl-5">
            <div class="card loading">
              <div class="content">
                <h4></h4>
                <h4></h4>
              </div>
              <div class="image"></div>
              <div class="image mt-4"></div>
              <div class="image mt-3"></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
            <div class="card loading">
              <div class="image"></div>
              <div class="content">
                <h4></h4>
                <div class="description"></div>
              </div>
              <div class="image mt-3"></div>
              <div class="content">
                <h4></h4>
                <h4></h4>
                <h4></h4>
                <h4></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SkeltonLoader