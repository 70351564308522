import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    companyInformation: {},
    createEndrosement: {},
    loading: false,
    updateLoader: false,
}

export const companyInformationSlice = createSlice({
    name: 'companyInformation',
    initialState,
    reducers: ({
        getCompanyInformation: (state) => {
            state.loading = true
        },
        setGetCompanyInformation: (state, action) => {
            state.loading = false
            state.companyInformation = action.payload
        },
        getUpdatedCompanyInformation: (state) => {
            state.updateLoader = true
        },
        setGetUpdatedCompanyInformation: (state, action) => {
            state.updateLoader = false
            state.companyInformation = action.payload
        },
        createEndrosement: (state) => {
            state.loading = true
        },
        setCreateEndrosement: (state, action) => {
            state.loading = false
            state.createEndrosement = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const {
    getCompanyInformation,
    setGetCompanyInformation,
    createEndrosement,
    setCreateEndrosement,
    getUpdatedCompanyInformation,
    setGetUpdatedCompanyInformation,
    onErrorStopLoad
} = companyInformationSlice.actions

export default companyInformationSlice.reducer