//userProfile
import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import OpengraphReactComponent from "opengraph-react";
import InfiniteScroll from "react-infinite-scroll-component";
import Linkify from "react-linkify";
import { useDispatch } from "react-redux";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useNavigation,
} from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import swal from "sweetalert";

// import SubmitEndrosment from "./submitEndrosment";
import { useAdvanceSearchSelector } from "../../../redux/selector/advanceSearch";
import { useAuthSelector } from "../../../redux/selector/auth";
// import SendMessage from "./sendMessage";
import { useCompanyInformationSelector } from "../../../redux/selector/companyInformation";
import {
  getUpdatedUserDeatails,
  getUserDeatails,
} from "../../../redux/slices/advanceSearch";
import {
  createEndrosement,
  getCompanyInformation,
} from "../../../redux/slices/companyInformation";
import {
  updateRequestConnection,
  connectionFollow,
  connectionUnfollow,
  addConnection,
  connectionRequsetCancel,
  connectionRequsetUnfriend,
} from "../../../redux/slices/connection";
import {
  allPostComment,
  commentLike,
  deletePost,
  disableEnableComment,
  getPostSingleDetails,
  postFeature,
  userPostComment,
  userPostLike,
} from "../../../redux/slices/dashboard";
import { updatingInfoKeys } from "../../../utilities/helper";
import * as Images from "../../../utilities/images";
import {
  useUserCompanyNavigation,
  useUserNavigation,
  useUserProfileNavigation,
} from "../../commonFunctions";
import CommonSpinner from "../../components/common/CommonSpinner";
import CustomModal from "../../components/common/shared/CustomModal";
import SendMessage from "../companyfrontProfile/sendMessage";
import SubmitEndrosment from "../companyfrontProfile/submitEndrosment";
import AddNewPost from "../dashboard/modals/addNewPost";
import { useDropzone } from "react-dropzone";
import ImageCropper from "../../components/common/ImageCropper/ImageCropper";
import { companyBannerUplaod, companyUpdateLogoBanner, userCompanyProfileUpdate, userPhotoUpload, userProfileUpdate } from "../../../redux/slices/auth";
import getCroppedImg from "../../components/common/ImageCropper/cropImage";
import AllPostLikeUser from "../dashboard/allPostLikeUser";
import SharedPost from "../dashboard/SharedPost";
import { getChatHead } from "../../../redux/slices/opportunity";
import { getChatHead as getChatList } from "../../../redux/slices/messages";
import FadeInImage from "../../../utilities/FadeInImage";
import BusinessResource from "./BusinessResource";
import UserCertificate from "../myCompanyProfilePage/UserCertificate";
import CompanyDetails from "./CompanyDetails";
import UserAcclamation from "./UserAcclamation";
import SpinLoader from "../../../utilities/SpinLoader";
import VideoPlayer from "../dashboard/videoPlayer";

const UserProfile = () => {
  const companyInfoSelector = useAdvanceSearchSelector();
  const UserProfileNavigation = useUserProfileNavigation();
  const userNavigation = useUserNavigation();
  const [showOpportunity, setShowOpportunity] = useState(true);

  const [userInformation, setUserInformation] = useState();
  const { id } = useParams();
  const [userById, setUserById] = useState();
  const authSelector = useAuthSelector();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [companyInfo, setCompanyInfo] = useState("");
  const [companyCurrentOpportunity, setCompanyCurrentOpportunity] = useState(
    []
  );
  const [leaveEndorsements, setLeaveEndorsements] = useState(false);
  const [editId, setEditId] = useState("");
  const [leaveEndorsementsCompanyName, setleaveEndorsementsCompanyName] =
    useState("");
  const [leaveEndorsementsDate, setLeaveEndorsementsDate] = useState(
    new Date()
  );
  const [leaveEndorsementsDescription, setLeaveEndorsementsDescription] =
    useState("");
  const [leaveEndorsementsReceiverId, setleaveEndorsementsReceiverId] =
    useState("");
  const [leaveEndorsementsInfo, setLeaveEndorsementsInfo] = useState("");
  const [key, setKey] = useState(Math.random());
  const [showNestedReply, setShowNestedReply] = useState(false);
  const [nestedReply, setNestedReply] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const checkUrl = (value) => {
    const urlRegex =
      /\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/g;
    const urls = value.match(urlRegex) ?? "";

    for (let i = 0; i < urls.length; i++) {
      if (urls[i].startsWith("https://")) {
        continue;
      } else if (urls[i].startsWith("https:/")) {
        urls[i] = urls[i].replace("https:/", "https://");
      } else if (urls[i].startsWith("https:")) {
        urls[i] = urls[i].replace("https:", "https://");
      } else if (urls[i].startsWith("https")) {
        urls[i] = urls[i].replace("https", "https://");
      } else {
        urls[i] = "https://" + urls[i];
      }
    }
    return urls;
  };

  const chooseBusinessType =
    authSelector?.userStep?.user?.business_type ??
    authSelector?.userStep?.business_type;
  const choosePlan =
    authSelector?.userStep?.user?.membership_details?.name ??
    authSelector?.userStep?.membership_details?.name;

  //featured post data
  const userInfo = authSelector?.userInfo?.user;
  const [allPost, setAllPostList] = useState([]);
  const [userDeatil, setUserDeatil] = useState();
  const [editFlag, setEditFlag] = useState(false);
  const [editPostID, setEditPostID] = useState(false);
  const [post, setPost] = useState("");
  const [allCommentsLoad, setAllCommentsLoad] = useState(false);
  const [mainTags, setMainTags] = useState({
    companies: [],
    users: [],
  });
  const [selectedIds, setSelectedIds] = useState({
    companies: [],
    users: [],
  });
  const [photo, setPhotoUpload] = useState({
    uploadPhoto: "",
    flag: "",
  });
  const [videoUrlOriginal, setVideoUrlOriginal] = useState();
  const [photoInfo, setPhotoInfo] = useState();
  const [page, setPages] = useState(1);
  const [postIndexMoreComments, setPostIndexMoreComments] = useState("");
  const [allCommentList, setAllCommentList] = useState([]);
  const [allCommentData, setAllCommentData] = useState({});
  const [replyFlag, setReplyFlag] = useState(false);
  const [replyName, setReplyName] = useState("");
  const [replyId, setReplyId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [postID, setPostID] = useState("");
  const [comment, setComment] = useState("");
  const [showNodata, setShowNoData] = useState(false);
  const [updatingWhat, setUpdatingWhat] = useState(null);
  // // API for post Feature ..............
  // const postFetures = (id, flag, index) => {
  //     let params = {
  //         post_id: id,
  //         feature_status: flag,
  //     };
  //     dispatch(
  //         postFeature({
  //             ...params,
  //             cb(res) {
  //                 if (res.status) {
  //                     CompanyInformation(id);
  //                 }
  //             },
  //         })
  //     );
  // };

  const maxTextLength = 200

  const seeMore = (id) => {
    setAllPostList(prev=>{
      return prev.map(item=>{
        return item.id == id ? {...item, expanded: true} : item
      })
    })
  }
  const seeLess = (id) => {
    setAllPostList(prev=>{
      return prev.map(item=>{
        return item.id == id ? {...item, expanded: false} : {...item}
      })
    })
  }

  const renderPostText = (message,isExpanded=false,post) => {
    if (!message) return null;

    if (isExpanded || message.length <= maxTextLength) {
      return (
        <>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="_blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {message.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Linkify>
          {message.length > maxTextLength && (
            <span
            onClick={()=>{seeLess(post?.id)}}
              style={{ color: "blue", cursor: "pointer" }}
            >
              Show Less
            </span>
          )}
        </>
      );
    }
    return (
      <>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="_blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {message.substring(0, maxTextLength)}...
        </Linkify>
        <span
          onClick={()=>{seeMore(post?.id)}}
          style={{ color: "blue", cursor: "pointer" }}
        >
          Read More
        </span>
      </>
    );
  }

  // for close Modal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // company information
  const CompanyInformation = (cmpanyid) => {
    let params = {
      // id: id,
      id: cmpanyid ? cmpanyid : id ? id : authSelector.userInfo.user.id,
    };
    dispatch(
      getUserDeatails({
        ...params,
        cb(res) {
          if (res.status != 204) {
            setUserById(res?.data?.payload);
            setUserInformation(res?.data?.payload?.userDetails);
            setUserDeatil(
              res?.data?.payload?.userDetails?.is_invited == 2
                ? res?.data?.payload?.userDetails
                  ?.user_selected_company_information
                  ?.user_company_information
                : res?.data?.payload?.userDetails?.user_company_information
            );
            console.log(res?.data?.payload?.userDetails,'userde')
            setCompanyInfo({
              companyDetails:
                res?.data?.payload?.userDetails?.is_invited !== 0
                  ? res?.data?.payload?.userDetails
                    ?.user_selected_company_information
                    ?.user_company_information
                  : res?.data?.payload?.userDetails?.user_company_information,
            });
            // setAllPostList(res.data?.payload?.feature_post)
            setAllPostList(res?.data?.payload?.userPosts?.posts ?? []);
            setCompanyCurrentOpportunity(
              res?.data?.payload?.companyPostOpportunity
            );
            setleaveEndorsementsReceiverId(
              res?.data?.payload?.companyDetails?.user_details?.user_profile
                ?.user_id
            );
          } else {
            setShowNoData(true);
          }
        },
      })
    );
  };
  const updateCompanyInformation = (cmpanyid) => {
    let params = {
      // id: id,
      id: cmpanyid ? cmpanyid : id ? id : authSelector.userInfo.user.id,
    };
    dispatch(
      getUpdatedUserDeatails({
        ...params,
        cb(res) {
          if (res.status != 204) {
            setUserById(res?.data?.payload);
            setUserInformation(res?.data?.payload?.userDetails);
            setUserDeatil(
              res?.data?.payload?.userDetails?.is_invited == 2
                ? res?.data?.payload?.userDetails
                  ?.user_selected_company_information
                  ?.user_company_information
                : res?.data?.payload?.userDetails?.user_company_information
            );
            setCompanyInfo({
              companyDetails:
                res?.data?.payload?.userDetails?.is_invited == 2
                  ? res?.data?.payload?.userDetails
                    ?.user_selected_company_information
                    ?.user_company_information
                  : res?.data?.payload?.userDetails?.user_company_information,
            });
            // setAllPostList(res.data?.payload?.feature_post)
            setAllPostList(res?.data?.payload?.userPosts?.posts ?? []);
            setCompanyCurrentOpportunity(
              res?.data?.payload?.companyPostOpportunity
            );
            setleaveEndorsementsReceiverId(
              res?.data?.payload?.companyDetails?.user_details?.user_profile
                ?.user_id
            );
            setUpdatingWhat(null);
          } else {
            setShowNoData(true);
          }
        },
      })
    );
  };

  const SubmitEndrosement = (e) => {
    // e.preventDefault();

    if (!leaveEndorsementsDate) {
      toast.error("Please select Acclamation Date");

      return false;
    } else if (!leaveEndorsementsDescription) {
      toast.error("Please enter Description");
      return false;
    }
    let params = {
      company_name: companyInfo?.companyDetails?.company_name,
      project_date: leaveEndorsementsDate,
      receiver_id: id,
      endorsement: leaveEndorsementsDescription,
    };
    dispatch(
      createEndrosement({
        ...params,
        cb(res) {
          if (res.status) {
            setLeaveEndorsementsInfo(res?.data?.payload);
            setleaveEndorsementsCompanyName("");
            setLeaveEndorsementsDate(new Date());
            setLeaveEndorsementsDescription("");
            setLeaveEndorsements(false);
            setModalDetail({ show: true, flag: "submitendorsement" });
            setKey(Math.random());
          }
        },
      })
    );
  };

  // onchange date set date in the state
  const onChangeDateLeaveEndorsement = (date) => {
    setLeaveEndorsementsDate(date);
  };

  //update request
  const updateRequest = (connectionId, flag) => {
    let params = {
      id: connectionId,
      status: flag,
    };
    setUpdatingWhat(
      flag === 2 ? updatingInfoKeys.accept : updatingInfoKeys.reject
    );
    dispatch(
      updateRequestConnection({
        ...params,
        cb(res) {
          if (res.status) {
            updateCompanyInformation(id);
          } else {
          }
        },
      })
    );
  };

  // API for follow connection
  const handleFollowing = () => {
    let params = {
      receiver_id: id,
      company_id: companyInfo?.companyDetails?.id,
    };
    setUpdatingWhat(updatingInfoKeys.follow);
    dispatch(
      connectionFollow({
        ...params,
        cb(res) {
          if (res.status) {
            updateCompanyInformation(id);
          } else {
          }
        },
      })
    );
  };

  // API for unfollow connection
  const handleUnfollowing = () => {
    if (!userInformation?.is_following?.id) {
      return;
    }
    swal({
      text: `Are you sure want unfollow?`,
      dangerMode: false,
      buttons: true,
    }).then((result) => {
      if (result) {
        let params = {
          id: userInformation?.is_following?.id,
        };
        setUpdatingWhat(updatingInfoKeys.follow);
        dispatch(
          connectionUnfollow({
            ...params,
            cb(res) {
              if (res.status) {
                updateCompanyInformation(id);
              } else {
              }
            },
          })
        );
      }
    });
  };

  const CancleEndrosement = () => {
    setleaveEndorsementsCompanyName("");
    setLeaveEndorsementsDate(new Date());
    setLeaveEndorsementsDescription("");
    setLeaveEndorsements(false);
  };

  //for add connection.........
  const handleSubmit = () => {
    let params = {
      receiver_id: id,
      company_id: companyInfo?.companyDetails?.id,
      message: "hello",
    };
    setUpdatingWhat(updatingInfoKeys.request);
    dispatch(
      addConnection({
        ...params,
        cb(res) {
          if (res.status) {
            updateCompanyInformation(id);
          }
        },
      })
    );
  };
  // handle  page change
  const UserCompanyNavigation = useUserCompanyNavigation();

  //handle view all opp
  const handleActiveOpp = () => {
    navigate("/opportunities");
  };

  // handle redirect page
  const hanleRequestApplicatio = (data) => {
    navigate(`/apply/${data?.id}`);
  };

  // API for Cancel connect request
  const updatePendingRequest = () => {
    swal({
      text: `Are you sure want cancel request?`,
      dangerMode: false,
      buttons: true,
    }).then((result) => {
      if (result) {
        let params = {
          id: userById?.connectionStatus?.id,
        };
        setUpdatingWhat(updatingInfoKeys.request);
        dispatch(
          connectionRequsetCancel({
            ...params,
            cb(res) {
              if (res.status) {
                updateCompanyInformation(id);
              } else {
              }
            },
          })
        );
      }
    });
  };

  // API for unfriend connect request
  const updateAcceptRequest = () => {
    swal({
      text: `Are you sure want disconnect connection?`,
      dangerMode: false,
      buttons: true,
    }).then((result) => {
      if (result) {
        let params = {
          id: userById?.connectionStatus?.id,
        };
        setUpdatingWhat(updatingInfoKeys.request);
        dispatch(
          connectionRequsetUnfriend({
            ...params,
            cb(res) {
              if (res.status) {
                updateCompanyInformation(id);
              } else {
              }
            },
          })
        );
      }
    });
  };

  const handleSubmited = (e, id) => {
    //navigate(`/view-profile`, { state: { id: id } });
    e.preventDefault();
    if (showOpportunity) {
      navigate(`/opportunity/${id}/overview`);
    } else {
      navigate(`/query/${id}/overview`);
    }
    // navigate(`/${"view-profile"}/${id}`, {
    //   state: { oppId: companyInfo?.companyDetails?.id },
    // });
    // navigate(`/${"view-profile"}/${id}`);
  };
  const handleMessage = () => {
    if (companyInfo?.companyDetails?.company_connection) {
      if (companyInfo?.companyDetails?.company_connection?.status === 2) {
        if (companyInfo?.chat_head_details && companyInfo?.chat_head_details) {
          navigate(`/account-message/${companyInfo?.chat_head_details?.id}`);
        } else {
          setModalDetail({ show: true, flag: "ComposeMessage" });
          setKey(Math.random());
        }
      } else {
        if (companyInfo?.chatRequest) {
          if (companyInfo?.chatRequest?.status === 1) {
            navigate(`/account-message/${companyInfo?.chat_head_details?.id}`);
          } else if (companyInfo?.chatRequest?.status === 0) {
            if (companyInfo.chatRequest?.sender_id === userInfo?.id) {
              toast.warning(" Message request already sent to the user");
            } else {
              toast.warning("Please check message requests");
              // navigate("/message-requests");
            }
          } else {
            setModalDetail({ show: true, flag: "ComposeMessage" });
            setKey(Math.random());
          }
        } else {
          setModalDetail({ show: true, flag: "ComposeMessage" });
          setKey(Math.random());
        }
      }
    } else {
      if (companyInfo?.chatRequest) {
        if (
          companyInfo?.chat_head_details &&
          companyInfo?.chatRequest?.status === 1
        ) {
          navigate(`/account-message/${companyInfo?.chat_head_details?.id}`);
        } else if (companyInfo.chatRequest?.status === 0) {
          toast.warning(" Message request already sent to the user");
        }
      } else {
        setModalDetail({ show: true, flag: "ComposeMessage" });
        setKey(Math.random());
      }
    }
  };

  const isChadHeadExist = (idToMatch) => {
    let params = {
      search: "",
      searchLabel: "",
    };
    dispatch(
      getChatList({
        ...params,
        cb(res) {
          if (res?.data) {
            let foundObject = res?.data?.payload?.data.find((obj) => {
              return obj?.id === idToMatch;
            });
            if (foundObject) {
              return true;
            } else {
              return false;
            }
          }
        },
      })
    );
  };

  const handleChat = (userId) => {
    if (
      userById &&
      userById.connectionStatus &&
      userById?.connectionStatus?.status !== 2
    ) {
      toast.warn("You should be connected first");
      return;
    } else if (userById && !userById.connectionStatus) {
      toast.warn("You should be connected first");
      return;
    }
    let params = {
      id: userId,
    };
    dispatch(
      getChatHead({
        ...params,
        cb(res) {
          if (res.status) {
            if (
              (choosePlan == "Bronze" && chooseBusinessType === 1) ||
              (choosePlan == "Bronze" &&
                chooseBusinessType === 2 &&
                res?.data?.payload?.connectionRequest === false)
            ) {
              toast.dismiss();
              toast.error(
                "You need to connect to the member or Upgrade your plan"
              );
              return;
            } else {
              if (res?.data?.payload?.connectionRequest) {
                if (res?.data?.payload?.connectionRequest?.status === 2) {
                  if (res?.data?.payload?.chatHeadDetails) {
                    let find = Object.keys(
                      res.data.payload.chatHeadDetails
                    ).find(
                      (key) =>
                        res.data.payload.chatHeadDetails[key] === userInfo.id
                    );
                    let isDeleted =
                      res.data.payload.chatHeadDetails[
                      `deleted_by_${find.split("_")[0]}`
                      ] || false;
                    if (isDeleted) {
                      setModalDetail({ show: true, flag: "ComposeMessage" });
                      setKey(Math.random());
                      return;
                    }
                    navigate(
                      `/account-message/${res?.data?.payload?.chatHeadDetails?.id}`
                    );
                  } else if (
                    res?.data?.payload?.chatRequest &&
                    res?.data?.payload?.chatRequest?.status === 1 &&
                    res?.data?.payload?.chatRequest?.chat_id
                  ) {
                    if (
                      isChadHeadExist(res?.data?.payload?.chatRequest?.chat_id)
                    ) {
                      navigate(
                        `/account-message/${res?.data?.payload?.chatRequest?.chat_id}`
                      );
                    } else {
                      setModalDetail({ show: true, flag: "ComposeMessage" });
                      setKey(Math.random());
                    }
                  } else {
                    setModalDetail({ show: true, flag: "ComposeMessage" });
                    setKey(Math.random());
                  }
                } else {
                  if (res?.data?.payload?.chatRequest) {
                    if (res?.data?.payload?.chatRequest?.status === 1) {
                      let find = Object.keys(
                        res.data.payload.chatHeadDetails
                      ).find(
                        (key) =>
                          res.data.payload.chatHeadDetails[key] === userInfo.id
                      );
                      let isDeleted =
                        res.data.payload.chatHeadDetails[
                        `deleted_by_${find.split("_")[0]}`
                        ] || false;
                      if (isDeleted) {
                        setModalDetail({ show: true, flag: "ComposeMessage" });
                        setKey(Math.random());
                        return;
                      }
                      navigate(
                        `/account-message/${res?.data?.payload?.chatHeadDetails?.id}`
                      );
                    } else if (
                      res?.data?.payload?.chatRequest?.status === 1 &&
                      res?.data?.payload?.chatRequest?.chat_id
                    ) {
                      if (
                        isChadHeadExist(
                          res?.data?.payload?.chatRequest?.chat_id
                        )
                      ) {
                        navigate(
                          `/account-message/${res?.data?.payload?.chatRequest?.chat_id}`
                        );
                      } else {
                        setModalDetail({ show: true, flag: "ComposeMessage" });
                        setKey(Math.random());
                      }
                    } else if (res?.data?.payload?.chatRequest?.status === 0) {
                      if (
                        res?.data?.payload?.chatRequest?.sender_id ===
                        userInfo?.id
                      ) {
                        toast.warning(
                          " Message request already sent to the user"
                        );
                      } else {
                        toast.warning("Please check message requests");
                        // navigate("/message-requests");
                      }
                    } else {
                      setModalDetail({ show: true, flag: "ComposeMessage" });
                      setKey(Math.random());
                    }
                  } else {
                    setModalDetail({ show: true, flag: "ComposeMessage" });
                    setKey(Math.random());
                  }
                }
              } else {
                if (res?.data?.payload?.chatRequest) {
                  if (
                    res?.data?.payload?.chatHeadDetails &&
                    res?.data?.payload?.chatRequest?.status === 1
                  ) {
                    let find = Object.keys(
                      res.data.payload.chatHeadDetails
                    ).find(
                      (key) =>
                        res.data.payload.chatHeadDetails[key] === userInfo.id
                    );
                    let isDeleted =
                      res.data.payload.chatHeadDetails[
                      `deleted_by_${find.split("_")[0]}`
                      ] || false;
                    if (isDeleted) {
                      setModalDetail({ show: true, flag: "ComposeMessage" });
                      setKey(Math.random());
                      return;
                    }
                    navigate(
                      `/account-message/${res?.data?.payload?.chatHeadDetails?.id}`
                    );
                  } else if (
                    res?.data?.payload?.chatRequest &&
                    res?.data?.payload?.chatRequest?.status === 1 &&
                    res?.data?.payload?.chatRequest?.chat_id
                  ) {
                    if (
                      isChadHeadExist(res?.data?.payload?.chatRequest?.chat_id)
                    ) {
                      navigate(
                        `/account-message/${res?.data?.payload?.chatRequest?.chat_id}`
                      );
                    } else {
                      setModalDetail({ show: true, flag: "ComposeMessage" });
                      setKey(Math.random());
                    }
                  } else if (res?.data?.payload?.chatRequest?.status === 0) {
                    if (
                      res?.data?.payload?.chatRequest?.sender_id ===
                      userInfo?.id
                    ) {
                      toast.warning(
                        " Message request already sent to the user"
                      );
                    } else {
                      toast.warning("Please check message requests");
                      // navigate("/message-requests");
                    }
                  } else {
                    setModalDetail({ show: true, flag: "ComposeMessage" });
                    setKey(Math.random());
                  }
                } else {
                  if (res?.data?.payload?.chatRequest) {
                    if (res?.data?.payload?.chatRequest?.status === 1) {
                      let find = Object.keys(
                        res.data.payload.chatHeadDetails
                      ).find(
                        (key) =>
                          res.data.payload.chatHeadDetails[key] === userInfo.id
                      );
                      let isDeleted =
                        res.data.payload.chatHeadDetails[
                        `deleted_by_${find.split("_")[0]}`
                        ] || false;
                      if (isDeleted) {
                        setModalDetail({ show: true, flag: "ComposeMessage" });
                        setKey(Math.random());
                        return;
                      }
                      navigate(
                        `/account-message/${res?.data?.payload?.chatHeadDetails?.id}`
                      );
                    } else if (res?.data?.payload?.chatRequest?.status === 0) {
                      toast.warning(
                        " Message request already sent to the user"
                      );
                    } else {
                      setModalDetail({ show: true, flag: "ComposeMessage" });
                      setKey(Math.random());
                    }
                  } else if (res?.data?.payload?.chatHeadDetails && res?.data?.payload?.chatHeadDetails?.id) {
                    let find = Object.keys(res.data.payload.chatHeadDetails).find(key => res.data.payload.chatHeadDetails[key] === userInfo.id)
                    let isDeleted = res.data.payload.chatHeadDetails[`deleted_by_${find.split('_')[0]}`] || false
                    if (isDeleted) {
                      setModalDetail({ show: true, flag: "ComposeMessage" });
                      setKey(Math.random());
                      return;
                    }
                    navigate(
                      `/account-message/${res?.data?.payload?.chatHeadDetails?.id}`
                    );
                  } else {
                    setModalDetail({ show: true, flag: "ComposeMessage" });
                    setKey(Math.random());
                  }
                }
              }
            }
          }
        },
      })
    );
  };
  const copyLinkToClipboard = (postId) => {
    const currentBaseUrl = window.location.origin;
    const dynamicUrl = `${currentBaseUrl}/dashboard/post/${postId}`;

    if (postId) {
      navigator.clipboard.writeText(dynamicUrl).then(() => {
        toast.dismiss();
        toast.success("Link copied to clipboard");
      });
    } else {
      toast.error("Somenthing went wrong");
    }
  };

  // edit featured post
  const editPostAction = (id) => {
    let params = {
      id: id,
    };
    dispatch(
      getPostSingleDetails({
        ...params,
        cb(res) {
          if (res.status) {
            setEditFlag(true);
            setEditPostID(res.data.payload?.id);
            setPost(res.data.payload?.post ?? "");

            const companyTagData = res.data.payload?.tag_company_details?.map(
              (item, i) => {
                return {
                  id: item.company_id,
                  name: item?.company_information?.company_name,
                };
              }
            );

            const userTagData = res.data.payload?.tag_user_details?.map(
              (item, i) => {
                return {
                  id: item.user_id,
                  name:
                    item?.user_details?.user_profile?.first_name +
                    " " +
                    item?.user_details?.user_profile?.last_name,
                };
              }
            );

            setMainTags({
              companies: companyTagData,
              users: userTagData,
            });

            setSelectedIds({
              companies: companyTagData.map((item) => item.id),
              users: userTagData.map((item) => item.id),
            });

            if (res?.data?.payload?.attachment) {
              setPhotoUpload({
                uploadPhoto: {
                  name: res?.data?.payload?.attachment_name ?? "File",
                  size: res?.data?.payload?.attachment_size ?? 100000,
                  url: res?.data?.payload?.thumbnail_url,
                },
                flag: res?.data?.payload?.attachment_type,
              });

              setVideoUrlOriginal(res?.data?.payload?.attachment);

              setPhotoInfo([res?.data?.payload?.thumbnail_url]);
            }

            if (window) window.scrollTo(0, 0);
          }
        },
      })
    );
  };

  // // API for post delete ..............
  const deletePostAction = (id, index) => {
    swal({
      text: `Are you sure want to remove this post?`,
      dangerMode: false,
      buttons: true,
    }).then((result) => {
      if (result) {
        let params = {
          id: id,
        };
        dispatch(
          deletePost({
            ...params,
            cb(res) {
              if (res.status) {
                setAllPostList((prevData) => {
                  const data = prevData.filter((item, i) => i !== index);
                  return data;
                });

                toast.success("Post deleted successfully");
              }
            },
          })
        );
      }
    });
  };

  // // API for disabling comments ..............
  const disableEnableCommentAction = (id, flag, index) => {
    swal({
      text: `Are you sure want to ${flag == 1 ? "disable" : "enable"
        } comments on this post?`,
      dangerMode: false,
      buttons: true,
    }).then((result) => {
      if (result) {
        let params = {
          id: id,
          disable_comment: flag,
        };
        dispatch(
          disableEnableComment({
            ...params,
            cb(res) {
              if (res.status) {
                setAllPostList((prevData) => {
                  let data = [...prevData];
                  data[index] = { ...data[index], disable_comment: flag };
                  return data;
                });
                toast.success(res?.data?.msg);
              }
            },
          })
        );
      }
    });
  };

  //handle play button icon
  const handleVideoPlay = (post, index) => {
    setAllPostList((prevData) => {
      let data = [...prevData];
      // If you want to update the like status
      const updatedPost = {
        ...data[index],
        isvideo: true,
      };
      data[index] = updatedPost;

      return data;
    });
  };

  // total user like post
  const totalUserLikeList = (id) => {
    setModalDetail({ show: true, flag: "allPostLikeUser", id: id });
    setKey(Math.random());
  };

  //for post like...............
  const postLike = (id, flag, index) => {
    const params = {
      post_id: id,
      like_status: flag,
    };
    dispatch(
      userPostLike({
        ...params,
        cb(res) {
          if (res.status) {
            setAllPostList((prevData) => {
              let data = [...prevData];
              if (flag === 1) {
                // If you want to update the like status
                const updatedPost = {
                  ...data[index],
                  post_details: {
                    ...data[index].post_details,
                    is_like: res?.data?.payload?.is_like,
                    likeCount: res?.data?.payload?.likeCount,
                  },
                  is_like: res?.data?.payload?.is_like,
                  likeCount: res?.data?.payload?.likeCount,
                };

                data[index] = updatedPost;
              } else {
                // If you want to reset the like status to null
                const updatedPost = {
                  ...data[index],
                  post_details: {
                    ...data[index].post_details,
                    is_like: res?.data?.payload?.is_like,
                    likeCount: res?.data?.payload?.likeCount,
                  },
                  is_like: res?.data?.payload?.is_like,
                  likeCount: res?.data?.payload?.likeCount,
                };
                data[index] = updatedPost;
              }
              return data;
            });
          } else {
          }
        },
      })
    );
  };

  // //post all comments
  const handleLoadMoreComments = (id, index, page = 1, limit = 10, more) => {
    if (
      (postIndexMoreComments != "" || postIndexMoreComments === index) &&
      more !== true
    ) {
      setPostIndexMoreComments("");
      return;
    }
    let params = {
      id: id,
      page: page,
      limit: limit,
    };
    dispatch(
      allPostComment({
        ...params,
        cb(res) {
          if (res.status) {
            setPages(page);
            setAllCommentsLoad(true);
            setPostIndexMoreComments(index);
            if (page === 1) {
              // For the first page
              if (allCommentList.length === 0) {
                setAllCommentList(res.data.payload?.comments || []);
              } else {
                setAllCommentList((prevData) => {
                  // Replace existing comments with new comments
                  const updatedComments = res.data.payload?.comments || [];
                  return updatedComments;
                });
              }
            } else {
              // For pages other than the first page (e.g., page 2)
              setAllCommentList((prevData) => {
                // Concatenate existing comments with new comments
                const updatedComments = prevData.concat(
                  res.data.payload?.comments || []
                );
                return updatedComments;
              });
            }

            setAllCommentData(res.data.payload);
          }
        },
      })
    );
  };

  //like post
  const commentLikeAction = (
    id,
    flag,
    index,
    reIndex = null,
    postindexcomment
  ) => {
    const params = {
      comment_id: id,
      status: flag,
    };
    dispatch(
      commentLike({
        ...params,
        cb(res) {
          if (res.status) {
            if (reIndex == null) {
              setAllCommentList((prevData) => {
                let data = [...prevData];
                const updatedComment = {
                  ...data[index],
                  is_comment_liked:
                    res?.data?.payload?.commentLikeDetails ?? null,
                  total_like_count:
                    res?.data?.payload?.commentDetails?.likeCount ?? 0,
                };
                data[index] = updatedComment;

                return data;
              });
            } else if (postindexcomment === "postindexcomment") {
              if (flag === 1) {
                // If you want to update the like status
                setAllPostList((prevData) => {
                  let data = [...prevData];

                  data[index] = res?.data?.payload?.post_details;
                  return data;
                });
              } else {
                // If you want to reset the like status to null
                setAllPostList((prevData) => {
                  let data = [...prevData];

                  data[index] = res?.data?.payload?.post_details;
                  return data;
                });
              }
            } else {
              if (flag === 1) {
                // If you want to update the like status
                setAllCommentList((prevData) => {
                  let data = [...prevData];

                  data[index] =
                    res?.data?.payload?.post_details?.post_comments[index];
                  return data;
                });
              } else {
                // If you want to reset the like status to null
                setAllCommentList((prevData) => {
                  let data = [...prevData];

                  data[index] =
                    res?.data?.payload?.post_details?.post_comments[index];
                  return data;
                });
              }
            }
          } else {
          }
        },
      })
    );
  };

  //for post comment...............
  const postComment = (id, index, flag) => {
    if (comment !== "") {
      if (!comment.length === "") {
        toast.error("Please enter text to comment");
        return;
      }
    }
    const params = {
      post_id: id,
      // comment: comment ? comment : ""
    };
    if (flag === "comment") {
      params.comment = comment;
    }

    if (flag === "replyFlag") {
      params.comment_parent_id = replyId;
      params.comment = nestedReply;
    }
    dispatch(
      userPostComment({
        ...params,
        cb(res) {
          if (res.status) {
            if (flag === "replyFlag") {
              setShowNestedReply(false);
              // if (postDivRefs.current[index]) {
              //   postDivRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
              // }
              setReplyId("");

              setComment("");
              setPostID("");
              setAllPostList((prevData) => {
                let data = [...prevData];

                if (index >= 0 && index < data?.length) {
                  const postToUpdate = data[index];
                  // Assuming res?.data?.payload contains the new comment data
                  const updatedCommentReplies = [
                    ...postToUpdate?.post_comments[index].comment_replies,
                    res?.data?.payload?.comment,
                  ];

                  const updatedPost = {
                    ...postToUpdate,
                    post_comments: [
                      ...postToUpdate.post_comments?.slice(0, index), // Keep the comments before the index
                      {
                        ...postToUpdate.post_comments[index],
                        comment_replies: updatedCommentReplies, // Update comment_replies at the specified index
                      },
                      ...postToUpdate.post_comments?.slice(index + 1), // Keep the comments after the index
                    ],
                  };

                  data[index] = updatedPost;
                }

                return data;
              });
              setAllCommentList((prevData) => {
                let data = [...prevData];
                data.concat(res?.data?.payload);
                return data;
              });
            } else {
              setShowNestedReply(false);
              // if (postDivRefs.current[index]) {
              //   postDivRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
              // }
              setReplyId("");

              setComment("");
              setPostID("");
              if (postIndexMoreComments == index) {
                setAllCommentList((prevData) => [
                  res?.data?.payload,
                  ...prevData,
                ]);
              }

              setAllPostList((prevData) => {
                let data = [...prevData];

                const updatedPost = {
                  ...data[index],
                  total_comments: res?.data?.payload?.total_comments,
                  post_details: {
                    ...data[index].post_details,
                    total_comments: res?.data?.payload?.total_comments,
                  },
                };
                data[index] = updatedPost;
                return data;
              });
            }
          }
        },
      })
    );
  };

  const handlePageRedirectMyProfile = (name) => {
    const formattedName = name?.replace(/\s+/g, "-"); // Replace spaces with hyphens
    navigate(`/${formattedName}`);
  };

  const btnDisable =
    companyInfo?.chatRequest === false
      ? false
      : companyInfo?.chatRequest?.status === 2
        ? true
        : companyInfo?.chatRequest?.status === 0
          ? true
          : false;

  useEffect(() => {
    window.scrollTo(0, 0);
    CompanyInformation(id);
    document.title = "User Profile- OPPSWell";
  }, [id]);

  useEffect(() => {
    setShowNestedReply(false);
    setPostIndexMoreComments("");
  }, []);

  const [displayWidth, setDisplayWidth] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [displayHeight, setDisplayHeight] = useState(0);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedArea);
  };

  const saveProfileChnage = (data, flag) => {
    let params;
    if (flag == 2) {
      params = {
        id: userInformation?.user_company_information.id,
        banner: data?.url,
        banner_name: data?.name,
        banner_size: parseInt(data?.size),
        banner_format: data?.format,
      };
    }
    dispatch(
      companyUpdateLogoBanner({
        ...params,
        cb(res) {
          if (res.status) {
            updateCompanyInformation(id);
            // companyProfileInfo();
          }
        },
      })
    );
  };
  const uploadPhoto = (params) => {
    if (flag == 2) {
      dispatch(
        companyBannerUplaod({
          ...params,
          cb(res) {
            if (res?.data) {
              saveProfileChnage(res?.data?.payload, 2);
            }
          },
        })
      );
    }
    else {
      dispatch(
        userPhotoUpload({
          ...params,
          cb(res) {
            if (res.status) {
              setPhotoUpload(res?.data?.payload?.url);
              setPhotoInfo(res?.data?.payload);
              let params = {
                first_name: userInformation.user_profile.first_name
                  ? userInformation.user_profile.first_name
                  : "",
                last_name: userInformation.user_profile.last_name
                  ? userInformation.user_profile.last_name
                  : "",
              };
              if (res?.data?.payload.name != "") {
                if (chooseBusinessType == 2) {
                  params = {
                    ...params,
                    id: userInformation?.user_company_information.id,
                    company_name: userInformation?.user_company_information?.company_name,
                    company_logo: res?.data?.payload.url
                      ? res?.data?.payload.url
                      : "",
                    company_logo_name: res?.data?.payload.name
                      ? res?.data?.payload.name
                      : "",
                    company_logo_size: res?.data?.payload.size
                      ? res?.data?.payload.size
                      : 0,
                  };
                } else {
                  params = {
                    ...params,
                    profile_photo: res?.data?.payload.url
                      ? res?.data?.payload.url
                      : "",
                    profile_photo_name: res?.data?.payload.name
                      ? res?.data?.payload.name
                      : "",
                    profile_photo_size: res?.data?.payload.size
                      ? res?.data?.payload.size
                      : 0,
                    profile_photo_format: res?.data?.payload.format
                      ? res?.data?.payload.format
                      : "image/jpeg",
                  };
                }
              }
              if (chooseBusinessType == 2) {
                dispatch(
                  userCompanyProfileUpdate({
                    ...params,
                    cb(res) {
                      if (res.status) {
                        if (res.status === 200) {
                          updateCompanyInformation(id);
                        } else {
                          toast.error(
                            "Something went wrong. Try again after sometime"
                          );
                        }
                      }
                    },
                  }));
              } else {
                dispatch(
                  userProfileUpdate({
                    ...params,
                    cb(res) {
                      if (res.status === 200) {
                        updateCompanyInformation(id);
                      } else {
                        toast.error(
                          "Something went wrong. Try again after sometime"
                        );
                      }
                    },
                  })
                );
              }
            }
          },
        })
      );
    }
  };
  const makeClientCrop = async (fileBlobArray, crop) => {
    if (crop && crop.width && crop.height) {
      try {
        if (fileBlobArray.length > 0) {
          const fileBlob = fileBlobArray[0];
          const croppedImage = await getCroppedImg(
            fileBlob,
            crop,
            "cropped-image.jpeg",
            displayWidth,
            displayHeight,
            flag == 2 ? "banner" : ""
          );
          uploadPhoto({
            photo: new File([croppedImage], fileBlob?.name ?? "abc.jpeg"),
          });
          setCroppedImageBlob(croppedImage);
          handleOnCloseModal();
        } else {
          throw new Error("File blob array is empty or invalid");
        }
      } catch (error) {
        console.error("Error cropping image:", error);
      }
    } else {
      console.warn("Invalid crop object:", crop);
      setCroppedImageBlob(null);
    }
  };
  const [fileBlob, setFileBlob] = useState();
  const [image, setImage] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    setFlag(1)
    const imageFile = acceptedFiles[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
      toast.error("Please select a valid image.");
      return false;
    }
    setModalDetail({
      show: true,
      title: "cropImage",
      flag: "cropImage",
    });
    setKey(Math.random());
    setFileBlob(acceptedFiles);
    setImage(imageFile);
  }, []);

  // banner Image
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const divRef = useRef(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (divRef.current) {
        const width = divRef.current.offsetWidth;
        const height = calculateHeight(width);
        setDimensions({ width, height });
      }
    };

    const calculateHeight = (width) => {
      return (width * 2) / 5;
    };

    // Initial dimension calculation
    updateDimensions();

    // Add event listener for window resize
    window.addEventListener("resize", updateDimensions);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [flag, setFlag] = useState(1);

  const onChangePicture = (e, flag) => {
    const imageFile = e.target.files[0];
    e.currentTarget.value = null;
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error("Please select a valid image.");
      return false;
    }
    if (imageFile.size > 25000000) {
      toast.error("File size too large.");
      return false;
    }
    let params = {
      photo: imageFile,
    };
    setFlag(flag);
    setModalDetail({
      show: true,
      title: "cropImage",
      flag: "cropImage",
    });
    setKey(Math.random());
    setFileBlob([imageFile]); // Assuming setFileBlob expects an array of blobs/files
    setImage(imageFile); // Set the image as well
  };
  return (
    <>
      {showNodata ? (
        <>
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <div className="noDataFound">
                  <FadeInImage src={Images.noDataFound} className="noDataFoundImg" />
                  <h4 className="headingBlack30">
                    <span className="headingBlue30 fw-700">Sorry!</span> No User
                    Found.
                  </h4>
                  <p className="mb-3">
                    We couldn't find any User that match your search criteria.{" "}
                    <br /> Please try broadening your search or exploring our
                    other available resources. <br />
                    If you have specific questions or need assistance, feel free
                    to contact our
                    <br /> support team.
                  </p>
                  {/* <p>We're here to help you navigate your Company effectively.</p> */}
                  <p>
                    <a href="mailto:support@oppswell.com">
                      support@oppswell.com
                    </a>
                  </p>
                  <button
                    className="secondaryBtnOuter"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    {" "}
                    Back to Dashboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <section ref={divRef} className="companyProfile_ overflow-hidden">
            <div className="topprofileBanner">
              <div className="container-fluid noPadding">
                <div className="row gx-0">
                  {companyInfoSelector?.loading ? (
                    <>
                      <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                          <div className="customLoader">
                            <div
                              className="spinner-grow text-success"
                              role="status"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : companyInfo?.companyDetails?.banner !== null ? (

                    <FadeInImage
                      className="profile-img companyprofilebanner"
                      style={{ height: `${dimensions.height}px` }}
                      src={companyInfo?.companyDetails?.banner}
                    />
                  ) : companyInfo?.companyDetails?.banner === null ? (
                    <FadeInImage
                      className="profile-img companyprofilebanner"
                      style={{ height: `${dimensions.height}px` }}
                      src={Images.companyProfile2}
                    />
                  ) : (
                    ""
                  )}
                  <>
                    <input
                      type="file"
                      id="fileAttCompany"
                      accept="image/jpeg, image/jpg, image/png"
                      className="form-control d-none"
                      required=""
                      onChange={(e) => {
                        onChangePicture(e, 2);
                      }}
                    />
                    {userInfo?.id == userDeatil?.user_id && <label className="fileupimg" htmlFor="fileAttCompany">
                      <figure className="chnageProfile">
                        <FadeInImage
                          src={Images.attach}
                          alt="Attach"
                          className="uploadIcon_"
                        />
                      </figure>
                    </label>}
                  </>
                </div>
              </div>
            </div>
            <div className="companyDetailspro_ mb-5">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="companyDetailsLeft">
                      <div className="containerBox">
                        <div className="row">
                          <div className="col-lg-3 col-md-3 p-0">
                            {companyInfoSelector?.loading ? (
                              <>
                                <SpinLoader />
                              </>
                            ) : companyInfo?.companyDetails?.company_logo !=
                              "" ? (
                              <>
                                {id ? (
                                  <div className="designImg_">
                                    <input {...getInputProps()} />
                                    <FadeInImage
                                      src={
                                        userInformation?.business_type == 2 ?
                                          userInformation?.user_company_information?.company_logo ?? Images.puzzleProfile :
                                          userInformation?.user_profile?.profile_photo ?? Images.puzzleProfile
                                      }
                                      alt="AddDocument"
                                      className="me-2 imgproduct_"
                                    />
                                  </div>
                                ) : (
                                  <div class="custom-file-input">
                                    <label for="fileInput">
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <div className="designImg_">
                                          <input {...getInputProps()} />
                                          <FadeInImage
                                            src={
                                              userInformation?.business_type == 2 ?
                                                userInformation?.user_company_information?.company_logo ?? Images.puzzleProfile :
                                                userInformation?.user_profile?.profile_photo ?? Images.puzzleProfile
                                            }
                                            alt="AddDocument"
                                            className="me-2 imgproduct_"
                                          />

                                          {(!id || userInformation.id == id) && <figure className="chnageProfile">
                                            <FadeInImage
                                              src={Images.attach}
                                              alt="Attach"
                                              className="uploadIcon_"
                                            />
                                          </figure>}
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                )}
                                {/* <div class="custom-file-input">
                                <label for="fileInput">
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <div className="designImg_">
                                      <input {...getInputProps()} />
                                      <FadeInImage
                                        src={
                                          userInformation?.user_profile
                                            ?.profile_photo ??
                                          Images.puzzleProfile
                                        }
                                        alt="AddDocument"
                                        className="me-2 imgproduct_"
                                      />

                                      <figure className="chnageProfile">
                                        <FadeInImage
                                          src={Images.attach}
                                          alt="Attach"
                                          className="uploadIcon_"
                                        />
                                      </figure>
                                    </div>
                                  </div>
                                </label>
                              </div> */}
                              </>
                            ) : companyInfo?.companyDetails?.company_logo ===
                              "" ? (
                              companyInfo?.companyDetails?.company_logo ==
                                null ? (
                                <FadeInImage
                                  className="imgproduct_"
                                  src={Images.puzzleProfile}
                                />
                              ) : (
                                <FadeInImage
                                  className="imgproduct_"
                                  src={Images.puzzleProfile}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-lg-9 col-md-9">
                            <div className="designContent_">
                              <div className="designcontentTop_">
                                <div className="designLeft_">
                                  <h4 className="boldmainHeading_">
                                    {userInformation?.business_type == 2 ? // business type will be 2 when the user is small user
                                      companyInfo?.companyDetails?.company_name
                                      :
                                      `${userInformation?.user_profile?.first_name ?? ""} ${userInformation?.user_profile?.last_name ?? ""}`
                                    }
                                  </h4>
                                  <span className="lightgreyHead_ fw-500 userCompanyName">
                                    {userInformation?.business_type == 2 ? // business type will be 2 when the user is small user
                                      `${userInformation?.user_profile?.first_name ?? ""} ${userInformation?.user_profile?.last_name ?? ""}`
                                      :
                                      companyInfo?.companyDetails?.company_name
                                    }
                                  </span>
                                  <h5 className="abouText_ mt-2">
                                    {
                                      companyInfo?.companyDetails?.industries_group_details?.name
                                    }
                                  </h5>
                                  {companyInfo?.companyDetails?.country_details
                                    ?.name ? (
                                    <p className="motionLoc mb-3">
                                      <FadeInImage
                                        src={Images.locationpin}
                                        alt="Location"
                                        className="me-1"
                                      />
                                      <span className="textInnerOuter">
                                        {
                                          companyInfo?.companyDetails
                                            ?.province_details?.name
                                        }{" "}
                                        /{" "}
                                        {
                                          companyInfo?.companyDetails
                                            ?.country_details?.name
                                        }
                                      </span>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              {id &&
                                (companyInfoSelector?.loading ? (
                                  <>
                                    <SpinLoader />
                                  </>
                                ) : (
                                  <div className="designbtnRight">
                                    {userInformation?.is_following?.id ? (
                                      userInformation?.is_following?.id ? (
                                        <button
                                          className="subsmallBtngreen_ me-2 mb-2"
                                          onClick={() => handleUnfollowing()}
                                        >
                                          {companyInfoSelector?.updateLoader &&
                                            updatingWhat ===
                                            updatingInfoKeys.follow ? (
                                            <CommonSpinner
                                              className={"border-white"}
                                            />
                                          ) : (
                                            "Following -"
                                          )}
                                        </button>
                                      ) : (
                                        <button
                                          className="subsmallBtngreen_ me-2 mb-2"
                                          onClick={() => handleFollowing()}
                                        >
                                          {companyInfoSelector?.updateLoader &&
                                            updatingWhat ===
                                            updatingInfoKeys.follow ? (
                                            <CommonSpinner
                                              className={"border-white"}
                                            />
                                          ) : (
                                            "Follow +"
                                          )}
                                        </button>
                                      )
                                    ) : (
                                      <>
                                        <button
                                          className="subsmallBtngreen_ me-2 mb-2"
                                          onClick={() => handleFollowing()}
                                        >
                                          {companyInfoSelector?.updateLoader &&
                                            updatingWhat ===
                                            updatingInfoKeys.follow ? (
                                            <CommonSpinner
                                              className={"border-white"}
                                            />
                                          ) : (
                                            "Follow +"
                                          )}
                                        </button>
                                      </>
                                    )}
                                    {userById?.connectionStatus?.status ? (
                                      userById?.connectionStatus?.receiver_id ==
                                        authSelector?.userInfo?.user?.id &&
                                        userById?.connectionStatus?.status ==
                                        1 ? (
                                        <>
                                          <button
                                            className="subsmallBtnblue_ me-2"
                                            onClick={() => {
                                              updateRequest(
                                                userById?.connectionStatus?.id,
                                                2
                                              );
                                            }}
                                          >
                                            {companyInfoSelector?.updateLoader &&
                                              updatingWhat ===
                                              updatingInfoKeys.accept ? (
                                              <CommonSpinner
                                                className={"border-white"}
                                              />
                                            ) : (
                                              <>
                                                Accept{" "}
                                                <i className="fas fa-check"></i>
                                              </>
                                            )}
                                          </button>
                                          <button
                                            className="subsmallBtnblue_ me-2 bg-danger"
                                            onClick={() => {
                                              updateRequest(
                                                userById?.connectionStatus?.id,
                                                3
                                              );
                                            }}
                                          >
                                            {companyInfoSelector?.updateLoader &&
                                              updatingWhat ===
                                              updatingInfoKeys.reject ? (
                                              <CommonSpinner
                                                className={"border-white"}
                                              />
                                            ) : (
                                              <>
                                                Reject{" "}
                                                <i className="fas fa-times"></i>
                                              </>
                                            )}
                                            {/* Connected should be here */}
                                          </button>
                                        </>
                                      ) : userById?.connectionStatus?.status ==
                                        1 ? (
                                        <button
                                          className="subsmallBtnblue_ me-2"
                                          onClick={() => {
                                            updatePendingRequest();
                                          }}
                                        >
                                          {companyInfoSelector?.updateLoader &&
                                            updatingWhat ===
                                            updatingInfoKeys.request ? (
                                            <CommonSpinner
                                              className={"border-white"}
                                            />
                                          ) : (
                                            "Pending"
                                          )}
                                        </button>
                                      ) : userById?.connectionStatus?.status ==
                                        2 ? (
                                        <button
                                          className="subsmallBtnblue_ me-2 connectedBtn"
                                          onClick={() => {
                                            updateAcceptRequest();
                                          }}
                                        >
                                          {companyInfoSelector?.updateLoader &&
                                            updatingWhat ===
                                            updatingInfoKeys.request ? (
                                            <CommonSpinner
                                              className={"border-white"}
                                            />
                                          ) : (
                                            "Connected"
                                          )}
                                        </button>
                                      ) : userById?.connectionStatus?.status ==
                                        3 ? (
                                        <button
                                          className="subsmallBtnblue_ me-2"
                                          onClick={() => {
                                            handleSubmit();
                                          }}
                                        >
                                          {companyInfoSelector?.updateLoader &&
                                            updatingWhat ===
                                            updatingInfoKeys.request ? (
                                            <CommonSpinner
                                              className={"border-white"}
                                            />
                                          ) : (
                                            "Request Connection"
                                          )}
                                        </button>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <>
                                        {companyInfo?.connecetionRequestReceived ? (
                                          companyInfo
                                            ?.connecetionRequestReceived
                                            ?.status == 1 ? (
                                            <>
                                              <button
                                                className="subsmallBtnblue_ me-2"
                                                onClick={() => {
                                                  updateRequest(2);
                                                }}
                                              >
                                                {companyInfoSelector?.updateLoader &&
                                                  updatingWhat ===
                                                  updatingInfoKeys.request ? (
                                                  <CommonSpinner
                                                    className={"border-white"}
                                                  />
                                                ) : (
                                                  "Accept"
                                                )}
                                              </button>
                                              <button
                                                className="subsmallBtnblue_"
                                                onClick={() => {
                                                  updateRequest(3);
                                                }}
                                              >
                                                {" "}
                                                {companyInfoSelector?.updateLoader &&
                                                  updatingWhat ===
                                                  updatingInfoKeys.request ? (
                                                  <CommonSpinner
                                                    className={"border-white"}
                                                  />
                                                ) : (
                                                  "Reject"
                                                )}
                                              </button>
                                            </>
                                          ) : companyInfo
                                            ?.connecetionRequestReceived
                                            ?.status == 2 ? (
                                            ""
                                          ) : companyInfo
                                            ?.connecetionRequestReceived
                                            ?.status == 3 ? (
                                            <button
                                              className="subsmallBtnblue_ me-2 mb-2"
                                              onClick={() => {
                                                handleSubmit();
                                              }}
                                            >
                                              {companyInfoSelector?.updateLoader &&
                                                updatingWhat ===
                                                updatingInfoKeys.request ? (
                                                <CommonSpinner
                                                  className={"border-white"}
                                                />
                                              ) : (
                                                "Request Connection"
                                              )}
                                            </button>
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          <button
                                            className="subsmallBtnblue_ me-2 mb-2"
                                            onClick={() => {
                                              handleSubmit();
                                            }}
                                          >
                                            {companyInfoSelector?.updateLoader &&
                                              updatingWhat ===
                                              updatingInfoKeys.request ? (
                                              <CommonSpinner
                                                className={"border-white"}
                                              />
                                            ) : (
                                              "Request Connection"
                                            )}
                                          </button>
                                        )}
                                      </>
                                    )}

                                    {/* {
                                                             companyInfo?.chatRequest?.status === 1 ? <button className="subsmallBtnblue_" onClick={() => {
                                                                 navigate(`/account-message/${companyInfo?.chat_head_details?.id}`)
                                                             }}
                                                             >Message   </button>
                                                                 :
                                                                 <button className={`subsmallBtnblue_ ${btnDisable === true ? "disabled__" : ""}`} onClick={() => {
                                                                     setModalDetail({ show: true, flag: "ComposeMessage", });
                                                                     setKey(Math.random());
                                                                 }}
                                                                     disabled={btnDisable}
                                                                 >Message</button>
                                                         } */}

                                    {/* {chooseBusinessType == 2 && choosePlan === "Bronze" ?
                                                             // companyInfo?.companyDetails?.company_connection == false || companyInfo?.companyDetails?.company_connection?.status == 1 || companyInfo?.companyDetails?.company_connection?.status == 3 ?
                                                             "" : <button className={`subsmallBtnblue_ ${btnDisable === true ? "disabled__" : ""}`}
                                                                 onClick={() => handleMessage()}
                                                                 disabled={btnDisable}
                                                             >Message</button>
 
                                                             // : ""
                                                         } */}
                                    {/* {chooseBusinessType === 2 && choosePlan == "Bronze" && companyInfo?.companyDetails?.company_connection == false ? "" : ""}
                                                         {chooseBusinessType === 2 && choosePlan != "Bronze" && companyInfo?.companyDetails?.company_connection == false || chooseBusinessType === 2 && choosePlan != "Bronze" || companyInfo?.companyDetails?.company_connection?.status == 2 || chooseBusinessType === 2 && choosePlan != "Bronze" && companyInfo?.companyDetails?.company_connection?.status == 1 || chooseBusinessType === 2 && choosePlan != "Bronze" && companyInfo?.companyDetails?.company_connection?.status == 3 ?
                                                             <button
                                                                 className={`subsmallBtnblue_ ${btnDisable === true ? "disabled__" : ""}`}
                                                                 onClick={() => handleMessage()}
                                                                 disabled={btnDisable}
                                                             >
                                                                 Message
                                                             </button> : ""
 
                                                         } */}
                                    {authSelector?.companyProfileInfo
                                      ?.userDetails?.membership_details
                                      ?.business_type === 2 &&
                                      authSelector?.companyProfileInfo
                                        ?.userDetails?.membership_details
                                        ?.name === "Bronze" &&
                                      (companyInfo?.companyDetails
                                        ?.company_connection?.status == 0 ||
                                        companyInfo?.companyDetails
                                          ?.company_connection?.status == 1 ||
                                        companyInfo?.companyDetails
                                          ?.company_connection?.status == 3 ||
                                        companyInfo?.companyDetails
                                          ?.company_connection === false) ? (
                                      ""
                                    ) : (
                                      <button
                                        className={`subsmallBtnblue_ sendAMessage ${btnDisable === true
                                          ? "disabled__"
                                          : ""
                                          }`}
                                        onClick={() => handleChat(id)}
                                        disabled={btnDisable}
                                      >
                                        Send a Message
                                        <i className="fas fa-info-circle ms-1"></i>
                                      </button>
                                    )}

                                    {/* { companyInfo?.companyDetails?.company_connection == false || companyInfo?.companyDetails?.company_connection?.status == 1 || companyInfo?.companyDetails?.company_connection?.status == 3 ?
                                                             "" : <button className={`subsmallBtnblue_ ${btnDisable === true ? "disabled__" : ""}`}
                                                                 onClick={() => handleMessage()}
                                                                 // onClick={() => {
                                                                 //     setModalDetail({ show: true, flag: "ComposeMessage", });
                                                                 //     setKey(Math.random());
                                                                 // }}
                                                                 disabled={btnDisable}
                                                             >Message</button>
                                                         } */}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <hr className="bottomBorder_"></hr>
                        <div className="aboutprofile_ mt-4">
                          <h5 className="headingTitle_">
                            <b>About </b>
                            {userInformation?.business_type == 2 ? // business type will be 2 when the user is small user
                                      companyInfo?.companyDetails?.company_name
                                      :
                                      `${userInformation?.user_profile?.first_name ?? ""} ${userInformation?.user_profile?.last_name ?? ""}`
                                    }
                            {/* {userInformation?.user_profile?.first_name}{" "}
                            {userInformation?.user_profile?.last_name} */}
                          </h5>
                          <p className="innerSubtextSmall companyInfo mt-2 about15 text-start cursor_text">
                            {
                              userInformation?.business_type == 2 ? userInformation?.user_company_information?.company_service_description : userInformation?.user_profile?.about
                            }
                            {/* {companyInfo?.companyDetails?.company_service_description?.split(
                              " "
                            ).length -
                              1 >=
                              100
                              ? companyInfo?.companyDetails?.company_service_description
                                ?.split(" ")
                                ?.slice(0, 100)
                                .join(" ")
                              : companyInfo?.companyDetails
                                ?.company_service_description}
                            } */}
                          </p>
                        </div>
                      </div>

                      <div className="containerBox mt-3 pb-5">
                        <div className="currentOpportunity_">
                          <div className="currentTop">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div className="d-flex w-100">
                                <button
                                  className={`${showOpportunity
                                    ? "activeOpportunities pe-4"
                                    : "inactiveOpportunities me-4"
                                    }`}
                                  onClick={() => {
                                    setShowOpportunity(true);
                                  }}
                                >
                                  Opportunities
                                </button>
                                <button
                                  className={`${showOpportunity
                                    ? "inactiveOpportunities me-4"
                                    : "activeOpportunities pe-4"
                                    }`}
                                  onClick={() => {
                                    setShowOpportunity(false);
                                  }}
                                >
                                  Industry Queries
                                </button>
                              </div>
                            </div>
                            {showOpportunity &&
                              companyCurrentOpportunity?.length > 2 && (
                                <button
                                  className="viewAllBtn___ viewAllDesktop"
                                  onClick={() => {
                                    navigate(
                                      "/create-opportunities/active-opportunity",
                                      {
                                        state: {
                                          oppId:
                                            companyInfo?.companyDetails
                                              ?.user_id,
                                          activeTab: "activeOpportunity",
                                        },
                                      }
                                    );
                                  }}
                                >
                                  View all
                                </button>
                              )}
                            {!showOpportunity &&
                              userInformation?.user_industry_query?.length >
                              2 && (
                                <button
                                  className="viewAllBtn___ viewAllDesktop"
                                  onClick={() => {
                                    navigate(
                                      "/create-industry-query/active-query",
                                      {
                                        state: {
                                          oppId:
                                            companyInfo?.companyDetails
                                              ?.user_id,
                                          activeTab: "activeIndustryQuery",
                                        },
                                      }
                                    );
                                  }}
                                >
                                  View all
                                </button>
                              )}
                          </div>
                          {companyInfoSelector?.loading ? (
                            <>
                              <SpinLoader />
                            </>
                          ) : (
                            <>
                              <div className="row mt-5 ctive">
                                {companyCurrentOpportunity?.length > 0 ||
                                  userInformation?.user_industry_query?.length >
                                  0 ? (
                                  <>
                                    {(showOpportunity
                                      ? companyCurrentOpportunity === false
                                        ? []
                                        : companyCurrentOpportunity
                                      : userInformation?.user_industry_query
                                    )
                                      ?.slice(0, 2)
                                      .map((data, index) => {
                                        return (
                                          <>
                                            <div className="col-md-6">
                                              <div
                                                className={`opportunityComtent_ ${index == 0
                                                  ? "noOpportunity"
                                                  : ""
                                                  }`}
                                              >
                                                <FadeInImage
                                                  className="todoList_"
                                                  // src={
                                                  //     data?.image_mime_type === "image/png" || data?.image_mime_type === "image/jpeg" ? data?.opportunity_media : data?.image_mime_type === "application/pdf" ? Images.iconPdf : data?.image_mime_type === "application/msword" || data?.image_mime_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? Images.iconDoc : Images.oppoIcon
                                                  // }
                                                  src={Images.oppoIcon}
                                                />
                                                <h5 className="opportunityHead_ mt-3">
                                                  {showOpportunity
                                                    ? data?.posting_title
                                                    : data?.query_title}
                                                </h5>
                                                {data.contract_length ? (
                                                  <>
                                                    <h6 className="headingTitleSmall_ mb-2 cursor_text">
                                                      {/* {data?.contract_length === 1
                                                      ? "Yearly Term Contract"
                                                      : data?.contract_length ===
                                                        2
                                                      ? "Monthly  Term Contract"
                                                      : data?.contract_length ===
                                                        3
                                                      ? "Weekly  Term Contract"
                                                      : data?.contract_length ===
                                                        4
                                                      ? "Daily  Term Contract"
                                                      : data?.contract_length ===
                                                        5
                                                      ? "Hourly  Term Contract"
                                                      : ""}{" "} */}
                                                      {
                                                        data
                                                          ?.industries_group_details
                                                          ?.name
                                                      }
                                                      {/* Fixed Term Contract */}
                                                    </h6>
                                                    <br />
                                                  </>
                                                ) : (
                                                  <></>
                                                )}

                                                {/* {data?.hq_location ?
                                                                                            <p className="postHours_ mb-3">
                                                                                                {data?.hq_location}
                                                                                            </p> : ""
                                                                                        } */}
                                                {/* <p className="lightgreyHead_">
                                                  <i>
                                                    Additional Contract Details
                                                  </i>
                                                </p> */}

                                                <p className="innerSubtextSmall wordLimit text-start cursor_text">
                                                  {data?.description}
                                                </p>
                                                <h6 className="postHours_ mb-2 fw-normal">
                                                  {" "}
                                                  {moment(
                                                    data?.created_at
                                                  ).fromNow()}
                                                </h6>
                                                {/* {(
                                                  <div className={`industryGroupBtn d-inline-block me-1 ${showOpportunity &&
                                                    data
                                                      ?.post_opportunity_diversity_details
                                                      ?.length > 0 ? "" : "invisible"}`}>
                                                    <strong>
                                                      {data?.post_opportunity_diversity_details?.map(
                                                        (val, index) => (
                                                          <span
                                                            className=""
                                                            key={index}
                                                          >
                                                            {val
                                                              ?.diversity_status_details
                                                              ?.name +
                                                              (data
                                                                ?.post_opportunity_diversity_details
                                                                ?.length !=
                                                                index + 1
                                                                ? ", "
                                                                : "")}
                                                          </span>

                                                        )
                                                      )}

                                                    </strong>

                                                  </div>
                                                )} */}
                                                {/* {!showOpportunity &&
                                                  data
                                                    ?.post_industry_diversity_status_details
                                                    ?.length > 0 ? (
                                                  <div className={`industryGroupBtn d-inline-block me-1 ${!showOpportunity &&
                                                    data
                                                      ?.post_industry_diversity_status_details
                                                      ?.length > 0 ? "" : "invisible"}`}>
                                                    <strong>
                                                      {data?.post_industry_diversity_status_details?.map(
                                                        (val, index) => (
                                                          <span
                                                            className=""
                                                            key={index}
                                                          >
                                                            {val
                                                              ?.diversity_status_details
                                                              ?.name +
                                                              (data
                                                                ?.post_industry_diversity_status_details
                                                                ?.length !=
                                                                index + 1
                                                                ? ", "
                                                                : "")}
                                                          </span>
                                                        )
                                                      )}

                                                    </strong>
                                                  </div>
                                                ) : (
                                                  ""
                                                )} */}

                                                {data.is_applied == null ? (
                                                  // <button onClick={() => hanleRequestApplicatio(data)} className="subsmallBtngreen_ mt-3">
                                                  <button
                                                    type="button"
                                                    onClick={(e) =>
                                                      handleSubmited(
                                                        e,
                                                        data?.id ? data?.id : ""
                                                      )
                                                    }
                                                    className="subsmallBtngreen_ mt-3 d-block"
                                                  >
                                                    <b>
                                                      View{" "}
                                                      {showOpportunity
                                                        ? "Opportunity"
                                                        : "Query"}
                                                    </b>
                                                  </button>
                                                ) : (
                                                  <button className="subsmallBtnblue_ mt-3 d-block">
                                                    Application Applied
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                  </>
                                ) : (
                                  <></>
                                  // <p className="mb-0">
                                  //   {" "}
                                  //   No Current {showOpportunity ?  "Opportunity" : "Industry Query"} Found
                                  // </p>
                                )}
                                <p className="text-center">
                                  {(companyCurrentOpportunity?.length == 0 ||
                                    companyCurrentOpportunity === false) &&
                                    showOpportunity ? (
                                    <div>
                                      <FadeInImage
                                        src={Images.noDataFound}
                                        className="mb-2"
                                      />
                                      <br />
                                      <h5>
                                        Company has not posted Opportunities at
                                        this time
                                      </h5>
                                    </div>
                                  ) : userInformation?.user_industry_query
                                    .length == 0 && !showOpportunity ? (
                                    <div>
                                      <FadeInImage
                                        src={Images.noDataFound}
                                        className="mb-2"
                                      />
                                      <br />
                                      <h5>
                                        Company has not posted Industry Queries
                                        at this time
                                      </h5>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                        {showOpportunity &&
                          companyCurrentOpportunity?.length > 2 && (
                            <button
                              className="viewAllBtn___ viewAllMobile"
                              onClick={() => {
                                navigate(
                                  "/create-opportunities/active-opportunity",
                                  {
                                    state: {
                                      oppId:
                                        companyInfo?.companyDetails?.user_id,
                                      activeTab: "activeOpportunity",
                                    },
                                  }
                                );
                              }}
                            >
                              View all
                            </button>
                          )}
                        {!showOpportunity &&
                          userInformation?.user_industry_query?.length > 2 && (
                            <button
                              className="viewAllBtn___ viewAllMobile"
                              onClick={() => {
                                navigate(
                                  "/create-industry-query/active-query",
                                  {
                                    state: {
                                      oppId:
                                        companyInfo?.companyDetails?.user_id,
                                      activeTab: "activeIndustryQuery",
                                    },
                                  }
                                );
                              }}
                            >
                              View all
                            </button>
                          )}
                      </div>

                      <div className="containerBox mt-4">
                        <div className="newsBox_">
                          <div className="currentTop">
                            <h4 className="headingTitle_ mb-3">
                              <b>Chronology</b>
                            </h4>
                          </div>
                          {companyInfoSelector?.loading ? (
                            <>
                              <SpinLoader />
                            </>
                          ) : (
                            <>
                              <InfiniteScroll
                                dataLength={allPost?.length ?? 0} // Total length of the data
                                // next={() => fetchMoreData()}
                                hasMore={true} // Set to false when there is no more data
                                // loader={<h4>Loading...</h4>} // Loading indicator
                                // endMessage={<p>No more data to load.</p>}
                                className="overflow-hidden"
                              >
                                {allPost && allPost?.length > 0 ? (
                                  <div className="featurePostCon">
                                    {allPost?.map((post, index) => {
                                      var comapanyInfo =
                                        post?.user_details?.is_invited === 2
                                          ? post?.user_details
                                            ?.user_selected_company_information
                                            ?.user_company_information
                                          : post?.user_details
                                            ?.user_company_information;

                                      return (
                                        <div className="mb-4 postContainerArea" key={index}>
                                          <div className="posts justify-content-between mb-2  ">
                                            <div className="posts">
                                              {userDeatil?.user_id ==
                                                post?.user_id ? (
                                                <FadeInImage
                                                  onClick={() =>
                                                    // handlePageRedirectMyProfile(
                                                    //   post?.user_details
                                                    //     ?.user_company_information
                                                    //     ?.company_name
                                                    // )
                                                    userNavigation(post?.user_details, post?.user_details?.is_invited == 2 ? false : "company")

                                                    // UserProfileNavigation(
                                                    //   `${post?.user_details?.user_profile?.first_name}-${post?.user_details?.user_profile?.last_name}`,
                                                    //   post?.user_details?.id
                                                    // )
                                                  }
                                                  src={
                                                    post?.user_details
                                                      ?.user_profile
                                                      ?.profile_photo
                                                      ? post?.user_details
                                                        ?.user_profile
                                                        ?.profile_photo
                                                      : Images.puzzleProfile
                                                  }
                                                  className="postUser me-3"
                                                  alt="UserImage"
                                                />
                                              ) : (
                                                <FadeInImage
                                                  onClick={() =>
                                                    // handlePageRedirect(
                                                    //   post?.user_details
                                                    //     ?.user_company_information
                                                    //     ?.company_name,
                                                    //   post?.user_details
                                                    //     ?.user_company_information
                                                    //     ?.id
                                                    // )
                                                    userNavigation(post?.user_details, post?.user_details?.is_invited == 2 ? false : "company")
                                                    // UserProfileNavigation(
                                                    //   `${post?.user_details?.user_profile?.first_name}-${post?.user_details?.user_profile?.last_name}`,
                                                    //   post?.user_details?.id
                                                    // )
                                                  }
                                                  src={
                                                    post?.user_details
                                                      ?.user_profile
                                                      ?.profile_photo
                                                      ? post?.user_details
                                                        ?.user_profile
                                                        ?.profile_photo
                                                      : Images.puzzleProfile
                                                  }
                                                  className="postUser me-3"
                                                  alt="UserImage"
                                                />
                                              )}
                                              <div className="postsDetails">
                                                <p className="innerSubtext m-0">
                                                  <strong>
                                                    {
                                                      post?.user_details
                                                        ?.company_name
                                                    }
                                                  </strong>
                                                </p>
                                                {userDeatil?.user_id ==
                                                  post?.user_id ? (
                                                  <h5
                                                    onClick={() =>
                                                      // handlePageRedirectMyProfile(
                                                      //   post?.user_details
                                                      //     ?.user_company_information
                                                      //     ?.company_name
                                                      // )
                                                      userNavigation(post?.user_details, post?.user_details?.is_invited == 2 ? false : "company")
                                                      // UserProfileNavigation(
                                                      //     `${post?.user_details?.user_profile?.first_name}-${post?.user_details?.user_profile?.last_name}`,
                                                      //     post?.user_details?.id
                                                      //   )
                                                    }
                                                    className="headingTitleSmall_ d-flex align-items-center justify-content-between"
                                                  >
                                                    <span className="memberName">
                                                      {
                                                        post?.user_details
                                                          ?.user_profile
                                                          ?.first_name
                                                      }{" "}
                                                      {
                                                        post?.user_details
                                                          ?.user_profile
                                                          ?.last_name
                                                      }
                                                    </span>
                                                  </h5>
                                                ) : (
                                                  <h5
                                                    onClick={() =>
                                                      // handlePageRedirect(
                                                      //   post?.user_details
                                                      //     ?.user_company_information
                                                      //     ?.company_name,
                                                      //   post?.user_details
                                                      //     ?.user_company_information
                                                      //     ?.id
                                                      // )
                                                      userNavigation(post?.user_details, post?.user_details?.is_invited == 2 ? false : "company")
                                                      // UserProfileNavigation(
                                                      //     `${post?.user_details?.user_profile?.first_name}-${post?.user_details?.user_profile?.last_name}`,
                                                      //     post?.user_details?.id
                                                      //   )
                                                    }
                                                    className="headingTitleSmall_ d-flex align-items-center justify-content-between"
                                                  >
                                                    <span className="memberName">
                                                      {
                                                        post?.user_details
                                                          ?.user_profile
                                                          ?.first_name
                                                      }{" "}
                                                      {
                                                        post?.user_details
                                                          ?.user_profile
                                                          ?.last_name
                                                      }
                                                    </span>
                                                  </h5>
                                                )}
                                                <p className="developerAcc mb-0">
                                                  {comapanyInfo?.company_name}
                                                </p>
                                                <span className="weeksTxt">
                                                  {moment(
                                                    post?.created_at
                                                  ).fromNow()}
                                                </span>
                                                {/* {post?.post?.startsWith(
                                                  "http"
                                                ) ||
                                                post?.post?.startsWith(
                                                  "https"
                                                ) ? (
                                                  <a
                                                    className="linkIndex_"
                                                    href={post?.post}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    key={key}
                                                  >
                                                    {post?.post}
                                                  </a>
                                                ) : (
                                                  <p className="innerSubtextSmall text-break text-start about15">
                                                    {post?.post}
                                                  </p>
                                                )} */}
                                                {/* {post?.post && (
                                                  <Linkify
                                                    componentDecorator={(
                                                      decoratedHref,
                                                      decoratedText,
                                                      key
                                                    ) => (
                                                      <a
                                                        target="_blank"
                                                        href={decoratedHref}
                                                        key={key}
                                                      >
                                                        {decoratedText}
                                                      </a>
                                                    )}
                                                  >
                                                    {post?.post
                                                      .split("\n")
                                                      .map((line, index) => (
                                                        <React.Fragment
                                                          key={index}
                                                        >
                                                          {line}
                                                          <br />
                                                        </React.Fragment>
                                                      ))}
                                                  </Linkify>
                                                )} */}
                                              </div>
                                            </div>

                                            <div className="postOptionBox">
                                              <div className="dropdown">
                                                <p
                                                  className="dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenuButton1"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="las la-ellipsis-h moreOptionIcon"></i>
                                                </p>
                                                <ul
                                                  className="dropdown-menu"
                                                  aria-labelledby="dropdownMenuButton1"
                                                >
                                                  <li
                                                    onClick={() =>
                                                      copyLinkToClipboard(
                                                        post?.id
                                                      )
                                                    }
                                                  >
                                                    <a className="dropdown-item">
                                                      Copy link to post
                                                    </a>
                                                  </li>
                                                  {userInformation?.id ==
                                                    post?.user_id && (
                                                      <>
                                                        <li
                                                          onClick={() => {
                                                            setEditId(post?.id);
                                                            editPostAction(
                                                              post?.id
                                                            );
                                                            setModalDetail({
                                                              show: true,
                                                              flag: "AddANewPost",
                                                              isShared:
                                                                post?.shared_post_data !=
                                                                null,
                                                            });
                                                            setKey(Math.random());
                                                          }}
                                                        >
                                                          <a className="dropdown-item">
                                                            Edit post
                                                          </a>
                                                        </li>

                                                        <li
                                                          onClick={(e) => {
                                                            deletePostAction(
                                                              post?.id,
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          <a className="dropdown-item">
                                                            Delete post
                                                          </a>
                                                        </li>

                                                        <li
                                                          onClick={(e) => {
                                                            disableEnableCommentAction(
                                                              post?.id,
                                                              post?.disable_comment ==
                                                                1
                                                                ? 2
                                                                : 1,
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          <a className="dropdown-item">
                                                            {post?.disable_comment ==
                                                              1
                                                              ? "Enable comments on this post"
                                                              : "Disable comments on this post"}
                                                          </a>
                                                        </li>

                                                        <li>
                                                          <Link
                                                            to="/settings"
                                                            className="dropdown-item"
                                                          >
                                                            who can see this post
                                                            ?
                                                          </Link>
                                                        </li>
                                                      </>
                                                    )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <p
                                        className="innerSubtextSmall text-break text-start mt-2 pb-4 cursor_text"
                                        style={{ fontSize: "15px" }}
                                      >
                                          {renderPostText(post?.post,post?.expanded,post)}
</p>
                                          {post?.attachment_type == 2 ? (
                                            <>
                                              <div className="uploadProfile_ uploadProfile__">
                                                <i className="fas fa-file-pdf ms-start me-3"></i>
                                                <div className="profileDetail_">
                                                  <h5 className="profileboldHead">
                                                    {post?.attachment_name}
                                                  </h5>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {post?.attachment_type == 3 ? (
                                            <>
                                              {post?.isvideo == true ? (
                                                // <video controls autoPlay height={540}>
                                                //   <source
                                                //     src={post?.attachment}
                                                //     type="video/mp4"
                                                //     className="soundbiteImg"
                                                //   />
                                                // </video>
                                                <VideoPlayer video={post?.attachment}/>
                                              ) : (
                                                <div className="postBox mb-3">
                                                  <FadeInImage
                                                    className="imagepost_ profilePostImage"
                                                    src={post?.thumbnail_url}
                                                  />
                                                  <FadeInImage
                                                    onClick={() =>
                                                      handleVideoPlay(
                                                        post,
                                                        index
                                                      )
                                                    }
                                                    className="videoIcon"
                                                    src={Images.VideoIcon}
                                                  />
                                                </div>
                                              )}
                                            </>
                                          ) : post?.attachment == null ? (
                                            ""
                                          ) : (
                                            ""
                                          )}
                                          {post?.attachment_type == 1 ? (
                                            <div className="postImage_ mt-3 mb-3">
                                              <FadeInImage
                                                className="imagepost_ profilePostImage"
                                                src={post?.attachment}
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div className="mt-4"></div>
                                          {post?.shared_post_data ? (
                                            <SharedPost
                                              post={post?.shared_post_data}
                                            />
                                          ) : (
                                            ""
                                          )}

                                          {checkUrl(post?.post).length > 0 ? (
                                            <OpengraphReactComponent
                                              appId={
                                                process.env
                                                  .REACT_APP_OPEN_GRAPH_ID
                                              }
                                              site={
                                                checkUrl(post?.post)[0] ?? ""
                                              }
                                              size={"small"}
                                            />
                                          ) : (
                                            ""
                                          )}

                                          <div className="bottomPostSec d-flex align-items-center justify-content-end my-3">
                                            <p className="postrate_">
                                              <span
                                                onClick={() =>
                                                  totalUserLikeList(post?.id)
                                                }
                                              >
                                                {post?.likeCount}
                                              </span>
                                              {post?.is_like?.like_status &&
                                                post?.is_like?.like_status ==
                                                1 ? (
                                                <FadeInImage
                                                  className="likepost_ me-2"
                                                  src={Images.thumbsup}
                                                  onClick={(e) => {
                                                    postLike(
                                                      post?.id,
                                                      2,
                                                      index
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <FadeInImage
                                                  className="likepost_ me-2"
                                                  src={Images.greyLike}
                                                  onClick={(e) => {
                                                    postLike(
                                                      post?.id,
                                                      1,
                                                      index
                                                    );
                                                  }}
                                                />
                                              )}
                                            </p>
                                            {post?.disable_comment == 2 &&
                                              post?.total_comments > 0 ? (
                                              <p className="postrate_">
                                                <span>
                                                  {post?.total_comments}
                                                </span>

                                                <FadeInImage
                                                  src={Images.message}
                                                  onClick={() =>
                                                    handleLoadMoreComments(
                                                      post?.id,
                                                      index
                                                    )
                                                  }
                                                  className="messageiconn me-2"
                                                  alt="Message"
                                                />
                                              </p>
                                            ) : (
                                              <p className="postrate_">
                                                <span>
                                                  {post?.total_comments}
                                                </span>
                                                <FadeInImage
                                                  src={Images.commentGrey}
                                                  className="messageiconn me-2"
                                                  alt="Message"
                                                />
                                              </p>
                                            )}
                                            {/* <p className="postrate_">
                                                                                        <FadeInImage
                                                                                            src={Images.star}
                                                                                            onClick={(e) => {
                                                                                                postFetures(
                                                                                                    post?.post_details?.id,
                                                                                                    2,
                                                                                                    index
                                                                                                );
                                                                                            }}
                                                                                            className="messageiconn"
                                                                                            alt="Message"
                                                                                        />

                                                                                    </p> */}
                                          </div>
                                          <div className="row">
                                            {postIndexMoreComments === index ? (
                                              <>
                                                <div
                                                  className="dashboardComments"
                                                  id="dashboardComments"
                                                >
                                                  {allCommentList &&
                                                    allCommentList?.length > 0 ? (
                                                    <>
                                                      {allCommentList.map(
                                                        (
                                                          allComment,
                                                          commentindex
                                                        ) => {
                                                          return (
                                                            <>
                                                              <div
                                                                className="row"
                                                                key={
                                                                  commentindex
                                                                }
                                                              >
                                                                <div className="col-lg-1 col-md-1 p-0">
                                                                  <FadeInImage
                                                                    src={
                                                                      Images.commentReply
                                                                    }
                                                                    className="newChatReplyIcon img-fluid me-1"
                                                                    alt="UserImage"
                                                                  />
                                                                </div>
                                                                <div className="col-lg-11 col-md-11 ps-0">
                                                                  <div className="listComments commentreply">
                                                                    <div className="comments mt-2 ">
                                                                      <div className="commentReply newCommentReply">
                                                                        <figure>
                                                                          {userDeatil?.user_id ==
                                                                            allComment?.user_id ? (
                                                                            <FadeInImage
                                                                              onClick={() =>
                                                                                // handlePageRedirectMyProfile(
                                                                                //   allComment
                                                                                //     ?.user_details
                                                                                //     ?.user_company_information
                                                                                //     ?.company_name
                                                                                // )
                                                                                userNavigation(allComment?.user_details, post?.user_details?.is_invited == 2 ? false : "company")
                                                                                // UserProfileNavigation(
                                                                                //                                 `${allComment?.user_details?.user_profile?.first_name}-${allComment?.user_details?.user_profile?.last_name}`,
                                                                                //                                 allComment
                                                                                //                                   ?.user_details
                                                                                //                                   ?.id
                                                                                //                               )
                                                                              }
                                                                              src={
                                                                                allComment
                                                                                  ?.user_details
                                                                                  ?.user_profile
                                                                                  ?.profile_photo
                                                                                  ? allComment
                                                                                    ?.user_details
                                                                                    ?.user_profile
                                                                                    ?.profile_photo
                                                                                  : Images.puzzleProfile
                                                                              }
                                                                              className="chatPosttedUser me-3"
                                                                              alt="UserImage"
                                                                            />
                                                                          ) : (
                                                                            <FadeInImage
                                                                              onClick={() =>
                                                                                // handlePageRedirect(
                                                                                //   allComment
                                                                                //     ?.user_details
                                                                                //     ?.user_company_information
                                                                                //     ?.company_name,
                                                                                //   allComment
                                                                                //     ?.user_details
                                                                                //     ?.user_company_information
                                                                                //     ?.id
                                                                                // )
                                                                                userNavigation(allComment?.user_details, post?.user_details?.is_invited == 2 ? false : "company")
                                                                                //     UserProfileNavigation(
                                                                                //   `${allComment?.user_details?.user_profile?.first_name}-${allComment?.user_details?.user_profile?.last_name}`,
                                                                                //   allComment
                                                                                //     ?.user_details
                                                                                //     ?.id
                                                                                // )
                                                                              }
                                                                              src={
                                                                                allComment
                                                                                  ?.user_details
                                                                                  ?.user_profile
                                                                                  ?.profile_photo
                                                                                  ? allComment
                                                                                    ?.user_details
                                                                                    ?.user_profile
                                                                                    ?.profile_photo
                                                                                  : Images.puzzleProfile
                                                                              }
                                                                              className="chatPosttedUser me-3"
                                                                              alt="UserImage"
                                                                            />
                                                                          )}
                                                                        </figure>
                                                                        <div>
                                                                          {userDeatil?.user_id ==
                                                                            allComment?.user_id ? (
                                                                            <p
                                                                              className="innerSubtextSmall replyPerson"
                                                                              onClick={() =>
                                                                                // handlePageRedirectMyProfile(
                                                                                //   allComment
                                                                                //     ?.user_details
                                                                                //     ?.user_company_information
                                                                                //     ?.company_name
                                                                                // )
                                                                                userNavigation(allComment?.user_details, post?.user_details?.is_invited == 2 ? false : "company")
                                                                                // UserProfileNavigation(
                                                                                //   `${allComment?.user_details?.user_profile?.first_name}-${allComment?.user_details?.user_profile?.last_name}`,
                                                                                //   allComment
                                                                                //     ?.user_details
                                                                                //     ?.id
                                                                                // )
                                                                              }
                                                                            >
                                                                              <b>
                                                                                {
                                                                                  allComment
                                                                                    ?.user_details
                                                                                    ?.user_profile
                                                                                    ?.first_name
                                                                                }{" "}
                                                                                {
                                                                                  allComment
                                                                                    ?.user_details
                                                                                    ?.user_profile
                                                                                    ?.last_name
                                                                                }
                                                                              </b>
                                                                            </p>
                                                                          ) : (
                                                                            <p
                                                                              className="innerSubtextSmall replyPerson"
                                                                              onClick={() =>
                                                                                // handlePageRedirect(
                                                                                //   allComment
                                                                                //     ?.user_details
                                                                                //     ?.user_company_information
                                                                                //     ?.company_name,
                                                                                //   allComment
                                                                                //     ?.user_details
                                                                                //     ?.user_company_information
                                                                                //     ?.id
                                                                                // )
                                                                                userNavigation(allComment?.user_details, post?.user_details?.is_invited == 2 ? false : "company")
                                                                                // UserProfileNavigation(
                                                                                //   `${allComment?.user_details?.user_profile?.first_name}-${allComment?.user_details?.user_profile?.last_name}`,
                                                                                //   allComment
                                                                                //     ?.user_details
                                                                                //     ?.id
                                                                                // )
                                                                              }
                                                                            >
                                                                              <b>
                                                                                {
                                                                                  allComment
                                                                                    ?.user_details
                                                                                    ?.user_profile
                                                                                    ?.first_name
                                                                                }{" "}
                                                                                {
                                                                                  allComment
                                                                                    ?.user_details
                                                                                    ?.user_profile
                                                                                    ?.last_name
                                                                                }
                                                                              </b>
                                                                            </p>
                                                                          )}
                                                                          <h5 className="headingTitleSmall_ ">
                                                                            <span className="commentText mb-2 replyTime">
                                                                              {moment(
                                                                                allComment?.created_at
                                                                              ).fromNow()}
                                                                            </span>
                                                                          </h5>
                                                                        </div>
                                                                      </div>

                                                                      <p className="innerSubtextSmall mt-2">
                                                                        {
                                                                          allComment?.comment
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                    <div className="commentReply justify-content-between my-3">
                                                                      <figure>
                                                                        <p className="postrate_ justify-content-end">
                                                                          {allComment?.is_comment_liked ? (
                                                                            <FadeInImage
                                                                              className="likepost_ me-2"
                                                                              src={
                                                                                Images.thumbsup
                                                                              }
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                commentLikeAction(
                                                                                  allComment?.id,
                                                                                  2,
                                                                                  commentindex,
                                                                                  "",
                                                                                  ""
                                                                                );
                                                                              }}
                                                                            />
                                                                          ) : (
                                                                            <FadeInImage
                                                                              className="likepost_ me-2"
                                                                              src={
                                                                                Images.greyLike
                                                                              }
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                commentLikeAction(
                                                                                  allComment?.id,
                                                                                  1,
                                                                                  commentindex,
                                                                                  "",
                                                                                  ""
                                                                                );
                                                                              }}
                                                                            />
                                                                          )}
                                                                          <span>
                                                                            {allComment?.total_like_count
                                                                              ? allComment?.total_like_count
                                                                              : allComment?.CommentLikeCount
                                                                                ? allComment?.CommentLikeCount
                                                                                : 0}
                                                                          </span>
                                                                        </p>
                                                                      </figure>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allCommentList?.length > 1 &&
                                                    allCommentData.total >
                                                    allCommentList?.length && (
                                                      <div
                                                        className="text-center"
                                                        onClick={() => {
                                                          handleLoadMoreComments(
                                                            post?.post_id,
                                                            postIndexMoreComments,
                                                            page + 1,
                                                            10,
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        <i className="las la-angle-double-down loadMore"></i>
                                                      </div>
                                                    )}
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            <div className="footerComment">
                                              <div className="commentBox_ px-0">
                                                <div className="messageTextarea">
                                                  <FadeInImage
                                                    className="img-fluid postUser"
                                                    src={
                                                      userInfo?.user_profile
                                                        ?.profile_photo
                                                        ? userInfo?.user_profile
                                                          ?.profile_photo
                                                        : Images.puzzleProfile
                                                    }
                                                  />
                                                  <div className="form-group">
                                                    {replyFlag && (
                                                      <div className="d-flex justify-content-between">
                                                        <h6>
                                                          Reply to {replyName}
                                                        </h6>

                                                        <i
                                                          className="fa fa-times mt-0 cursor-pointer"
                                                          onClick={() => {
                                                            setReplyId(null);
                                                            setReplyName("");
                                                            setReplyFlag(false);
                                                            setSelectedIndex(
                                                              null
                                                            );
                                                          }}
                                                        ></i>
                                                      </div>
                                                    )}
                                                    <textarea
                                                      type="text"
                                                      className="customFormControl"
                                                      value={
                                                        post.id === postID
                                                          ? comment
                                                          : ""
                                                      }
                                                      placeholder="Have something to say..."
                                                      id="inputName"
                                                      name="post"
                                                      key={index}
                                                      onChange={(e) => {
                                                        setComment(
                                                          e.target.value
                                                        );
                                                        setPostID(post?.id);
                                                      }}
                                                    />
                                                    <FadeInImage
                                                      className="sendIcon_"
                                                      src={Images.sendIcon_}
                                                      onClick={() => {
                                                        postComment(
                                                          post?.id,
                                                          index
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  <FadeInImage
                                                    className="sendIcon_"
                                                    src={Images.sendIcon_}
                                                    onClick={() => {
                                                      postComment(
                                                        post?.id,
                                                        index,
                                                        "comment"
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  "No Post Found"
                                )}
                              </InfiniteScroll>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">

                    <div
                      className={`containerBoxright mb-4 profileAbout ${userInformation?.business_type == 2 ? "d-none" : ""
                        }`}
                    >
                      <FadeInImage
                        src={
                          companyInfo?.companyDetails?.company_logo ??
                          Images.puzzleProfile
                        }
                      />
                      <h5 className="headingTitle_ mb-2">
                        <b className="me-2">About</b>
                        {companyInfo?.companyDetails?.company_name}
                      </h5>
                      <p className="listHead_">
                        {companyInfo?.companyDetails?.company_service_description?.split(
                          " "
                        ).length -
                          1 >=
                          100
                          ? companyInfo?.companyDetails?.company_service_description
                            ?.split(" ")
                            ?.slice(0, 100)
                            .join(" ")
                          : companyInfo?.companyDetails
                            ?.company_service_description}{" "}
                      </p>
                      <button
                        className="subsmallBtngreen_ mt-2"
                        onClick={() => {
                          UserCompanyNavigation(
                            companyInfo?.companyDetails?.company_name,
                            companyInfo?.companyDetails?.id
                          );
                        }}
                      >
                        <b>View Company Profile</b>
                      </button>
                    </div>

                    <CompanyDetails classname={`containerBoxright ${companyInfo?.companyDetails ? "" : "d-none"
                      } ${userInformation?.business_type == 2
                        ? "profileAbout pt-4"
                        : ""
                      }`} companyDetails={companyInfo?.companyDetails} />

                    <UserCertificate existingCertificate={companyInfo?.companyDetails?.company_existing_certificate_details} />
                    <UserAcclamation choosePlan={choosePlan}
                      id={id}
                      authSelector={authSelector}
                      chooseBusinessType={chooseBusinessType}
                      leaveEndorsements={leaveEndorsements}
                      companyInfo={companyInfo}
                      leaveEndorsementsDate={leaveEndorsementsDate}
                      onChangeDateLeaveEndorsement={onChangeDateLeaveEndorsement}
                      leaveEndorsementsDescription={leaveEndorsementsDescription}
                      setLeaveEndorsementsDescription={setLeaveEndorsementsDescription}
                      CancleEndrosement={CancleEndrosement}
                      SubmitEndrosement={SubmitEndrosement}
                      userById={userById}
                      UserProfileNavigation={userNavigation}
                      setLeaveEndorsements={setLeaveEndorsements}
                    />
                    <BusinessResource />

                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "submitendorsement" ? "submitendorsement" : ""
        }
        ids={
          modalDetail.flag === "submitendorsement"
            ? "submitendorsement"
            : modalDetail.flag === "AddANewPost"
              ? "addNewPost"
              : modalDetail.flag === "allPostLikeUser"
                ? "allPostLikeUser"
                : modalDetail.flag === "cropImage"
                  ? "cropImage"
                  : ""
        }
        child={
          modalDetail.flag === "submitendorsement" ? (
            <SubmitEndrosment close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "ComposeMessage" ? (
            <SendMessage
              close={() => handleOnCloseModal()}
              companyInfo={companyInfo}
              userId={Number(id)}
              chat_for={1}
              from="companyProfile"
              handleCompany={(id) => updateCompanyInformation(id)}
            />
          ) : modalDetail.flag == "AddANewPost" ? (
            <AddNewPost
              // closeAllCommentsMore={setAllCommentsLoad(true)}
              close={() => handleOnCloseModal()}
              selectedCompanies={
                mainTags.companies?.length > 0 ? mainTags.companies : []
              }
              selectedUsers={mainTags.users?.length > 0 ? mainTags.users : []}
              // updateTagData={(data, ids) => updateTagData(data, ids)}
              setAllPostList={setAllPostList}
              selectedIds={selectedIds}
              allPost={allPost}
              editId={editId}
              setEditId={setEditId}
              userDeatil={userDeatil}
              editFlag={editFlag}
              isSharedPost={modalDetail?.isShared}
              setEditFlag={setEditFlag}
              userData={userInformation}
              UpdateData={() => {
                updateCompanyInformation(id);
              }}
            />
          ) : modalDetail.flag === "cropImage" ? (
            <ImageCropper
              onCropComplete={onCropComplete}
              setDisplayHeight={setDisplayHeight}
              setDisplayWidth={setDisplayWidth}
              close={() => handleOnCloseModal()}
              uploadPhoto={(params) => {
                uploadPhoto(params);
              }}
              aspectRatio={flag == 2 ? 5 / 2 : 1}
              fileBlob={fileBlob}
              imageSrc={image ? URL.createObjectURL(image) : ""}
            />
          ) : modalDetail.flag == "allPostLikeUser" ? (
            <AllPostLikeUser
              id={modalDetail.id}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "submitendorsement" ? (
                <h2 className="addLabels_heading">Acclamation</h2>
              ) : modalDetail.flag === "ComposeMessage" ? (
                <h2 className="addLabels_heading">Compose Message</h2>
              ) : modalDetail.flag == "allPostLikeUser" ? (
                <h2 className="headingSmall_">Post Like List</h2>
              ) : modalDetail.flag == "AddANewPost" ? (
                <>
                  <h2 className="headingTitle_">Edit Your Post </h2>
                  <button onClick={() => handleOnCloseModal()}>
                    <FadeInImage
                      src={Images.modalCross}
                      alt=""
                      className="cursor"
                      type="button"
                    />
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        footerContent={
          <div className="footerContent">
            {modalDetail.flag === "cropImage" ? (
              <>
                <button
                  className="primaryBtn mx-2 cropBtnModal"
                  form="edit-user-details"
                  type="button"
                  onClick={() => {
                    makeClientCrop(fileBlob, croppedAreaPixels);
                  }}
                >
                  Crop Image
                </button>
                <button
                  className="subsmallBtnblue_ mx-2"
                  onClick={handleOnCloseModal}
                >
                  Cancel
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default UserProfile;
