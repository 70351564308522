import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import Linkify from "react-linkify";
import moment from "moment";
import OpengraphReactComponent from "opengraph-react";
import { checkUrl, useUserNavigation, useUserProfileNavigation } from "../../commonFunctions";
import FadeInImage from "../../../utilities/FadeInImage";

function SharedPost(props) {
  const [post, setPost] = useState();
  useEffect(()=>{
    setPost(prev=>props?.post == undefined ? prev : props?.post)
  },[props])
  const [isExpanded, setIsExpanded] = useState(false);
  const maxTextLength = 200; // Set the max length for the truncated text

  const companyInfo =
    post?.user_details?.is_invited === 2
      ? post?.user_details?.user_selected_company_information
          ?.user_company_information
      : post?.user_details?.user_company_information;

  const UserProfileNavigation = useUserProfileNavigation();
  const UserNavigation = useUserNavigation();

  const handleVideoPlay = (post, index) => {
    setPost((prev) => ({ ...prev, isvideo: true }));
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const renderPostText = () => {
    if (!post?.post) return null;

    if (isExpanded || post.post.length <= maxTextLength) {
      return (
        <>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="_blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {post?.post.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Linkify>
          {post.post.length > maxTextLength && (
            <span
              onClick={toggleText}
              style={{ color: "blue", cursor: "pointer" }}
            >
              Show Less
            </span>
          )}
        </>
      );
    }

    return (
      <>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="_blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {post.post.substring(0, maxTextLength)}...
        </Linkify>
        <span
          onClick={toggleText}
          style={{ color: "blue", cursor: "pointer" }}
        >
          Read More
        </span>
      </>
    );
  };

  return (
    <div className="reSharePostOuter mb-4">
      <div className="posts">
        <div>
          <FadeInImage
            src={
              post?.user_details?.user_profile?.profile_photo ?? Images.puzzleProfile
            }
            onClick={() => {
              UserNavigation(post.user_details);
            }}
            className="postUser me-3"
            alt="UserImage"
          />
        </div>
        <div className="postsDetails">
          <h5
            className="headingTitleSmall_ d-flex align-items-center justify-content-between mb-0"
            onClick={() => {
              UserNavigation(post.user_details);
            }}
          >
            <span className="memberName">
              {post?.user_details?.user_profile?.first_name}{" "}
              {post?.user_details?.user_profile?.last_name}
            </span>
          </h5>
          <p className="developerAcc mb-0">{companyInfo?.company_name}</p>
          <span className="weeksTxt">{moment(post?.created_at).fromNow()}</span>
        </div>
      </div>
      <p
        className="innerSubtextSmall text-break text-start mt-2 pb-4 cursor_text"
        style={{ fontSize: "15px" }}
      >
        {renderPostText()}
      </p>
      {post?.attachment_type == 2 && (
        <div className="uploadProfile_ uploadProfile__">
          <i className="fas fa-file-pdf ms-start me-3"></i>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="profileDetail_">
              <h5 className="profileboldHead">{post?.attachment_name}</h5>
            </div>
            <a href={post?.attachment} target="_blank">
              <i className="fas fa-eye"></i>
            </a>
          </div>
        </div>
      )}
      {post?.attachment_type == 3 && (
        <>
          {post?.isvideo ? (
            <video controls autoPlay height={540}>
              <source
                src={post?.attachment}
                type="video/mp4"
                className="soundbiteImg"
              />
            </video>
          ) : (
            <div className="postBox mb-3">
              <FadeInImage
                className="imagepost_ postThumbnailnail"
                src={post?.thumbnail_url}
              />
              <FadeInImage
                onClick={() => handleVideoPlay(post)}
                className="videoIcon"
                src={Images.VideoIcon}
              />
            </div>
          )}
        </>
      )}
      {post?.attachment_type == 1 && (
        <div className="postImage_ mt-3 mb-3">
          <FadeInImage className="imagepost_" src={post?.attachment} />
        </div>
      )}

      {checkUrl(post?.post).length > 0 && (
        <OpengraphReactComponent
          appId={process.env.REACT_APP_OPEN_GRAPH_ID}
          site={checkUrl(post?.post)[0] ?? ""}
          size={"small"}
        />
      )}
    </div>
  );
}

export default SharedPost;
