import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    archiveUpdate:{},
    getMyArchiveOppertunities:{},
    loading: false
}

export const archiveOpportunitySlice = createSlice({
    name: 'archiveOpportunity',
    initialState,
    reducers: ({
        updateArchive: (state) => {
            state.loading = true
        },
        setUpdateArchive: (state, action) => {
            state.loading = false
            state.archiveUpdate = action.payload
        },
        getMyArchiveOppertunity: (state) => {
            state.loading = true
        },
        setGetMyArchiveOppertunity: (state, action) => {
            state.loading = false
            state.getMyArchiveOppertunities = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        },
        updateQueryArchive: (state) =>{
            state.loading= true
        },
    }),
})

// Action creators are generated for each case reducer function
export const { updateArchive, setUpdateArchive,getMyArchiveOppertunity,setGetMyArchiveOppertunity,onErrorStopLoad } = archiveOpportunitySlice.actions

export default archiveOpportunitySlice.reducer