import React from 'react'
import FadeInImage from '../../../utilities/FadeInImage';
import SpinLoader from '../../../utilities/SpinLoader';

const CompanyDetails = ({ classname, companyDetails, companyLoading }) => {

    return (
        <div
            className={classname}
        >
            <div className="companyDetailsRight">
                <h5 className="headingTitle_ mb-2">
                    <b>Company Details</b>
                </h5>
                {companyLoading ? (
                      <>
                        <SpinLoader/>
                      </>
                    ) : (
                <ul className="companyDetailsList">
                    <li className="">
                        <span className="blueinnerSubtext pe-2">
                            Website
                        </span>
                        <a
                            className="listHead_ text-break"
                            href={
                                companyDetails?.company_website
                            }
                            target="_blank"
                        >
                            {" "}
                            {companyDetails?.company_website}
                        </a>
                    </li>
                    {companyDetails
                        ?.industries_group_details === null ? (
                        ""
                    ) : (
                        <li className="flex-wrap">
                            <span className="blueinnerSubtext">Industry</span>
                            <span className="listHead_">
                                {
                                    companyDetails
                                        ?.industries_group_details?.name
                                }
                            </span>
                        </li>
                    )}

                    <li className="flex-wrap">
                        <span className="blueinnerSubtext">
                            Company Size
                        </span>
                        <span className="listHead_">
                            {companyDetails
                                ?.number_of_employees == 0
                                ? companyDetails
                                    ?.number_of_employees
                                : companyDetails
                                    ?.number_of_employees + `+ employees`}{" "}
                        </span>
                    </li>

                    {companyDetails
                        ?.main_company_country_location ? (
                        <li className="flex-wrap">
                            <span className="blueinnerSubtext">
                                Locations
                            </span>
                            <span className="listHead_">
                                {
                                    companyDetails
                                        ?.main_company_country_location
                                }
                            </span>
                        </li>
                    ) : (
                        ""
                    )}
                    {companyDetails
                        ?.company_diverse_supplier_description?.length ===
                        0 ? (
                        ""
                    ) : (
                        <li>
                            <ul className="recentlyDataList mt-0">
                                <li>
                                    {companyDetails?.company_diverse_supplier_description?.map(
                                        (val, index) => (
                                            <div
                                                className="recentlyBtn d-inline-block me-1"
                                                key={index}
                                            >
                                                {
                                                    val
                                                        ?.diverse_supplier_description_details
                                                        ?.name
                                                }
                                            </div>
                                        )
                                    )}
                                </li>
                            </ul>
                        </li>
                    )}
                </ul>
                    )}
            </div>
        </div>
    )
}

export default CompanyDetails