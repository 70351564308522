import React, { useCallback, useEffect, useState, useRef } from "react";
import OpengraphReactComponent from "opengraph-react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { faImages } from "@fortawesome/free-regular-svg-icons";

import { useAuthSelector } from "../../../../redux/selector/auth";
import { useDashboardSelector } from "../../../../redux/selector/dashboard";
import { getCompanyProfileInfo } from "../../../../redux/slices/auth";
import {
  editPost,
  getPostSingleDetails,
  postImageUplaod,
  userPost,
} from "../../../../redux/slices/dashboard";
import * as Images from "../../../../utilities/images";
import CustomModal from "../../../components/common/shared/CustomModal";
import AddTagToPost from "./addTagToPost";
import SharedPost from "../SharedPost";
import FadeInImage from "../../../../utilities/FadeInImage";
import SpinLoader from "../../../../utilities/SpinLoader";

const AddNewPost = (props) => {
  const dispatch = useDispatch();
  const previewContainer = useRef()
  const authSelector = useAuthSelector();
  const [showCloseBtn, setShowCloseBtn] = useState(false)

  const dashboardSelector = useDashboardSelector();

  const [key, setKey] = useState(Math.random());
  const [url, setUrl] = useState("");

  const [showPreview, setShowPreview] = useState(false);

  // const [previewData, setPreviewData] = useState(null);
  // const [loading, setLoading] = useState(true);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const [userDetailProfile, setUserDetailProfile] = useState("");
  const [post, setPost] = useState("");
  const [loader, setLoader] = useState(false);
  const [photo, setPhotoUpload] = useState({
    uploadPhoto: "",
    flag: "",
  });
  const [photoInfo, setPhotoInfo] = useState();
  const [uploadloader, setUploadLoader] = useState(false);
  const [videoUrlOriginal, setVideoUrlOriginal] = useState();

  const [mainTags, setMainTags] = useState({
    companies: [],
    users: [],
  });

  const [selectedIds, setSelectedIds] = useState({
    companies: [],
    users: [],
  });
  const [showCrossIcon, setShowCrossIcon] = useState(false);

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
    // fetchData();
  };

  const updateTagData = (tagData, idData) => {
    setMainTags(tagData);
    setSelectedIds(idData);
  };
  useEffect(() => {
    if (previewContainer?.current?.children?.length >= 1) {
      setShowCloseBtn(true)
    } else {
      setShowCloseBtn(false)
    }
  }, [previewContainer?.current?.children, post])
  const [SharedPostData,setSharedPostData] = useState()


  const checkUrl = (value) => {
    const urlRegex =
      /\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/g;
    const urls = value.match(urlRegex) ?? "";

    if ((url.length !== urls.length && urls.length <= 1) || url == "") {
      for (let i = 0; i < urls.length; i++) {
        if (urls[i].startsWith("https://")) {
          continue;
        } else if (urls[i].startsWith("https:/")) {
          urls[i] = urls[i].replace("https:/", "https://");
        } else if (urls[i].startsWith("https:")) {
          urls[i] = urls[i].replace("https:", "https://");
        } else if (urls[i].startsWith("https")) {
          urls[i] = urls[i].replace("https", "https://");
        } else {
          urls[i] = "https://" + urls[i];
        }
      }
      setUrl(urls);
    }
  }


  //onChange input
  const onChangeInput = (e, fieldName) => {
    const value = e.target.value;
    if (fieldName === "post") {
      setPost(value);
      // Corrected the declaration of the regex pattern
      checkUrl(value)




    }
  };

  useEffect(() => {
    if (url !== "") {
      setShowPreview(false);
      setShowCrossIcon(false)
      setTimeout(() => {
        setShowPreview(true);
        setShowCrossIcon(true)

      }, "50");
    }
  }, [url]);

  //remove file
  const removeFile = (file) => () => {
    const newFiles = [photo];
    newFiles.splice(newFiles.indexOf(file), 1);
    setPhotoUpload(newFiles);
    setPhotoInfo("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!post.trim()) {
      toast.error("Please enter Post text");
      return;
    } else if (!post) {
      toast.error("Please enter Post without trim");
      return;
    } else if (post?.length <= 2) {
      toast.error("Add more characters to Post ");
      return;
    }
    let params = {};

    if (post !== "") {
      params.post = post.trim();
    }

    if (photoInfo) {
      params.attachment = videoUrlOriginal;
      params.attachment_type = photo.flag;
      params.thumbnail_url = photo.uploadPhoto?.url;
      params.attachment_name = photo.uploadPhoto?.name;
      params.attachment_size = photo.uploadPhoto?.size;
    }

    if (selectedIds.companies?.length > 0) {
      params.company_tags = selectedIds.companies;
    }

    if (selectedIds?.users?.length > 0) {
      params.user_tags = selectedIds.users;
    }

    if (props?.editFlag) {
      params.id = props?.editId;
      dispatch(
        editPost({
          ...params,
          cb(res) {
            if (res.status == 200) {
              props?.UpdateData && props?.UpdateData();
              props?.close();
              props?.setEditFlag(false);
              props?.setEditId("");
              props?.setEditPostID(null);
              setVideoUrlOriginal("");
              setPhotoInfo("");
              setPost("");
              setPhotoUpload({ ...photo, uploadPhoto: "", flag: "" });
              setMainTags({
                companies: [],
                users: [],
              });
              setSelectedIds({
                companies: [],
                users: [],
              });

              props?.setAllPostList((prevData) => {
                const data = prevData.filter(
                  (item, i) => res.data.payload.id != item.id
                );
                const newData = [res.data.payload, ...data];
                return newData;
              });
              toast.success("Post Updated Successfully");
            } else {
            }
          },
        })
      );
    } else {
      setLoader(true);
      dispatch(
        userPost({
          ...params,
          cb(res) {
            if (res.status) {
              props?.close();
              setLoader(false);
              setVideoUrlOriginal("");
              setPhotoInfo("");
              setPost("");
              setMainTags({
                companies: [],
                users: [],
              });
              setSelectedIds({
                companies: [],
                users: [],
              });
              setPhotoUpload({ ...photo, uploadPhoto: "", flag: "" });

              props?.setAllPostList([res.data.payload, ...props?.allPost]);
            }
          },
        })
      );
    }
  };

  //onDrop
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      rejectedFiles.forEach((file) => {
        toast.warning(`Please select mentioned file type only.`);
      });
      return;
    }

    let fileaaray = acceptedFiles.map((file) => {
      const newFile = new File([file], file.name, { type: file.type });
      return newFile
    });

    let imageFile = fileaaray[0];
    let params = {
      photo: imageFile,
      type: "post",
    };
    dispatch(
      postImageUplaod({
        ...params,
        cb(res) {
          if (res.status) {
            if (res?.data?.payload?.originalUrl?.format == "video/mp4") {
              setPhotoUpload({
                ...photo,
                uploadPhoto: res?.data?.payload?.thumbnailUrl,
                flag: 3,
              });
              setVideoUrlOriginal(res?.data?.payload?.originalUrl.url);
            } else if (res?.data?.payload?.originalUrl?.format == "video/quicktime") {
              setPhotoUpload({
                ...photo,
                uploadPhoto: res?.data?.payload?.thumbnailUrl,
                flag: 3,
              });
              setVideoUrlOriginal(res?.data?.payload?.originalUrl.url);
            } else if (
              res?.data?.payload?.originalUrl?.format == "application/pdf"
            ) {
              setPhotoUpload({
                ...photo,
                uploadPhoto: res?.data?.payload?.originalUrl,
                flag: 2,
              });
              setVideoUrlOriginal(res?.data?.payload?.originalUrl.url);
            } else {
              setPhotoUpload({
                ...photo,
                uploadPhoto: res?.data?.payload?.thumbnailUrl,
                flag: 1,
              });
              setVideoUrlOriginal(res?.data?.payload?.originalUrl.url);
            }
            setPhotoInfo([res?.data?.payload?.thumbnailUrl.url]);
          } else {
          }
        },
      })
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "application/pdf": [],
      "video/mp4": [], // Allow MP4 video files
      "video/quicktime": [], // Allow MOV video files
    },
  });

  const removeTags = (itemId, name, type) => {
    const data = { ...mainTags };
    const IdData = { ...selectedIds };

    if (type === "company") {
      if (IdData.companies.includes(itemId)) {
        // Remove the company from the selected list and data
        const index = IdData.companies.indexOf(itemId);
        if (index > -1) {
          IdData.companies.splice(index, 1);
          data.companies = data.companies.filter(
            (company) => company.id !== itemId
          );
        }
      }
    } else {
      if (IdData.users.includes(itemId)) {
        // Remove the user from the selected list and data
        const index = IdData.users.indexOf(itemId);
        if (index > -1) {
          IdData.users.splice(index, 1);
          data.users = data.users.filter((user) => user.id !== itemId);
        }
      }
    }

    setMainTags(data);
    setSelectedIds(IdData);
  };

  let ComapnyLogo;
  if (props?.userDeatil?.company_logo) {
    ComapnyLogo = props?.userDeatil?.company_logo;
  } else {
    ComapnyLogo = Images.puzzleProfile;
  }

  const editPostAction = (id) => {
    let params = {
      id: id,
    };
    dispatch(
      getPostSingleDetails({
        ...params,
        cb(res) {
          if (res.status) {
            props?.setEditFlag(true);
            // setEditPostID(res.data.payload?.id)
            setSharedPostData(res.data.payload?.shared_post_data)
            setPost(res.data.payload?.post ?? "");
            checkUrl(res.data.payload?.post ?? "")
            const companyTagData = res.data.payload?.tag_company_details?.map(
              (item, i) => {
                return {
                  id: item.company_id,
                  name: item?.company_information?.company_name,
                };
              }
            );

            const userTagData = res.data.payload?.tag_user_details?.map(
              (item, i) => {
                return {
                  id: item.user_id,
                  name:
                    item?.user_details?.user_profile?.first_name +
                    " " +
                    item?.user_details?.user_profile?.last_name,
                };
              }
            );

            setMainTags({
              companies: companyTagData,
              users: userTagData,
            });

            setSelectedIds({
              companies: companyTagData.map((item) => item.id),
              users: userTagData.map((item) => item.id),
            });

            if (res?.data?.payload?.attachment) {
              setPhotoUpload({
                uploadPhoto: {
                  name: res?.data?.payload?.attachment_name ?? "File",
                  size: res?.data?.payload?.attachment_size ?? 100000,
                  url: res?.data?.payload?.thumbnail_url,
                },
                flag: res?.data?.payload?.attachment_type,
              });

              setVideoUrlOriginal(res?.data?.payload?.attachment);

              setPhotoInfo([res?.data?.payload?.thumbnail_url]);
            }

           // if (window) window.scrollTo(0, 0);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (props?.editId) {
      editPostAction(props?.editId);
    }
  }, [props?.editId]);

  return (
    <>
      <div className="hrRow"></div>
      <div className="container add-tag-to-post mt-3">
        <div className="addPostCon mb-4">
          <>
            <FadeInImage
              src={
                props?.userData?.user_profile?.profile_photo ??
                Images.puzzleProfile
              }
              alt=""
              className="addPostImg"
            />
            <div className="addPostName">
              <h4 className="addPostTitle mb-0">
                {authSelector?.userInfo?.user?.user_profile?.first_name +
                  " " +
                  (authSelector?.userInfo?.user?.user_profile?.last_name ===
                    null
                    ? " "
                    : authSelector?.userInfo?.user?.user_profile?.last_name)}
              </h4>
              <p className="addPostSub mb-0">
                {props?.userDeatil?.company_name}
              </p>
            </div>
          </>
        </div>
        <div className="form-group mb-3 col-md-12">
          <textarea
            type="text"
            className="customFormControl"
            placeholder="What do you have to tell people..."
            id="inputName"
            name="post"
            value={post}
            onChange={(e) => {
              onChangeInput(e, "post");
            }}
            rows="4"
          />
          <div></div>
        </div>
        {/* <div className="row">
                    {post?.startsWith('https') ?
                        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                            {previewData?.thumbnailUrl && <FadeInImage src={previewData.thumbnailUrl} alt="YouTube Thumbnail" />}
                        </div>
                        : ""}
                </div> */}

        {!props?.isSharedPost && <div className="row">
          {dashboardSelector?.loading ? (
            <SpinLoader />
          ) : (
            <div className="uploadFile_ col-md-12">
              {photoInfo ? (
                <>
                  {photo?.uploadPhoto != "" &&
                    photo?.uploadPhoto?.format == "video/mp4" ? (
                    <>
                      <div className="uploadProfile_">
                        <video controls className="videoBox_ me-3">
                          <source
                            src={photoInfo}
                            type="video/mp4"
                            className="soundbiteImg"
                          />
                        </video>
                        <FadeInImage className="w3-round" src={photoInfo} />
                        <h5 className="profileboldHead me-2">
                          {photo?.uploadPhoto?.name?.length > 40.5
                            ? photo?.uploadPhoto?.name.substring(0, 40.5) +
                            "...."
                            : photo?.uploadPhoto?.name}
                        </h5>
                        <p className="profileboldgraph">
                          {(photo.uploadPhoto?.size / (1024 * 1024)).toFixed(2)}{" "}
                          MB
                        </p>
                        <i
                          className="fa fa-times crossfile text-red"
                          onClick={removeFile(photoInfo)}
                        ></i>
                      </div>
                    </>
                  ) : photo?.uploadPhoto?.format === "application/pdf" ? (
                    <>
                      <ul className="uploadProfile_">
                        <FadeInImage className="w3-round" src={Images.PDFImage} />
                        <div className="profileDetail_">
                          <h5 className="profileboldHead">
                            {photo?.uploadPhoto?.name > 40.5
                              ? photo?.uploadPhoto?.name.substring(0, 40.5) +
                              "...."
                              : photo?.uploadPhoto?.name}
                          </h5>
                          <p className="profileboldgraph">
                            {(photo.uploadPhoto?.size / (1024 * 1024)).toFixed(
                              2
                            )}{" "}
                            MB
                          </p>
                        </div>
                        <i
                          className="fa fa-times crossfile text-red"
                          onClick={removeFile(photoInfo)}
                        ></i>
                      </ul>
                    </>
                  ) : photo?.uploadPhoto ? (
                    <>
                      <ul className="uploadProfile_">
                        <FadeInImage className="w3-round" src={photoInfo} />
                        <div className="profileDetail_">
                          <h5 className="profileboldHead">
                            {photo?.uploadPhoto?.name > 40.5
                              ? photo?.uploadPhoto?.name.substring(0, 40.5) +
                              "...."
                              : photo?.uploadPhoto?.name}
                          </h5>
                          <p className="profileboldgraph">
                            {(photo.uploadPhoto?.size / (1024 * 1024)).toFixed(
                              2
                            )}{" "}
                            MB
                          </p>
                        </div>
                        <i
                          className="fa fa-times crossfile text-red"
                          onClick={removeFile(photoInfo)}
                        ></i>
                      </ul>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <div className="form-group col-md-12 mb-3">
                  <div className="uploadImgeboxNew">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <FadeInImage
                        src={Images.uploadIcon}
                        alt="uploadIcon"
                        className="img-fluid uploadfileIcon hjgfhjfhj"
                      />
                      <p className="uploadbtn mb-0">
                        Drag & Drop or{" "}
                        <span className="ColoredText">browse</span>
                      </p>
                      <p className="uploadText">
                        Supports: JPEG, JPG, PNG or PDF
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="form-group mb-3 col-md-12">
                <div className="row postTags">
                  {mainTags.companies.map((val, index) => {
                    return (
                      <div className="tag col-md-3">
                        <div>
                          <span>{val.name}</span>
                        </div>
                        <div
                          className="close"
                          onClick={() => {
                            removeTags(val.id, val.name, "company");
                          }}
                        >
                          <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    );
                  })}
                  {mainTags.users.map((val, index) => {
                    return (
                      <div className="tag col-md-3">
                        <div>
                          <span>{val.name}</span>
                        </div>
                        <div
                          className="close"
                          onClick={() => {
                            removeTags(val.id, val.name, "users");
                          }}
                        >
                          <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>}
        {props?.isSharedPost && <SharedPost post={SharedPostData} />}

        {showPreview && (url.length > 0) ? (
          <div className="previewbox_" ref={previewContainer}>
            {url.length > 0 && showCloseBtn ? (
              <>
                <FadeInImage
                  src={Images.crossIconn}
                  alt=""
                  className="previewiconclose"
                  onClick={() => {
                    setUrl("");
                    setShowPreview(false);
                    setShowCrossIcon(false)
                  }}
                />
              </>
            ) : (
              ""
            )}

            <OpengraphReactComponent
              appId={process.env.REACT_APP_OPEN_GRAPH_ID}
              site={url[0] ?? ""}
              size={"small"}
            />
          </div>
        ) : (
          ""
        )}
        <button onClick={(e) => handleSubmit(e)} className="postBtn__">
          {dashboardSelector?.dashboard_loader && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          Post Message
        </button>
      </div>
      <CustomModal
        key={key}
        flag={modalDetail.flag}
        showCustomBackdrop={modalDetail.flag === "CommentBox" ? true : false}
        show={modalDetail.show}
        backdrop={modalDetail.flag === "CommentBox" ? null : "static"}
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "CommentBox" ? "commonWidth customContent" : ""
        }
        ids={
          modalDetail.flag === "AddANewPost"
            ? "addNewPost"
            : modalDetail.flag === "CommentBox"
              ? "commentBox"
              : modalDetail.flag === "CompanyLogo"
                ? "CompanyLogo"
                : modalDetail.flag === "allPostLikeUser"
                  ? "allPostLikeUser"
                  : modalDetail.flag === "addTagToPost"
                    ? "addTagToPost"
                    : modalDetail.flag === "Endorsements"
                      ? "Endorsements"
                      : ""
        }
        child={
          modalDetail.flag == "addTagToPost" ? (
            <AddTagToPost
              id={modalDetail.id}
              close={() => handleOnCloseModal()}
              updateTagData={(data, ids) => updateTagData(data, ids)}
              selectedCompanies={props?.selectedCompanies}
              selectedUsers={props?.selectedUsers}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "CommentBox" ? (
            <h2 className="headingSmall_">Comments</h2>
          ) : (
            <div className="modalHeader_">
              <div className="common_">
                {modalDetail.flag === "CompanyLogo" ? (
                  <h2 className="headingSmall_">Company Logo</h2>
                ) : modalDetail.flag == "allPostLikeUser" ? (
                  <h2 className="headingSmall_">Post Like List</h2>
                ) : modalDetail.flag == "addTagToPost" ? (
                  <h2 className="headingSmall_">{modalDetail.header}</h2>
                ) : modalDetail.flag == "AddANewPost" ? (
                  <>
                    <h2 className="headingSmall_">Post a Message</h2>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default AddNewPost;
