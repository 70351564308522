import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import moment from "moment";
import { useAuthSelector } from "../../../redux/selector/auth";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import { allUserWithSearch } from "../../../redux/slices/dashboard";
import {
  getCompanyProfileInfo,
  setSubscriptionID,
} from "../../../redux/slices/auth";
import OneSignal from "react-onesignal";
import CustomModal from "./shared/CustomModal";
import Payment from "../../containers/subscription/payment";
import Plans from "../../containers/subscription/plans";
import UpdatePayment from "./updatePayment";
import { useNotificationSelector } from "../../../redux/selector/notification";
import { toast } from "react-toastify";
import { logout } from "../../../redux/slices/auth";
import { useUserProfileNavigation } from "../../commonFunctions";
import FadeInImage from "../../../utilities/FadeInImage";
const AuthNav = () => {
  const [expanded, setExpanded] = useState(false);
  const [addClassBody, setAddClassBody] = useState(false);
  const authdata = useAuthSelector();
  const inputCloseRef = useRef();
  const notificationSelector = useNotificationSelector();
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const checkAccount = authSelector?.userInfo?.user?.is_deactivate;
  const isInvited = authSelector.companyProfileInfo?.userDetails?.is_invited;
  const dispatch = useDispatch();
  const userId = authSelector?.userInfo?.user?.id;

  const [userDeatil, setUserDeatil] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [searchInput, setSearchInput] = useState("");

  const [searchInputToggle, setSearchInputToggle] = useState(false);
  const [allUser, setAllUser] = useState("");
  const [addClass, setAddClass] = useState("");

  const [oneSignalKey, setOneSignalKey] = useState(Math.random());

  // useEffect(() => {
  //     // Check conditions and create the socket instance
  //     if (authSelector?.userStep?.step === 6 || authSelector?.userInfo?.user?.step === 6) {
  //         const newSocketInstance = socket();
  //         setSocketInstance(newSocketInstance);
  //     }
  // }, [socketSelector?.connected === false]);

  useEffect(() => {
    if (addClassBody) {
      document.body.classList.add("newClass");
    } else {
      document.body.classList.remove("newClass");
    }
  }, [addClassBody]);

  useEffect(() => {
    setExpanded(false);
  }, []);
  const step =
    (authSelector?.userInfo?.user?.step <= 6 &&
      authSelector?.userStep?.step == 6) ||
    authSelector?.userInfo?.user?.step == 6;
  const socialStep =
    (authSelector?.userSignUp?.user?.step <= 6 &&
      authSelector?.userStep?.step == 6) ||
    authSelector?.userInfo?.user?.step == 6;
  const location = useLocation();
  const token = localStorage.getItem("authToken");
  const [activeTab, setActiveTab] = useState("");
  const path = location.pathname;
  const userInfo = authSelector?.userInfo?.user;
  var today_date = new Date();
  today_date.setDate(today_date.getDate() + 1);
  const loggedInCompany =  authSelector?.companyProfileInfo?.userDetails?.is_invited != 2
  ? authSelector?.companyProfileInfo?.userDetails?.user_company_information
  : authSelector?.companyProfileInfo?.userDetails?.user_selected_company_information
    ?.user_company_information;
  

  const userLogout = () => {
    dispatch(
      logout({
        cb(res) {
          if (res.status) {
            navigate("/");
            // emptyDiverseCertificates()
          } else {
          }
        },
      })
    );
  };

  const end_date = new Date(`${currentPlan?.end_date}`);

  const chooseBusinessType =
    authSelector?.userStep?.user?.business_type ??
    authSelector?.userStep?.business_type;

  const choosePlan =
    authSelector?.userStep?.user?.membership_details?.name ??
    authSelector?.userStep?.membership_details?.name;

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  useEffect(() => {
    if (
      path === "/login" ||
      path === "/signup" ||
      path === "/verification" ||
      path === "/" ||
      path == "/enterprise-signup"
    ) {
    } else {
      companyProfileInfo();
    }
    if (
      userInfo &&
      (userInfo?.membership_status == 2 || userInfo?.membership_status == 3) &&
      path != "/setting" &&
      today_date > end_date &&
      location?.state?.from !== "updatePlans"
    ) {
      setModalDetail({
        show: true,
        title: "paymentUpdate",
        flag: "paymentUpdate",
      });
      setKey(Math.random());
    }
  }, [userInfo, path]);

  useEffect(() => {
    if (OneSignal) {
      setTimeout(() => {
        if (OneSignal.User.PushSubscription.id === undefined) {
          localStorage.removeItem("onesignal-notification-prompt");
          OneSignal.Slidedown.promptPush();
        } else {
          if (OneSignal.User.PushSubscription.id) {
            updateSubscriptionID(OneSignal.User.PushSubscription.id);
          }
        }
      }, 8640000);
    }
  }, [OneSignal, oneSignalKey]);

  if (OneSignal) {
    OneSignal.Slidedown.addEventListener("change", (e) => {
      if (e?.to?.optedIn === true && e?.to?.id) {
        if (e?.to?.id !== userInfo?.one_signal_subscription_id) {
          updateSubscriptionID(e?.to?.id);
        }
      }
    });
  }

  const updateSubscriptionID = (id) => {
    let params = {
      one_signal_subscription_id: id,
      one_signal_external_id: `${userInfo.id}getOpps-notification-id`,
    };
    dispatch(
      setSubscriptionID({
        ...params,
        cb(res) {},
      })
    );
  };

  //search input
  const onChangeSearchInput = (searchValue) => {
    setSearchInput(searchValue);
    setSearchInputToggle(true);
  };

  //reset input
  const onChangeReset = () => {
    setSearchInput("");
    getAllUserWithSearch("clear");
  };

  //get all user with search
  const getAllUserWithSearch = (flag) => {
    let params = {
      search: searchInput ? searchInput : "",
    };
    if (flag == "clear") {
      params = { serach: "" };
    }
    dispatch(
      allUserWithSearch({
        ...params,
        cb(res) {
          if (res.status) {
            setAllUser(res?.data?.payload?.data);
          }
        },
      })
    );
  };
  const UserProfileNavigation = useUserProfileNavigation();

  // handle  page change
  const handlePageRedirect = (user) => {
    UserProfileNavigation(
      user?.user_profile?.first_name + "-" + user?.user_profile?.last_name,
      user?.id
    );
    onChangeReset();
    setAllUser("");
    return;
    // user?.user_company_information !== null ?  : user?.user_selected_company_information !== null ? user?.user_selected_company_information?.user_company_information?.id : toast.warning("User not found"), user?.user_company_information?.company_name
    setAddClass("searchboxactive");
    if (user?.user_company_information?.id) {
      // navigate(`/companyfrontProfile/${id}`)

      navigate(
        `/${user?.user_company_information?.company_name}/${user?.user_company_information?.id}`
      );
      onChangeReset();
      setAllUser("");
    } else if (user?.user_selected_company_information !== null) {
      navigate(
        `/${user?.user_selected_company_information?.user_company_information?.company_name}/${user?.user_selected_company_information?.user_company_information?.id}`
      );
      onChangeReset();
      setAllUser("");
    } else {
      toast.warning("User not found");
    }
  };

  //for COMPANY PROFILE INFORMATION.......

  const companyProfileInfo = () => {
    const comapanyData = authSelector?.companyProfileInfo
    if(Object.keys(comapanyData).length === 0){
      dispatch(
        getCompanyProfileInfo({
          cb(res) {
        
            if (
              res?.data?.payload?.userDetails?.user_company_information !== null
            ) {
              setUserDeatil(
                res?.data?.payload?.userDetails?.user_company_information
              );
            } else {
              setUserDeatil(
                res?.data?.payload?.userDetails?.user_selected_company_information
                  ?.user_company_information
              );
            }
            setCurrentPlan(res?.data?.payload?.userDetails?.currentPlan);
          },
        })
      );
    }
    else{
      setUserDeatil(comapanyData?.userDetails?.user_company_information == null ? comapanyData?.userDetails?.user_selected_company_information
        ?.user_company_information : comapanyData?.userDetails?.user_company_information );
        setCurrentPlan(comapanyData?.userDetails?.currentPlan);
    }


  };

  const handleChangePage = (flag, id) => {
    setExpanded(false);
    if (flag == "edit") {
      navigate("/settings/edit-profile", {
        state: { activeTab: "editProfile" },
      });
      localStorage.setItem("activeTab", "account");
    } else if (flag == "plan") {
      navigate("/settings/manage-subscription", {
        state: { activeTab: "subscription" },
      });
      localStorage.setItem("activeTab", "subscription");
    } else if (flag == "allOpportunities") {
      navigate("/create-opportunities/review-responses", {
        state: { activeTab: "reviewapplication" },
      });
      localStorage.setItem("selectedOpportunity", "reviewapplication");
    } else if (flag == "setting") {
      navigate("/settings", { state: { activeTab: "privacy" } });
      localStorage.setItem("activeTab", "privacy");
    } else if (flag == "viewResponse") {
      navigate("/create-opportunities/view-response", {
        state: { activeTab: "ReviewRespondents", oppId: id },
      });
      localStorage.setItem("selectedOpportunity", "ReviewRespondents");
    } else if (flag === "document-view") {
      navigate("/document-view");
      localStorage.setItem("selectedOpportunity", "addPostOpportunity");
      localStorage.setItem("activeTab", "privacy");
      setSearchInput("");
    } else {
      navigate("/create-opportunities", {
        state: { activeTab: "activeOpportunity" },
      });
      localStorage.setItem("activeTabSetting", "activeOpportunity");
    }
  };

  useEffect(() => {
    if (!userId) {
    } else {
      getAllUserWithSearch();
    }
  }, [searchInput]);

  useEffect(() => {
    if (token) {
      companyProfileInfo();
    }
  }, []);

  // useEffect(() => {
  //   /**
  //    * Alert if clicked on outside of element
  //    */
  //   function handleClickOutside(event) {
  //     if (
  //       inputCloseRef.current &&
  //       !inputCloseRef.current.contains(event.target)
  //     ) {
  //       setSearchInput("");
  //       setSearchInputToggle(false);
  //     }
  //   }
  //   // Bind the event listener
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [inputCloseRef]);

  return (
    <>
      <div className="authNavBar">
        <nav className="navbarContain navInnerhaed">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-6 col-sm-3 col-md-4 col-lg-2 text-center">
                <Link to="/" className="navbar-brand">
                  <FadeInImage
                    src={Images.logoWhite}
                    alt="Logo"
                    className="img-fluid"
                  />
                </Link>
              </div>

              <div className="col-6 col-sm-9 col-md-8 col-lg-10 text-end">
                {(token && step) || socialStep ? (
                  // {/* {(token && step ? step : true)  ? ( */}
                  <Navbar expanded={expanded} expand="lg">
                    <div
                      className={`rightNav_ ${
                        path == "/resources" ? "" : "searchNav"
                      }`}
                    >
                      {path == "/resources" ? (
                        ""
                      ) : (
                        <div className="" ref={inputCloseRef}>
                          <div className="searchBox authNavSearchBox">
                            <form
                              className="inputSearchForm w-auto"
                              onSubmit={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <div className="input-group me-2">
                                {checkAccount === 1 ? (
                                  <input
                                    type="text"
                                    className="form-control rounded"
                                    disabled
                                    placeholder="Profile Search"
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="form-control rounded"
                                    onChange={(e) =>
                                      onChangeSearchInput(e.target.value)
                                    }
                                    placeholder="Profile Search"
                                    value={searchInput}
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                  />
                                )}

                                <button
                                  className={`inputClose ${
                                    searchInput ? "d-block" : ""
                                  }`}
                                  onClick={() => {
                                    onChangeReset();
                                  }}
                                  type="reset"
                                >
                                  {" "}
                                  <i className="las la-times"></i>{" "}
                                </button>
                              </div>
                              <button
                                className="searchBtn"
                                type="button"
                                onClick={() => {
                                  return searchInput
                                    ? getAllUserWithSearch()
                                    : "";
                                }}
                              >
                                <i className="las la-search"></i>
                              </button>
                            </form>
                          </div>
                          {searchInputToggle && allUser?.length > 0 ? (
                            <div
                              className={`searchBoxUsers ${
                                searchInput ? "active" : ""
                              }`}
                            >
                              <>
                                {allUser?.map((user, index) => {
                                  const companyInformation =
                                    user?.is_invited === 2
                                      ? user?.user_selected_company_information
                                          ?.user_company_information
                                      : user?.user_company_information;

                                  return (
                                    <>
                                      <div
                                        onClick={() => handlePageRedirect(user)}
                                        className={`searchBoxuser ${
                                          addClass == "searchboxactive"
                                            ? "searchboxactive"
                                            : ""
                                        }`}
                                      >
                                        <figure>
                                          <FadeInImage
                                            src={
                                              user?.user_profile
                                                ?.profile_photo ??
                                              Images.puzzleProfile
                                            }
                                            alt="Bell"
                                            className="searchCompany"
                                          />
                                        </figure>
                                        <artical className="">
                                          <h6 className="m-0">
                                            {user?.user_profile?.first_name}{" "}
                                            {user?.user_profile?.last_name|| ""}
                                          </h6>
                                          <p className="lightgreyHead_">
                                            {companyInformation?.company_name}
                                          </p>
                                        </artical>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      <div className="flexBox">
                        <div className="navheadInner">
                          <Nav className="">
                            {checkAccount === 1 ? (
                              <Link
                                className={`nav-link ${
                                  path == "/dashboard" ? "active" : ""
                                }`}
                              >
                                Dashboard
                              </Link>
                            ) : (
                              <Link
                                to="/dashboard"
                                className={`nav-link ${
                                  path == "/dashboard" ? "active" : ""
                                }`}
                                onClick={() => {
                                  setExpanded(false);
                                  localStorage.setItem(
                                    "selectedOpportunity",
                                    "addPostOpportunity"
                                  );
                                  localStorage.setItem("activeTab", "privacy");
                                  setSearchInput("");
                                }}
                              >
                                Dashboard
                              </Link>
                            )}
                            {checkAccount == 1 ? (
                              <Link
                                className={`nav-link ${
                                  path == "/suppliers-search" ? "active" : ""
                                }`}
                              >
                                Search
                              </Link>
                            ) : (
                              <Link
                                to="/suppliers-search"
                                className={`nav-link ${
                                  path == "/suppliers-search" ? "active" : ""
                                }`}
                                onClick={() => {
                                  setExpanded(false);

                                  localStorage.setItem(
                                    "selectedOpportunity",
                                    "addPostOpportunity"
                                  );
                                  localStorage.setItem("activeTab", "privacy");
                                  setSearchInput("");
                                }}
                              >
                                Search
                              </Link>
                            )}
                            {checkAccount === 1 ? (
                              <Link
                                className={`nav-link ${
                                  path == "/all-connections" ? "active" : ""
                                }`}
                              >
                                Connections
                              </Link>
                            ) : (
                              <Link
                                to="/all-connections"
                                className={`nav-link ${
                                  path == "/all-connections" ? "active" : ""
                                }`}
                                onClick={() => {
                                  setExpanded(false);

                                  localStorage.setItem(
                                    "selectedOpportunity",
                                    "addPostOpportunity"
                                  );
                                  localStorage.setItem("activeTab", "privacy");
                                  setSearchInput("");
                                }}
                              >
                                Connections
                              </Link>
                            )}

                            {checkAccount === 1 ? (
                              <Link
                                className={`nav-link ${
                                  path == "/resources" ? "active" : ""
                                }`}
                              >
                                Resources
                              </Link>
                            ) : (
                              <Link
                                to="/resources"
                                className={`nav-link ${
                                  path == "/resources" ? "active" : ""
                                }`}
                                onClick={() => {
                                  setExpanded(false);

                                  localStorage.setItem(
                                    "selectedOpportunity",
                                    "addPostOpportunity"
                                  );
                                  localStorage.setItem("activeTab", "privacy");
                                  setSearchInput("");
                                }}
                              >
                                Resources
                              </Link>
                            )}

                            {checkAccount === 1 ? (
                              <Link
                                className={`nav-link ${
                                  path == "/create-opportunities"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Opportunities/Queries
                              </Link>
                            ) : (
                              <Link
                                to="/create-opportunities"
                                className={`nav-link ${
                                  path == "/create-opportunities"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  setExpanded(false);

                                  localStorage.setItem(
                                    "selectedOpportunity",
                                    "addPostOpportunity"
                                  );
                                  localStorage.setItem("activeTab", "privacy");
                                  setSearchInput("");
                                }}
                              >
                                Opportunities/Queries
                              </Link>
                            )}
                          </Nav>
                        </div>
                        <ul className="authMenus d-flex align-items-center ms-3">
                          <li className="authItems">
                            {checkAccount === 1 ? (
                              <Link
                                className={`authLinks ${
                                  path == "/account-message" ? "active" : ""
                                }`}
                              >
                                <FadeInImage
                                  src={Images.msgIcon_}
                                  alt="Envelope"
                                  className="authIcons"
                                />
                              </Link>
                            ) : (
                              <Link
                                to="/account-message"
                                className={`authLinks ${
                                  path == "/account-message" ? "active" : ""
                                }`}
                                onClick={() => {
                                  setExpanded(false);

                                  localStorage.setItem(
                                    "selectedOpportunity",
                                    "addPostOpportunity"
                                  );
                                  localStorage.setItem("activeTab", "privacy");
                                  setSearchInput("");
                                }}
                              >
                                <FadeInImage
                                  src={Images.msgIcon_}
                                  alt="Envelope"
                                  className="authIcons"
                                />
                              </Link>
                            )}
                          </li>
                          <li className="authItems">
                            {checkAccount === 1 ? (
                              <Link
                                className={`authLinks  notification ${
                                  path == "/notification" ? "active" : ""
                                }`}
                              >
                                <FadeInImage
                                  src={Images.notificationIcon_}
                                  alt="Bell"
                                  className="authIcons"
                                />
                              </Link>
                            ) : (
                              <Link
                                to="/notification"
                                className={`authLinks  notification ${
                                  path == "/notification" ? "active" : ""
                                }`}
                                onClick={() => {
                                  setExpanded(false);
                                  localStorage.setItem(
                                    "selectedOpportunity",
                                    "addPostOpportunity"
                                  );
                                  localStorage.setItem("activeTab", "privacy");
                                  setSearchInput("");
                                }}
                              >
                                <FadeInImage
                                  src={Images.notificationIcon_}
                                  alt="Bell"
                                  className="authIcons"
                                />
                              </Link>
                            )}
                          </li>
                          <li className="authItems userDetails">
                            {checkAccount === 1 ? (
                              <Link className="d-flex align-items-center">
                                <FadeInImage
                                  src={
                                    authSelector?.userInfo?.user?.user_profile
                                      ?.profile_photo
                                      ? authSelector?.userInfo?.user
                                          ?.user_profile?.profile_photo
                                      : Images.puzzleProfile
                                  }
                                  alt="User"
                                  className="userImg me-lg-2 me-0"
                                />
                                <span className="userName me-3 d-none d-md-inline">
                                  {
                                    authSelector?.userInfo?.user?.user_profile
                                      ?.first_name
                                  }{" "}
                                  {authSelector?.userInfo?.user?.user_profile
                                    ?.last_name
                                    ? authSelector?.userInfo?.user?.user_profile
                                        ?.last_name
                                    : ""}
                                </span>
                              </Link>
                            ) : (
                              <button
                                // to={
                                //   isInvited == 2 ? authSelector?.companyProfileInfo?.userDetails?.user_selected_company_information?.user_company_information?.company_name:
                                //   `/${authSelector?.userInfo?.user
                                //   ?.user_company_information?.company_name
                                //   ? authSelector?.userInfo?.user?.user_company_information?.company_name?.replace(
                                //     /\s+/g,
                                //     "-"
                                //   )
                                //   : authSelector?.userStep?.user_company_information?.company_name?.replace(
                                //     /\s+/g,
                                //     "-"
                                //   )
                                //   }`}
                                // to={"/user-profile"}
                                onClick={()=>{
                                  UserProfileNavigation(authSelector?.userInfo?.user?.business_type == 2 ? loggedInCompany?.company_name : (authSelector?.userInfo?.user?.user_profile
                                  ?.first_name+"-"+authSelector?.userInfo?.user?.user_profile
                                  ?.last_name),authSelector?.userInfo?.user?.user_profile?.id )}}
                                className="d-flex align-items-center"
                              >
                                <FadeInImage
                                  src={
                                    authSelector?.userInfo?.user?.user_profile
                                      ?.profile_photo
                                      ? authSelector?.userInfo?.user
                                          ?.user_profile?.profile_photo
                                      : Images.puzzleProfile
                                  }
                                  alt="User"
                                  className="userImg me-lg-2 me-0"
                                />
                                <span className="userName me-3 d-none d-md-inline">
                                  {
                                    authSelector?.userInfo?.user?.user_profile
                                      ?.first_name
                                  }{" "}
                                  {authSelector?.userInfo?.user?.user_profile
                                    ?.last_name
                                    ? authSelector?.userInfo?.user?.user_profile
                                        ?.last_name
                                    : ""}
                                </span>
                              </button>
                            )}
                          </li>
                          <li className="authItems">
                            <Link
                              to="/settings/overview"
                              className={`authLinks ${
                                path == "/settings/overview" ? "active" : ""
                              }`}
                              onClick={() => {
                                localStorage.setItem(
                                  "selectedOpportunity",
                                  "addPostOpportunity"
                                );
                                localStorage.setItem("activeTab", "privacy");
                                setSearchInput("");
                              }}
                            >
                              <FadeInImage
                                src={Images.setting}
                                alt="Setting"
                                className="authIcons"
                              />
                            </Link>
                          </li>
                        </ul>
                        <Navbar.Toggle
                          aria-controls="basic-navbar-nav"
                          className="border-0"
                          collapseOnSelect
                          onClick={() => setExpanded("expanded")}
                        >
                          <i className="las la-bars barIcon"></i>
                        </Navbar.Toggle>
                      </div>
                      <div className="flexBox mobileMenu d-none">
                        <div className="navheadInner">
                          <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="">
                              <div
                                onClick={() => {
                                  document.body.classList.remove("newClass");
                                }}
                                className="scrollnav"
                              >
                                <div className="mobileMenu_ d-none">
                                  <div className="profileBox_ ">
                                    <h3 className="lighthead_">
                                      {
                                        authSelector?.userInfo?.user
                                          ?.user_profile?.first_name
                                      }{" "}
                                      {
                                        authSelector?.userInfo?.user
                                          ?.user_profile?.last_name
                                      }
                                    </h3>
                                    <h4 className="lighthead_">
                                      <strong>
                                        {
                                          authSelector?.companyProfileInfo
                                            ?.userDetails
                                            ?.user_company_information
                                            ?.company_name
                                        }
                                      </strong>
                                    </h4>
                                    <Link
                                      to={
                                        isInvited == 2
                                          ? authSelector?.companyProfileInfo
                                              ?.userDetails
                                              ?.user_selected_company_information
                                              ?.user_company_information
                                              ?.company_name
                                          : `/${
                                              authSelector?.userInfo?.user
                                                ?.user_company_information
                                                ?.company_name
                                                ? authSelector?.userInfo?.user?.user_company_information?.company_name?.replace(
                                                    /\s+/g,
                                                    "-"
                                                  )
                                                : authSelector?.userStep?.user_company_information?.company_name?.replace(
                                                    /\s+/g,
                                                    "-"
                                                  )
                                            }`
                                      }
                                      onClick={() => {
                                        setExpanded(false);
                                      }}
                                      className="secondaryBtnOuter"
                                    >
                                      View Profile
                                    </Link>
                                  </div>

                                  {checkAccount == 1 ? (
                                    <Link
                                      className={`nav-link ${
                                        path == "/suppliers-search"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      Search
                                    </Link>
                                  ) : (
                                    <Link
                                      to="/suppliers-search"
                                      className={`nav-link ${
                                        path == "/suppliers-search"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setExpanded(false);
                                        localStorage.setItem(
                                          "selectedOpportunity",
                                          "addPostOpportunity"
                                        );
                                        localStorage.setItem(
                                          "activeTab",
                                          "privacy"
                                        );
                                        setSearchInput("");
                                      }}
                                    >
                                      Search
                                    </Link>
                                  )}
                                  {checkAccount == 1 ? (
                                    <Link
                                      className={`nav-link ${
                                        path == "/create-opportunities"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      Opportunities/Queries
                                    </Link>
                                  ) : (
                                    <Link
                                      to="/create-opportunities"
                                      className={`nav-link ${
                                        path == "/create-opportunities"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setExpanded(false);
                                        localStorage.setItem(
                                          "selectedOpportunity",
                                          "addPostOpportunity"
                                        );
                                        localStorage.setItem(
                                          "activeTab",
                                          "privacy"
                                        );
                                        setSearchInput("");
                                      }}
                                    >
                                      Opportunities/Queries
                                    </Link>
                                  )}
                                  {checkAccount === 1 ? (
                                    <Link
                                      className={`nav-link ${
                                        path == "/all-connections"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      Connections
                                    </Link>
                                  ) : (
                                    <Link
                                      to="/all-connections"
                                      className={`nav-link ${
                                        path == "/all-connections"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setExpanded(false);
                                        localStorage.setItem(
                                          "selectedOpportunity",
                                          "addPostOpportunity"
                                        );
                                        localStorage.setItem(
                                          "activeTab",
                                          "privacy"
                                        );
                                        setSearchInput("");
                                      }}
                                    >
                                      Connections
                                    </Link>
                                  )}

                                  {checkAccount === 1 ? (
                                    <Link
                                      className={`nav-link ${
                                        path == "/resources" ? "active" : ""
                                      }`}
                                    >
                                      Resources
                                    </Link>
                                  ) : (
                                    <Link
                                      to="/resources"
                                      className={`nav-link ${
                                        path == "/resources" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "selectedOpportunity",
                                          "addPostOpportunity"
                                        );
                                        localStorage.setItem(
                                          "activeTab",
                                          "privacy"
                                        );
                                        setSearchInput("");
                                      }}
                                    >
                                      Resources
                                    </Link>
                                  )}
                                  {checkAccount === 1 ? (
                                    <Link
                                      className={`nav-link ${
                                        path == "/certificate" ? "active" : ""
                                      }`}
                                    >
                                      Certifications
                                    </Link>
                                  ) : (
                                    <Link
                                      to="/certificate"
                                      className={`nav-link ${
                                        path == "/certificate" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        setExpanded(false);

                                        localStorage.setItem(
                                          "selectedOpportunity",
                                          "addPostOpportunity"
                                        );
                                        localStorage.setItem(
                                          "activeTab",
                                          "privacy"
                                        );
                                        setSearchInput("");
                                      }}
                                    >
                                      Certifications
                                    </Link>
                                  )}
                                  {checkAccount === 1 ? (
                                    <Link
                                      className={`nav-link ${
                                        path == "/account-message"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      Messages
                                    </Link>
                                  ) : (
                                    <Link
                                      to="/account-message"
                                      className={`nav-link ${
                                        path == "/account-message"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "selectedOpportunity",
                                          "addPostOpportunity"
                                        );
                                        localStorage.setItem(
                                          "activeTab",
                                          "privacy"
                                        );
                                        setSearchInput("");
                                        setExpanded(false);
                                      }}
                                    >
                                      Messages
                                    </Link>
                                  )}
                                  {checkAccount === 1 ? (
                                    <Link
                                      className={`nav-link ${
                                        path == "/dashboard" ? "active" : ""
                                      }`}
                                    >
                                      Dashboard
                                    </Link>
                                  ) : (
                                    <Link
                                      to="/dashboard"
                                      className={`nav-link ${
                                        path == "/dashboard" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "selectedOpportunity",
                                          "addPostOpportunity"
                                        );
                                        localStorage.setItem(
                                          "activeTab",
                                          "privacy"
                                        );
                                        setExpanded(false);

                                        setSearchInput("");
                                      }}
                                    >
                                      Dashboard
                                    </Link>
                                  )}
                                </div>
                                <div className="bottomMenu_">
                                  {checkAccount === 1 ? (
                                    <button
                                      className={`nav-link ${
                                        path == "/document-view" ? "active" : ""
                                      }`}
                                    >
                                      Documents
                                    </button>
                                  ) : (
                                    <button
                                      className={`nav-link ${
                                        path == "/document-view" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        handleChangePage("document-view");
                                        localStorage.setItem(
                                          "selectedOpportunity",
                                          "addPostOpportunity"
                                        );
                                        localStorage.setItem(
                                          "activeTab",
                                          "privacy"
                                        );
                                        setSearchInput("");
                                      }}
                                    >
                                      Documents
                                    </button>
                                  )}
                                  {checkAccount === 1 ? (
                                    // <Link
                                    //     className={`nav-link ${path == '/document-view' ? 'active' : ''}`}
                                    // >Documents</Link>
                                    <button
                                      onClick={() => {
                                        handleChangePage("edit");
                                      }}
                                      className={`nav-link ${
                                        path == "/settings/edit-profile"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      Edit Profile
                                    </button>
                                  ) : (
                                    // <Link to="/document-view"
                                    //     className={`nav-link ${path == '/document-view' ? 'active' : ''}`}
                                    //     onClick={() => {
                                    //         localStorage.setItem("selectedOpportunity", "addPostOpportunity")
                                    //         localStorage.setItem("activeTab", "privacy");
                                    //         setSearchInput("")
                                    //     }}
                                    // >Documents</Link>
                                    <button
                                      onClick={() => {
                                        handleChangePage("edit");
                                      }}
                                      className={`nav-link ${
                                        path == "/settings/edit-profile"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      Edit Profile
                                    </button>
                                  )}
                                  {checkAccount === 1 ? (
                                    // <Link
                                    //     className={`nav-link ${path == '/document-view' ? 'active' : ''}`}
                                    // >Documents</Link>
                                    <button
                                      onClick={() => {
                                        handleChangePage("plan");
                                      }}
                                      className={`nav-link ${
                                        path == "/settings/manage-subscription"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      Manage Membership
                                    </button>
                                  ) : (
                                    // <Link to="/document-view"
                                    //     className={`nav-link ${path == '/document-view' ? 'active' : ''}`}
                                    //     onClick={() => {
                                    //         localStorage.setItem("selectedOpportunity", "addPostOpportunity")
                                    //         localStorage.setItem("activeTab", "privacy");
                                    //         setSearchInput("")
                                    //     }}
                                    // >Documents</Link>
                                    <button
                                      onClick={() => {
                                        handleChangePage("plan");
                                      }}
                                      className={`nav-link ${
                                        path == "/settings/manage-subscription"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      Manage Membership
                                    </button>
                                  )}
                                  {checkAccount === 1 ? (
                                    // <Link
                                    //     className={`nav-link ${path == '/document-view' ? 'active' : ''}`}
                                    // >Documents</Link>
                                    <button
                                      onClick={() => {
                                        handleChangePage("setting");
                                      }}
                                      className={`nav-link ${
                                        path == "/settings" ? "active" : ""
                                      }`}
                                    >
                                      Privacy & Settings
                                    </button>
                                  ) : (
                                    // <Link to="/document-view"
                                    //     className={`nav-link ${path == '/document-view' ? 'active' : ''}`}
                                    //     onClick={() => {
                                    //         localStorage.setItem("selectedOpportunity", "addPostOpportunity")
                                    //         localStorage.setItem("activeTab", "privacy");
                                    //         setSearchInput("")
                                    //     }}
                                    // >Documents</Link>
                                    <button
                                      onClick={() => {
                                        handleChangePage("setting");
                                      }}
                                      className={`nav-link ${
                                        path == "/settings" ? "active" : ""
                                      }`}
                                    >
                                      Privacy & Settings
                                    </button>
                                  )}
                                  {checkAccount === 1 ? (
                                    // <Link
                                    //     className={`nav-link ${path == '/document-view' ? 'active' : ''}`}
                                    // >Documents</Link>
                                    <button
                                      onClick={() => {
                                        userLogout();
                                      }}
                                      className={`nav-link`}
                                    >
                                      Logout
                                    </button>
                                  ) : (
                                    // <Link to="/document-view"
                                    //     className={`nav-link ${path == '/document-view' ? 'active' : ''}`}
                                    //     onClick={() => {
                                    //         localStorage.setItem("selectedOpportunity", "addPostOpportunity")
                                    //         localStorage.setItem("activeTab", "privacy");
                                    //         setSearchInput("")
                                    //     }}
                                    // >Documents</Link>
                                    <button
                                      onClick={() => {
                                        userLogout();
                                      }}
                                      className={`nav-link `}
                                    >
                                      Logout
                                    </button>
                                  )}
                                  {path == "/resources" ? (
                                    ""
                                  ) : (
                                    <div className="" ref={inputCloseRef}>
                                      <div className="searchBox mt-5">
                                        <form
                                          className="inputSearchForm"
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <div className="input-group me-2">
                                            {checkAccount === 1 ? (
                                              <input
                                                type="text"
                                                className="form-control rounded"
                                                disabled
                                                placeholder="Profile Search"
                                                aria-label="Search"
                                                aria-describedby="search-addon"
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className="form-control rounded"
                                                onChange={(e) =>
                                                  onChangeSearchInput(
                                                    e.target.value
                                                  )
                                                }
                                                placeholder="Profile Search"
                                                value={searchInput}
                                                aria-label="Search"
                                                aria-describedby="search-addon"
                                              />
                                            )}

                                            <button
                                              className={`inputClose ${
                                                searchInput ? "d-block" : ""
                                              }`}
                                              onClick={() => {
                                                onChangeReset();
                                              }}
                                              type="reset"
                                            >
                                              {" "}
                                              <i className="las la-times"></i>{" "}
                                            </button>
                                          </div>
                                          <button
                                            className="searchBtn"
                                            type="button"
                                            onClick={() => {
                                              return searchInput
                                                ? getAllUserWithSearch()
                                                : "";
                                            }}
                                          >
                                            <i className="las la-search"></i>
                                          </button>
                                        </form>
                                      </div>
                                      {searchInputToggle &&
                                      allUser?.length > 0 ? (
                                        <div
                                          className={`searchBoxUsers ${
                                            searchInput ? "active" : ""
                                          }`}
                                        >
                                          <>
                                            {allUser?.map((user, index) => {
                                              const companyInformation =
                                                user?.is_invited === 2
                                                  ? user
                                                      ?.user_selected_company_information
                                                      ?.user_company_information
                                                  : user?.user_company_information;
                                              return (
                                                <>
                                                  <div
                                                    onClick={() =>
                                                      handlePageRedirect(user)
                                                    }
                                                    className={`searchBoxuser ${
                                                      addClass ==
                                                      "searchboxactive"
                                                        ? "searchboxactive"
                                                        : ""
                                                    }`}
                                                  >
                                                    <figure>
                                                      <FadeInImage
                                                        src={
                                                          user?.user_profile
                                                            ?.profile_photo ??
                                                          Images.puzzleProfile
                                                        }
                                                        alt="Bell"
                                                        className="searchCompany"
                                                      />
                                                    </figure>
                                                    <artical className="">
                                                      <h6 className="m-0">
                                                        {
                                                          user?.user_profile
                                                            ?.first_name
                                                        }
                                                      </h6>
                                                      <p className="lightgreyHead_">
                                                        {
                                                          companyInformation?.company_name
                                                        }
                                                      </p>
                                                    </artical>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          </>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Nav>
                          </Navbar.Collapse>
                        </div>
                        <ul className="authMenus d-flex align-items-center ms-3">
                          <li className="authItems">
                            {checkAccount === 1 ? (
                              <Link
                                className={`authLinks ${
                                  path == "/account-message" ? "active" : ""
                                }`}
                              >
                                <FadeInImage
                                  src={Images.msgIcon_}
                                  alt="Envelope"
                                  className="authIcons"
                                />
                              </Link>
                            ) : (
                              <Link
                                to="/account-message"
                                className={`authLinks ${
                                  path == "/account-message" ? "active" : ""
                                }`}
                                onClick={() => {
                                  localStorage.setItem(
                                    "selectedOpportunity",
                                    "addPostOpportunity"
                                  );
                                  localStorage.setItem("activeTab", "privacy");
                                  setSearchInput("");
                                }}
                              >
                                <FadeInImage
                                  src={Images.msgIcon_}
                                  alt="Envelope"
                                  className="authIcons"
                                />
                              </Link>
                            )}
                          </li>
                          <li className="authItems">
                            {checkAccount === 1 ? (
                              <Link
                                className={`authLinks  notification ${
                                  path == "/notification" ? "active" : ""
                                }`}
                              >
                                <FadeInImage
                                  src={Images.notificationIcon_}
                                  alt="Bell"
                                  className="authIcons"
                                />
                              </Link>
                            ) : (
                              <Link
                                to="/notification"
                                className={`authLinks  notification ${
                                  path == "/notification" ? "active" : ""
                                }`}
                                onClick={() => {
                                  localStorage.setItem(
                                    "selectedOpportunity",
                                    "addPostOpportunity"
                                  );
                                  localStorage.setItem("activeTab", "privacy");
                                  setSearchInput("");
                                }}
                              >
                                <FadeInImage
                                  src={Images.notificationIcon_}
                                  alt="Bell"
                                  className="authIcons"
                                />
                              </Link>
                            )}
                          </li>
                          <li
                            className={
                              window.innerWidth > 991
                                ? "authItems userDetails"
                                : "d-none"
                            }
                          >
                            {checkAccount === 1 ? (
                              <Link className="d-flex align-items-center">
                                <FadeInImage
                                  src={
                                    authSelector?.userInfo?.user?.user_profile
                                      ?.profile_photo
                                      ? authSelector?.userInfo?.user
                                          ?.user_profile?.profile_photo
                                      : Images.puzzleProfile
                                  }
                                  alt="User"
                                  className="userImg me-lg-2 me-0"
                                />
                                <span className="userName me-3 d-none d-md-inline">
                                  {
                                    authSelector?.userInfo?.user?.user_profile
                                      ?.first_name
                                  }{" "}
                                  {authSelector?.userInfo?.user?.user_profile
                                    ?.last_name
                                    ? authSelector?.userInfo?.user?.user_profile
                                        ?.last_name
                                    : ""}
                                </span>
                              </Link>
                            ) : (
                              <Link
                                to={
                                  isInvited == 2
                                    ? authSelector?.companyProfileInfo
                                        ?.userDetails
                                        ?.user_selected_company_information
                                        ?.user_company_information?.company_name
                                    : `/${
                                        authSelector?.userInfo?.user
                                          ?.user_company_information
                                          ?.company_name
                                          ? authSelector?.userInfo?.user?.user_company_information?.company_name?.replace(
                                              /\s+/g,
                                              "-"
                                            )
                                          : authSelector?.userStep?.user_company_information?.company_name?.replace(
                                              /\s+/g,
                                              "-"
                                            )
                                      }`
                                }
                                className="d-flex align-items-center"
                              >

                                <FadeInImage
                                  src={
                                    authSelector?.userInfo?.user?.user_profile
                                      ?.profile_photo
                                      ? authSelector?.userInfo?.user
                                          ?.user_profile?.profile_photo
                                      : Images.puzzleProfile
                                  }
                                  alt="User"
                                  className="userImg me-lg-2 me-0"
                                />
                                <span className="userName me-3 d-none d-md-inline">
                                  {
                                    authSelector?.userInfo?.user?.user_profile
                                      ?.first_name
                                  }{" "}
                                  {authSelector?.userInfo?.user?.user_profile
                                    ?.last_name
                                    ? authSelector?.userInfo?.user?.user_profile
                                        ?.last_name
                                    : ""}
                                </span>
                              </Link>
                            )}
                          </li>
                          <li
                            className={
                              window.innerWidth > 991 ? "authItems" : "d-none"
                            }
                          >
                            <Link
                              to="/settings/overview"
                              className={`authLinks ${
                                path == "/settings/overview" ? "active" : ""
                              }`}
                              onClick={() => {
                                localStorage.setItem(
                                  "selectedOpportunity",
                                  "addPostOpportunity"
                                );
                                localStorage.setItem("activeTab", "privacy");
                                setSearchInput("");
                              }}
                            >
                              <FadeInImage
                                src={Images.setting}
                                alt="Setting"
                                className="authIcons"
                              />
                            </Link>
                          </li>
                        </ul>
                        <Navbar.Toggle
                          aria-controls="basic-navbar-nav"
                          className="border-0"
                          collapseOnSelect
                          onClick={
                            () => {
                              setExpanded((prev) => {
                                if (prev === "expanded") {
                                  return false;
                                } else {
                                  return "expanded";
                                }
                              });
                              document.body.classList.add("newClass");
                            }
                            // setAddClassBody(prevState => !prevState)}
                          }
                        >
                          <i className="las la-bars barIcon"></i>
                        </Navbar.Toggle>
                      </div>
                    </div>
                  </Navbar>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag ? "commonWidth customContent" : ""}
        ids={modalDetail.flag}
        child={
          modalDetail.flag === "paymentUpdate" ? (
            <UpdatePayment close={() => handleOnCloseModal()} />
          ) : null
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default AuthNav;
