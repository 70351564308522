import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
  
  try {
    const SignalId = process.env.REACT_APP_ONE_SIGNAL
    // const SignalId = "17b97d09-f936-4a9e-aa9d-5c1f1ff2ac82"

    // itechnolabs server  one signal sdk
    await OneSignal.init({ appId: SignalId, allowLocalhostAsSecureOrigin: true });
    // client server one signal sdk
    // await OneSignal.init({ appId: '4a712d3a-7a8b-462f-93a8-f431d97a7a90', allowLocalhostAsSecureOrigin: true });

    OneSignal.Slidedown.promptPush();
  } catch (error) {
    console.error("OneSignal initialization error:", error);
  }
}
// const RunOneSignal = ({ children }) => {

 
//   // OneSignal.Slidedown.promptPush();
// }

// export default RunOneSignal;