import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import {setMyDocuments,setDeleteDocument,setUploadAllDocuments,onErrorStopLoad } from '../../slices/documents';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';


function* getMyDocuments(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.DocumentApiPath.GET_MY_DOCUMENTS}?page=${action.payload.page}&limit=${action.payload.limit}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setMyDocuments(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setMyDocuments({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* DocumentDelete(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.DocumentApiPath.DELETE_DOCUMENTS}${action.payload.id}`);
    if (resp.status) {
      yield put(setDeleteDocument(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setDeleteDocument({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* UploadMyDocuments(action) {
  try {
    const resp = yield call(ApiClient.postFormData, action.url = ApiPath.DocumentApiPath.UPLOAD_DOCUMENTS, action.payload = action.payload);
    if (resp.status) {
      yield put(setUploadAllDocuments(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUploadAllDocuments({}));
    yield put(onErrorStopLoad())
    // toast.error(e?.response?.data?.msg);
  }
}


function* userDocuments() {
  yield all([
    takeLatest('documents/myDocuments', getMyDocuments),
    takeLatest('documents/deleteDocument', DocumentDelete),
    takeLatest('documents/uploadAllDocuments', UploadMyDocuments),
  ])
}

export default userDocuments;