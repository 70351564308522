import React, { useState } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image';
import { useNavigate } from 'react-router-dom';
import * as Images from "../../../utilities/images";


const UpdatePayment = (props) => {

    const navigate = useNavigate()

    const handleRedirect = (e) => {
        e.preventDefault()
        navigate('/settings',{state : {from: "updatePlans"}})
        props?.close()
        // navigate('/settings',{state:{activeTab:'subscription'}})
    }

    return (
        <>
            <form className="registrationForm row mt-3 justify-content-center">
                <div className="membershipRegistration_">
                    <div className='heading text-center'>
                        <h3 className="headingSmall">Update Payment</h3>
                        <p>
                            Your current membership plan has been expired please update your next billing to proceed further.
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center my-3">
                    <div className="col-md-6">
                        <button className="submitopportunity w-75" onClick={(e) => { handleRedirect(e) }}>My Plan</button>
                    </div>
                </div>
            </form>
        </>
    )
}


export default UpdatePayment