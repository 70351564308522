import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import BaseUrl from "../../constants/baseUrl";
import { useAuthSelector } from "../../redux/selector/auth";

const PrivateCheck = ({ auth }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const pathName = location.pathname;
    const authSelector = useAuthSelector();
    const step = authSelector?.userInfo?.user?.step == 6;
    const userstep = authSelector?.userStep?.step == 6;
    const mangeuserstep = authSelector?.userInfo?.user?.user_selected_company_information !== null;
    const checkAccount = authSelector?.userInfo?.user?.is_deactivate === 1
    // const authRoutes = ['/resources'];
    const authRoutes = [''];
    const authToken = localStorage.getItem("authToken") ? true : false;

    //add this authentication after implimenting login 


    return (
        <>
            {
                (authToken && auth) ? <Outlet/> :
                    (authToken && !auth && !step) ? <Navigate to="/registration" /> :
                        (!authToken && !auth) ? <Outlet /> :
                            (authRoutes.includes(pathName)) ? <Outlet /> :
                                checkAccount ? <Navigate to="/settings" /> :
                                    authToken && step ? <Navigate to="/dashboard" /> :

                                        <Navigate to="/" />
            }
            {step && pathName == "/registration" ? navigate("/dashboard") :
                userstep && pathName == "/registration" ? navigate("/dashboard")
                    : ""}
        </>

    )

}


export default PrivateCheck;
