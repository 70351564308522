
import React, { useState } from 'react';


const Payment = () => {

    return (
        <>
            <div className=''>
                <div></div>
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Card Number: </label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Expiry Month:</label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Expiry Year:</label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Security Code:</label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Cardholder Name:</label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div>
                    <button id="payButton" onclick="pay('card');">Pay Now</button>
                </div>
            </div>

        </>

    )
}
export default Payment