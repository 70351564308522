
const BaseUrl = {
    API_URL: process.env.REACT_APP_API_URL,
    SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
    // googleClientId: "389954111772-q4jns6icjpul6bunsb6q3gu27s89fjiq.apps.googleusercontent.com", // dev
    googleClientId: "983795878454-mis9e07h4ios4075dk41fnl8crd4igiq.apps.googleusercontent.com", // live
    // VIDEO_URL: 'https://api.wellopps.itechnolabs.tech:3000'

    VIDEO_URL: process.env.REACT_APP_VIDEO_URL|| "https://du11gq4butx1d.cloudfront.net/source-video",
    
    // API_URL: 'https://godev.getopps.io:4000',
    // SOCKET_URL: 'https://godev.getopps.io:4000'
}

export default BaseUrl;