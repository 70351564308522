import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  cancelcurrentMemberShip:{}
};

export const paymentPlanSlice = createSlice({
  name: "paymentPlan",
  initialState,
  reducers: {
    getPaymentPlanEnterpriseBusiness: (state) => {
        state.loading = true;
      },
      setGetPaymentPlanEnterpriseBusiness: (state, action) => {
        state.loading = false;
      },
      getPaymentPlanSmallBusiness: (state) => {
        state.loading = true;
      },
      setGetPaymentPlanSmallBusiness: (state, action) => {
        state.loading = false;
      },
      cancelcurrentMemberShip: (state, action) => {
        state.loading = true;
      },
      setCancelCurrentMemberShip: (state, action) => {
        state.loading = false;
        state.cancelcurrentMemberShip = action.payload
      },

    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onErrorStopLoad,
    getPaymentPlanEnterpriseBusiness,
    setGetPaymentPlanEnterpriseBusiness,
    getPaymentPlanSmallBusiness,
    setGetPaymentPlanSmallBusiness,
    cancelcurrentMemberShip,
    setCancelCurrentMemberShip

} = paymentPlanSlice.actions;

export default paymentPlanSlice.reducer;
