export const roleKeys = {
    enterprise: 1,
    smallUsers: 2,
    coworker: 1,
    coworkerInvited: 2,
}


export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const updatingInfoKeys = {
    request: "request",
    accept: "accept",
    reject: "reject",
    follow: "follow",
    connection: "connection"
}
export const onlyCharactersRegex = /^[a-zA-Z]+$/;

export const blockInvalidChar = (e) => {
    if (!onlyCharactersRegex.test(e.key)) e.preventDefault()
}

export const getDateComponents = (date = null) => {
    let givenDate;

    if (date) {
        givenDate = new Date(date);
    } else {
        givenDate = new Date();
    }

    const year = givenDate.getUTCFullYear();
    const month = givenDate.getUTCMonth() + 1; // Months are zero-based, so add 1
    const day = givenDate.getUTCDate();
    return {
        year, month, day
    }
}