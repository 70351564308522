import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { onErrorStopLoad, setAllNotificationList } from '../../slices/notification';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* getAllNotification(action) {
  try {
    var dataToSend = { ...action.payload }
    delete dataToSend.cb
    const resp = yield call(ApiClient.get, action.url = ApiPath.NotificationApiPath.GET_ALL_NOTIFICATION, action.params = { params: dataToSend });
    if (resp.status) {
      yield put(setAllNotificationList(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAllNotificationList({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* fetchMoreNotifications(action) {
  try {
    var dataToSend = { ...action.payload }
    delete dataToSend.cb
    const resp = yield call(ApiClient.get, action.url = ApiPath.NotificationApiPath.GET_ALL_NOTIFICATION, action.params = { params: dataToSend });
    if (resp.status) {
      yield put(setAllNotificationList(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAllNotificationList({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* userNotification() {
  yield all([
    takeLatest('notification/allNotificationList', getAllNotification),
    takeLatest('notification/fetchMoreNotifications', fetchMoreNotifications),
  ])
}

export default userNotification;