import React, { useEffect, useRef } from 'react'

const VideoPlayer = ({video}) => {
    const videoRef = useRef(null);

    useEffect(() => {
      let options = {
        rootMargin: "0px",
        threshold: [0.25, 0.75]
      };
  
      let handlePlay = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // videoRef.current.play();
          } else {
            videoRef.current.pause();
          }
        });
      };
  
      let observer = new IntersectionObserver(handlePlay, options);
  
      observer.observe(videoRef.current);
    });
  return (
    <video controls autoPlay height={540}  ref={videoRef}>
    <source
      src={video}
      type="video/mp4"
      className="soundbiteImg"
    />
  </video>
  )
}

export default VideoPlayer