import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  advanceSearchList: {},
  userdetails: {},
  supplierDescription: {},
  supplierCertification: {},
  tradeMemberships: {},
  loading: false,
  updateLoader: false
};

export const advanceSearchSlice = createSlice({
  name: "advanceSearch",
  initialState,
  reducers: {
    getAdvanceSearch: (state) => {
      state.loading = true;
    },
    setGetAdvanceSearch: (state, action) => {
      state.loading = false;
      state.advanceSearchList = action.payload;
    },
    getUserDeatails: (state) => {
      state.loading = true;
    },
    setGetUserDeatails: (state, action) => {
      state.loading = false;
      state.userdetails = action.payload;
    },
    getUpdatedUserDeatails: (state) => {
      state.updateLoader = true
    },
    setGetUpdatedUserDeatails: (state, action) => {
      state.updateLoader = false
      state.userdetails = action.payload;
    },
    diverseSupplierDescription: (state) => {
      state.loading = true;
    },
    setDiverseSupplierDescription: (state, action) => {
      state.loading = false;
      state.supplierDescription = action.payload;
    },
    diverseSupplierCertification: (state) => {
      state.loading = true;
    },
    setDiverseSupplierCertification: (state, action) => {
      state.loading = false;
      state.supplierCertification = action.payload;
    },
    tradeMembership: (state) => {
      state.loading = true;
    },
    setTradeMembership: (state, action) => {
      state.loading = false;
      state.tradeMemberships = action.payload;
    },
    existingCertification: (state) => {
      state.loading = true;
    },
    setExistingCertification: (state) => {
      state.loading = false;
    },
    tradeMemberShip: (state, action) => {
      state.loading = true;
    },
    setTradeMemberShip: (state, action) => {
      state.loading = false;
    },
    getSavedSearches: (state, action) => {
      state.loading = true;
    },
    setSavedSearches: (state, action) => {
      state.loading = false;
    },
    saveSearch: (state, action) => {
      state.loading = true;
    },
    setSaveSearch: (state, action) => {
      state.loading = false;
    },
    deleteSearch: (state, action) => {
      state.loading = true;
    },
    setDeleteSearch: (state, action) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAdvanceSearch,
  setGetAdvanceSearch,
  getUserDeatails,
  setGetUserDeatails,
  diverseSupplierDescription,
  setDiverseSupplierDescription,
  diverseSupplierCertification,
  setDiverseSupplierCertification,
  tradeMembership,
  setTradeMembership,
  existingCertification,
  setExistingCertification,
  tradeMemberShip,
  setTradeMemberShip,
  getSavedSearches,
  setSavedSearches,
  saveSearch,
  setSaveSearch,
  deleteSearch,
  setDeleteSearch,
  onErrorStopLoad,
  getUpdatedUserDeatails,
  setGetUpdatedUserDeatails
} = advanceSearchSlice.actions;

export default advanceSearchSlice.reducer;
