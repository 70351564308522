import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
// Worker saga will be fired on USER_FETCH_REQUESTED actions
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { onErrorStopLoad, setLabels, setAddLabels, setChatHead, setChatInfo, setAllCompanyInfo, setCreateDraft, setGetChatDraft, setChatDelete, setGetTrashChat, setMessageDelete, setTrashChatUpdate, setSentMessage, setDeleteLabels, setAssignLabels, setGetMessageReuests, setGetMessageRequest, setMessageRequest, setUpdateRequest, setUserByType } from '../../slices/messages';


function* getLabel(action) {
  try {
    var dataToSend = { ...action.payload }
    delete dataToSend.cb
    const resp = yield call(ApiClient.get, action.url = ApiPath.MessagePath.GET_LABELS, action.params = { params: dataToSend });
    if (resp.status) {

      yield put(setLabels(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* assignLabel(action) {
  try {

    const resp = yield call(ApiClient.post, action.url = ApiPath.MessagePath.ASSIGN_LABELS, action.payload = action.payload);
    if (resp.status) {

      yield put(setAssignLabels(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* deleteLabel(action) {
  try {

    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.MessagePath.DELETE_LABELS}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setDeleteLabels(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setMessageDelete({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* addLabel(action) {
  try {

    const resp = yield call(ApiClient.post, action.url = ApiPath.MessagePath.ADD_LABELS, action.payload = action.payload);
    if (resp.status) {

      yield put(setAddLabels(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setAddLabels({}));
    yield put(onErrorStopLoad())
    // toast.error(e?.response?.data?.msg);
  }
}

function* ChatHead(action) {
  try {
    var dataToSend = { ...action.payload }
    delete dataToSend.cb
    const resp = yield call(ApiClient.get, action.url = ApiPath.MessagePath.GET_CHAT_HEAD, action.params = { params: dataToSend });
    if (resp.status) {
      yield put(setChatHead(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setChatHead({}));
    yield put(onErrorStopLoad())
    // toast.error(e?.response?.data?.msg);
  }
}

function* ChatInfo(action) {
  try {

    const resp = yield call(ApiClient.get, action.url = `${ApiPath.MessagePath.GET_CONVERSATION}${action.payload.chatId}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setChatInfo(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setChatInfo({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data.msg);
  }
}
function* SentMessae(action) {
  try {
    // var dataToSend = { ...action.payload }
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.MessagePath.SENT_MESSAGES}`, action.params = action.payload);
    if (resp.status) {

      yield put(setSentMessage(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setSentMessage({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data.msg);
  }
}

function* GetAllCompanyInfo(action) {
  try {

    const resp = yield call(ApiClient.get, action.url = `${ApiPath.MessagePath.GET_ALL_COMPANY_INFORMATION}all?search=${action.payload.search}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setAllCompanyInfo(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setAllCompanyInfo({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getUserByType(action) {
  try {
    let search = action.payload.search ? `&search=${action.payload.search}` : ""
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.MessagePath.USER_BY_TYPE}?type=${action.payload.type}${search}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setUserByType(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setAllCompanyInfo({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}
function* createDrafts(action) {
  try {

    const resp = yield call(ApiClient.post, action.url = ApiPath.MessagePath.CREATE_DRAFT, action.payload = action.payload);
    if (resp.status) {

      yield put(setCreateDraft(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setCreateDraft({}));
    yield put(onErrorStopLoad())
    // toast.error(e.response.data.msg);
  }
}

function* getChatDrafts(action) {
  try {

    const resp = yield call(ApiClient.get, action.url = ApiPath.MessagePath.GET_CHAT_DRAFT, action.payload = action.payload);
    if (resp.status) {

      yield put(setGetChatDraft(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setGetChatDraft({}));
    yield put(onErrorStopLoad())
    // toast.error(e.response.data.msg);
  }
}

function* getMessageRequests(action) {
  try {

    const resp = yield call(ApiClient.get, action.url = ApiPath.MessagePath.MESSAGE_REQUESTS, action.payload = action.payload);
    if (resp.status) {
      yield put(setGetMessageRequest(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setGetChatDraft({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}
function* updateRequest(action) {
  try {
    const dataToSend = { ...action.payload }
    delete dataToSend.cb
    delete dataToSend.id

    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.MessagePath.UPDATE_REQUEST}${action.payload.id}`, action.params = dataToSend);
    if (resp.status) {
      yield put(setUpdateRequest(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setGetChatDraft({}));
    yield put(onErrorStopLoad())
    // toast.error(e.response.data.msg);
  }
}

function* messageRequest(action) {
  try {

    const resp = yield call(ApiClient.post, action.url = ApiPath.MessagePath.SENT_REQUESTS, action.payload = action.payload);
    if (resp.status) {

      yield put(setMessageRequest(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setGetChatDraft({}));
    yield put(onErrorStopLoad())
    // toast.error(e.response.data.msg);
  }
}

function* messageDeleted(action) {
  try {

    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.MessagePath.DELETE_MESSAGE}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setMessageDelete(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setMessageDelete({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* chatDeleted(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.MessagePath.DELETE_CHAT}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setChatDelete(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setChatDelete({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getTrashChatS(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.MessagePath.GET_TRASH_CHAT, action.payload = action.payload);
    if (resp.status) {

      yield put(setGetTrashChat(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setGetTrashChat({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* chatTrashUpdate(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.MessagePath.CHAT_TRASH_UPDATE}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setTrashChatUpdate(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setTrashChatUpdate({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* messageSaga() {
  yield all([
    takeLatest('messages/getLabels', getLabel),
    takeLatest('messages/deleteLabels', deleteLabel),
    takeLatest('messages/assignLabels', assignLabel),
    takeLatest('messages/addLabels', addLabel),
    takeLatest('messages/getChatHead', ChatHead),
    takeLatest('messages/getChatInfo', ChatInfo),
    takeLatest('messages/getSentMessage', SentMessae),
    takeLatest('messages/getAllCompanyInfo', GetAllCompanyInfo),
    takeLatest('messages/createDraft', createDrafts),
    takeLatest('messages/getChatDraft', getChatDrafts),
    takeLatest('messages/getMessageRequest', getMessageRequests),
    takeLatest('messages/messageDelete', messageDeleted),
    takeLatest('messages/chatDelete', chatDeleted),
    takeLatest('messages/getTrashChat', getTrashChatS),
    takeLatest('messages/trashChatUpdate', chatTrashUpdate),
    takeLatest('messages/messageRequest', messageRequest),
    takeLatest('messages/updateRequest', updateRequest),
    takeLatest('messages/getUserByType', getUserByType)
  ])
}

export default messageSaga;

