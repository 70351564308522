import React from 'react'
import { useNavigate } from 'react-router-dom'

const BusinessResource = () => {
  const navigate = useNavigate()
  return (
    <div className="lookSuplier mt-4">
      <div className="cardBox">
        <div className="textBox">
          <h6>
            Business <b>Resources</b>
          </h6>
          <p>
            Building, sustaining and growing a business is a
            never-ending journey that involves continuos
            infusions of knowledge and wisdom.
          </p>
          <button
            onClick={() => navigate("/resources")}
            className="primaryBtnSmall"
          >
            Start Learning
          </button>
        </div>
      </div>
    </div>
  )
}

export default BusinessResource