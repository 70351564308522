import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allNotification: {},
    loading: false
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: ({
        allNotificationList: (state) => {
            state.loading = true
        },
        setAllNotificationList: (state, action) => {
            state.loading = false
                        state.allNotification = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        },
        fetchMoreNotifications: (state) => {
        },
    }),
})

// Action creators are generated for each case reducer function
export const {
    allNotificationList,
    setAllNotificationList,
    onErrorStopLoad,
    fetchMoreNotifications
} = notificationSlice.actions

export default notificationSlice.reducer