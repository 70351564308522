import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../../utilities/api';
import { setUpdateArchive,setGetMyArchiveOppertunity,onErrorStopLoad } from '../../../slices/opportunity/archiveOpportunity';
import ApiPath from '../../../../constants/apiPath';
import { toast } from 'react-toastify';


function* getMyArchiveOppertunities(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PostOpportunityApiPath.ArchiveApiPath.GET_MY_ARCHIVE_OPPERTUNITIES}?page=${action.payload.page}&limit=${action.payload.limit}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setGetMyArchiveOppertunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetMyArchiveOppertunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* archiveUpdate(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.PostOpportunityApiPath.ArchiveApiPath.ARCHIVE_OPPERTUNITIES}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setUpdateArchive(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUpdateArchive({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}



//INdustry Query
// function* updateQueryArchive(action) {
//   try {
//     const resp = yield call(ApiClient.patch, action.url = `${ApiPath.PostIndustryQuery.ARCHIVE_OPPERTUNITIES}${action.payload.id}`, action.payload = action.payload);
//     if (resp.status) {
//       yield put(setUpdateArchive(resp.data.payload));
//       yield call(action.payload.cb, action.res = resp)
//       toast.success(action.res.data.msg);
//     }
//     else {
//       throw resp
//     }
//   } catch (e) {
//     yield put(setUpdateArchive({}));
//     yield put(onErrorStopLoad())
//     toast.error(e.response.data.msg);
//   }
// }

function* userArchive() {
  yield all([
    takeLatest("archiveOpportunity/updateArchive", archiveUpdate),
    takeLatest("archiveOpportunity/getMyArchiveOppertunity", getMyArchiveOppertunities),
    // takeLatest("archiveOpportunity/updateQueryArchive", updateQueryArchive),
  ])
}

export default userArchive;