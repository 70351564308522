import './App.css';
import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter, useParams } from "react-router-dom";
import Router from "./routes";
import './public/css/custom.css';
import './public/css/customNew.css';
import './public/css/customNew2.css';
import './public/css/login.css';
import './public/css/responsive.css';
import './public/css/homepage.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-image-crop/dist/ReactCrop.css";
import 'react-phone-input-2/lib/style.css'
import "react-tabs/style/react-tabs.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import store from './redux';
import { persistor } from './redux';
import { PersistGate } from 'redux-persist/integration/react';
import HomeScreenPassword from './app/components/common/homeScreenForm';
import OneSignal from 'react-onesignal';
import NetworkService from "./utilities/api"
import runOneSignal from './utilities/oneSignal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from 'react-ga';
import { HelmetProvider } from 'react-helmet-async';
import SkeltonLoader from './utilities/SkeltonLoader';

NetworkService.setupInterceptors(store);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


function App() {

  useEffect(() => {

    runOneSignal();
  })

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-Z841XJB452');
    // window.location.href("")
    // Send initial pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Suspense fallback={<SkeltonLoader />}>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </Suspense>
            </PersistGate>
            {process.env.REACT_APP_ENVIROMENT === "DEVELOPMENT" ? <HomeScreenPassword /> : <></>}
          </Provider>
          <ToastContainer
            closeButton={false}
            hideProgressBar={true}
            style={{
              marginTop:'60px'
            }}
              />
        </Elements>
      </HelmetProvider>
    </>
  );
}

export default App;
