import React, { useCallback, useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { postUserLikeList } from '../../../redux/slices/dashboard';
import { useDispatch } from 'react-redux';
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router-dom';
import { useUserNavigation, useUserProfileNavigation } from '../../commonFunctions';
import FadeInImage from '../../../utilities/FadeInImage';


const AllPostLikeUser = (props) => {
    const navigate = useNavigate();
    const [allPostLikeUser, setAllPostLikeUser] = useState([]);
    const [pageCount, setPageCount] = useState(1);

    const dispatch = useDispatch();

    // total user like post
    const totalUserLikeList = (page = 1, limit = 10, id) => {
        const params = {
            page: page,
            limit: limit,
            id: props?.id,
        }
        dispatch(postUserLikeList({
            ...params, cb(res) {
                if (res.status) {
                    setAllPostLikeUser(res?.data?.payload)
                    setPageCount(res?.data?.payload?.total_pages)

                }

                else {
                }
            }
        }))

    }

    //on change page pagination
    const handlePageClick = (e) => {
        totalUserLikeList(e.selected + 1);
    };


    const UserProfileNavigation = useUserProfileNavigation()
    const userNavigation = useUserNavigation()
    // handle  page change 
    const handlePageRedirect = (userDetails) => {
        if (userDetails) userNavigation(userDetails)
        props?.close()
    }
 
    useEffect(() => {
        totalUserLikeList();
    }, [])
    return (
        <div>
            {allPostLikeUser?.posts?.length > 0 ?
                <>
                    {allPostLikeUser?.posts?.map((postlike, index) => {
                        return (
                            <div className="approveEndorsement">
                                <FadeInImage onClick={() => handlePageRedirect(postlike?.user_details)} className='img-fluid likedUserImg' src={postlike?.user_details?.user_profile?.profile_photo ? postlike?.user_details?.user_profile?.profile_photo : Images.puzzleProfile} alt='likedUserImg' />
                                <p className='likedPostuser'>{(postlike?.user_details?.user_profile?.first_name ?? "") + " "} {postlike?.user_details?.user_profile?.last_name ?? ""}</p>
                            </div>
                        )
                    })}
                </>
                : "No post Like"
            }

            <div className="col-12 ">
                <div className="customPagination mt-4">
                    <div className="pagination">
                        {allPostLikeUser?.posts?.length > 0 ? (
                            <ReactPaginate

                                breakLabel=""
                                // nextLabel=">"
                                nextLabel={<i className='fas fa-caret-right'></i>}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={pageCount}
                                marginPagesDisplayed={0}
                                pageCount={pageCount}
                                previousLabel={<i className='fas fa-caret-left'></i>}
                                // previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        ) : ""}
                    </div>
                </div>
            </div>

        </div>


    )
}

export default AllPostLikeUser;