import React, { useState } from "react"
import * as Images from "../../../utilities/images";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavItem } from "react-bootstrap";
import { useAuthSelector } from "../../../redux/selector/auth";
import { useLocation, useNavigate } from "react-router-dom";
import AuthNav from "./authNav";
import { Link } from "react-router-dom";
import * as images from "../../../utilities/images";
import FadeInImage from "../../../utilities/FadeInImage";

const MainNav = () => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState();
    const location = useLocation();
    const authSelector = useAuthSelector();
    const checkAccount = authSelector?.userInfo?.user?.is_deactivate
    const [activeTab, setActiveTab] = useState("");
    const step = authSelector?.userInfo?.user?.step <= 6 || authSelector?.userStep?.step == 6;
    const button = authSelector?.userInfo?.user?.step == 6;
    const complete_step = authSelector?.userStep?.step == 6;
    const token = localStorage.getItem("authToken")
    const path = location.pathname;


    return ( !window?.ReactNativeWebView &&
        <>

            {token && step && button === true ?

                <AuthNav /> :
                <>
                    <section className="freeTrialSec">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="freeTrialInner">
                                        <p className="mb-0 text-center">Enhance your Small Business with a <b>30-day free trial</b>, special discounts and premium benefits. <Link to="/small-business" className="freeTrialLink">Get Started Now</Link></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className={`authNavBar navbarContain mainNavBar beforeLogin ${path === '/small-business' ? 'membershipAuthNav' : path === '/terms-of-service' ? 'membershipAuthNav' : path === '/privacy-policy' ? 'membershipAuthNav' : path === '/faqs' ? 'membershipAuthNav' : path === '/contact-us' ? 'membershipAuthNav' : path === '/membership-choose-per-role' ? 'membershipAuthNav' : path === '/enterprise-business' ? 'membershipAuthNav' : ''}`} >
                        <Navbar className={`${path == "/" ? "homePage" : ""}`} expand="lg">
                            <div className="container"  >
                                <Navbar.Brand href="/">
                                    <FadeInImage src={Images.logoWhite} alt="logo" className="img-fluid" />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0">
                                    <i className="las la-bars barIcon"></i>
                                </Navbar.Toggle>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="ms-auto">
                                        <Nav.Item>
                                            <div 
                                                onClick={() => {
                                                    setActiveTab('pricing')
                                                    navigate("/small-business", { state: { path: "#" } })
                                                }}
                                                className={`${activeTab === "pricing" ? "active" : ""}  nav-link cursor`}
                                            >Pricing</div>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <div 
                                                onClick={() => {
                                                    setActiveTab('enterprise')
                                                    navigate("/enterprise-business", { state: { path: "#" } })
                                                }}
                                                className={`${activeTab === "enterprise" ? "active" : ""}  nav-link cursor`}
                                            >Enterprise</div>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <div 
                                                onClick={() => {
                                                    setActiveTab('about')
                                                    navigate("/about", { state: { path: "#abouts" } })
                                                }}
                                                className={`${activeTab === "about" ? "active" : ""}  nav-link cursor`}
                                            >About</div>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Link to={`/#membership`}
                                                className={`${activeTab === "membership" ? "active" : ""}  nav-link`}
                                                onClick={() => { setActiveTab('membership') }}
                                            >Membership</Link>
                                        </Nav.Item> */}
                                        {/* <Nav.Item>
                                            <Link to={`/#buyers`}
                                                className={`${activeTab === "buyers" ? "active" : ""}  nav-link`}
                                                onClick={() => setActiveTab('buyers')}
                                            >Buyers</Link>
                                        </Nav.Item> */}
                                        {/* <Nav.Item>
                                            <Link to={`/#suppliers`}
                                                className={`${activeTab === "suppliers" ? "active" : ""}  nav-link`}
                                                onClick={() => setActiveTab('suppliers')}
                                            >Suppliers</Link>
                                        </Nav.Item> */}
                                        {/* <Nav.Item>
                                            <Link
                                                to={`/#opportunities`}
                                                className={`${activeTab === "opportunities" ? "active" : ""}  nav-link`}
                                                onClick={() => setActiveTab('opportunities')}
                                            >Opportunities</Link>
                                        </Nav.Item> */}
                                        {/* <Nav.Item>
                                            <Link to="/#community"
                                                className={`${activeTab === "community" ? "active" : ""}  nav-link`}
                                                onClick={() => setActiveTab('community')}
                                            >Community</Link>
                                        </Nav.Item> */}

                                        {token && step && button ?
                                            <Nav.Item>
                                                <Link to="/resources"
                                                    className={`${activeTab === "resources" ? "active" : ""}  nav-link`}
                                                    onClick={() => setActiveTab('resources')}
                                                >Resources</Link>
                                            </Nav.Item>
                                            :
                                            <Nav.Item>
                                                <Link to="/resource"
                                                    className={`${activeTab === "resources" ? "active" : ""}  nav-link`}
                                                    onClick={() => setActiveTab('resources')}
                                                >Resources</Link>
                                            </Nav.Item>
                                        }
                                        {token && step && button ?
                                            <div className="loginDetails ms-lg-4 ms-0">
                                                <NavItem>
                                                    {
                                                        checkAccount === 1 ?
                                                            <Nav.Link href="/settings/overview" className="primaryBtn">Dashboard</Nav.Link>
                                                            :
                                                            <Nav.Link href="/dashboard" className="primaryBtn">Dashboard</Nav.Link>
                                                    }
                                                </NavItem>
                                            </div> : button ?
                                                <div className="loginDetails  ms-0 d-block d-md-flex">
                                                    <Nav.Item>
                                                        <Nav.Link href="/login">Login </Nav.Link>
                                                    </Nav.Item>
                                                </div> : complete_step ?
                                                    <div className="loginDetails ms-lg-4 ms-0">
                                                        <NavItem>
                                                            {
                                                                checkAccount === 1 ?
                                                                    <Nav.Link href="/settings/overview" className="primaryBtn">Dashboard</Nav.Link>
                                                                    :
                                                                    <Nav.Link href="/dashboard" className="primaryBtn">Dashboard</Nav.Link>
                                                            }
                                                        </NavItem>
                                                    </div> :
                                                    <div className="loginDetails  ms-0 d-block d-md-flex loginButton">
                                                        <Nav.Item>
                                                            <Link to="/login">Login</Link>
                                                        </Nav.Item>
                                                    </div>
                                                
                                        }
                                        {!token && <button onClick={()=> {navigate('/small-business')}} className="getStartdHeader">Get Started</button>}
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </Navbar>
                    </div>
                    <div className={`authNavBar navbarContain mainNavBar d-none mobileMenu beforeMobileMenu  ${path === '/small-business' ? 'membershipAuthNav' : path === '/terms-of-service' ? 'membershipAuthNav' : path === '/privacy-policy' ? 'membershipAuthNav' : path === '/faqs' ? 'membershipAuthNav' : path === '/contact-us' ? 'membershipAuthNav' : path === '/membership-choose-per-role' ? 'membershipAuthNav' : path === '/enterprise-business' ? 'membershipAuthNav' : ''}`} >
                        <Navbar className={`${path == "/" ? "homePage" : ""}`} expand="lg" expanded={expanded}>
                            <div className="container "  >
                                <Navbar.Brand href="/">
                                    <FadeInImage src={Images.logoWhite} alt="logo" className="img-fluid" />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" onClick={() => { setExpanded(prev => !prev) }}>
                                    <i className="las la-bars barIcon"></i>
                                </Navbar.Toggle>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="ms-auto">
                                   <Nav.Item>
                                            <Link to={`/small-business`}
                                                className={`${activeTab === "membership" ? "active" : ""}  nav-link`}
                                                onClick={() => { setActiveTab('membership'); setExpanded(false) }}
                                            >Pricing</Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link to={`/enterprise-business`}
                                                className={`${activeTab === "membership" ? "active" : ""}  nav-link`}
                                                onClick={() => { setActiveTab('membership'); setExpanded(false) }}
                                            >Enterprise</Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link to="/about"
                                                className={`${activeTab === "about" ? "active" : ""}  nav-link`}
                                                // className={activeTab == 'about' ? 'active' : ''}
                                                onClick={() => { setActiveTab('about'); setExpanded(false) }}
                                            >About</Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Link to={`/#membership`}
                                                className={`${activeTab === "membership" ? "active" : ""}  nav-link`}
                                                onClick={() => { setActiveTab('membership'); setExpanded(false) }}
                                            >Membership</Link>
                                        </Nav.Item> */}
                                        {/* <Nav.Item>
                                            <Link to={`/#buyers`}
                                                className={`${activeTab === "buyers" ? "active" : ""}  nav-link`}
                                                onClick={() => { setActiveTab('buyers'); setExpanded(false) }}
                                            >Buyers</Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link to={`/#suppliers`}
                                                className={`${activeTab === "suppliers" ? "active" : ""}  nav-link`}
                                                onClick={() => { setActiveTab('suppliers'); setExpanded(false) }}
                                            >Suppliers</Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link
                                                to={`/#opportunities`}
                                                className={`${activeTab === "opportunities" ? "active" : ""}  nav-link`}
                                                onClick={() => { setActiveTab('opportunities'); setExpanded(false) }}
                                            >Opportunities</Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link to="/#community"
                                                className={`${activeTab === "community" ? "active" : ""}  nav-link`}
                                                onClick={() => { setActiveTab('community'); setExpanded(false) }}
                                            >Community</Link>
                                        </Nav.Item> */}

                                        {token && step && button ?
                                            <Nav.Item>
                                                <Link to="/resources"
                                                    className={`${activeTab === "resources" ? "active" : ""}  nav-link`}
                                                    onClick={() => { setActiveTab('resources'); setExpanded(false) }}
                                                >Resources</Link>
                                            </Nav.Item>
                                            :
                                            <Nav.Item>
                                                <Link to="/resource"
                                                    className={`${activeTab === "resources" ? "active" : ""}  nav-link`}
                                                    onClick={() => { setActiveTab('resources'); setExpanded(false) }}
                                                >Resources</Link>
                                            </Nav.Item>

                                        }
                                        {/* {token && step && button ?
                                            <Nav.Item>
                                                <Link to="/faqs"
                                                    className={`${activeTab === "faq" ? "active" : ""}  nav-link`}
                                                    onClick={() => { setActiveTab('faq'); setExpanded(false) }}
                                                >FAQs</Link>
                                            </Nav.Item>
                                            :
                                            <Nav.Item>
                                                <Link to="/faqs"
                                                    className={`${activeTab === "faq" ? "active" : ""}  nav-link`}
                                                    onClick={() => { setActiveTab('faq'); setExpanded(false) }}
                                                >FAQs</Link>
                                            </Nav.Item>

                                        } */}
                                        {/* {token && step && button ?
                                            <Nav.Item>
                                                <Link to="/contact-us"
                                                    className={`${activeTab === "contact" ? "active" : ""}  nav-link`}
                                                    onClick={() => { setActiveTab('contact'); setExpanded(false) }}
                                                >Contact</Link>
                                            </Nav.Item>
                                            :
                                            <Nav.Item>
                                                <Link to="/contact-us"
                                                    className={`${activeTab === "contact" ? "active" : ""}  nav-link`}
                                                    onClick={() => { setActiveTab('contact'); setExpanded(false) }}
                                                >Contact</Link>
                                            </Nav.Item>

                                        } */}
                                        <div className="mobileFooter">
                                            <div className="container">
                                                <div className="row align-items-center">
                                                    <div className="col-12">
                                                        <ul className="socialMenus ">
                                                            <li className="socialItems">
                                                                {
                                                                    checkAccount === 1 ?
                                                                        <a className="socialLinks" href="https://www.instagram.com/OppswellInc/" onClick={() => { setExpanded(false) }} target="_blank">
                                                                            <FadeInImage src={images.instaIcon} alt="Insta" />
                                                                        </a>
                                                                        :
                                                                        <a className="socialLinks" href="https://www.instagram.com/OppswellInc/" onClick={() => { setExpanded(false) }} target="_blank">
                                                                            <FadeInImage src={images.instaIcon} alt="Insta" />
                                                                        </a>
                                                                }
                                                            </li>
                                                            <li className="socialItems">
                                                                {
                                                                    checkAccount === 1 ?
                                                                        // <Link to="https://www.facebook.com/oppswellinc" className="socialLinks">
                                                                        <a className="socialLinks" href="https://www.facebook.com/oppswellinc" onClick={() => { setExpanded(false) }} target="_blank">
                                                                            <FadeInImage src={images.facebookIcon} alt="Facebook" />
                                                                        </a>
                                                                        // </Link>
                                                                        :
                                                                        <a className="socialLinks" href="https://www.facebook.com/oppswellinc" onClick={() => { setExpanded(false) }} target="_blank">
                                                                            {/* <Link to='https://www.facebook.com/oppswellinc' className="socialLinks"> */}
                                                                            <FadeInImage src={images.facebookIcon} alt="Facebook" />
                                                                            {/* </Link> */}
                                                                        </a>
                                                                }
                                                            </li>
                                                            <li className="socialItems">
                                                                {
                                                                    checkAccount === 1 ?
                                                                        <a className="socialLinks" href="https://www.linkedin.com/company/oppswell/" onClick={() => { setExpanded(false) }} target="_blank">
                                                                            {/* <Link to="https://www.linkedin.com/company/oppswell/" className="socialLinks"> */}
                                                                            <FadeInImage src={images.Linkedin} alt="LinkedIn" />
                                                                            {/* </Link> */}
                                                                        </a>
                                                                        :
                                                                        <a className="socialLinks" href="https://www.linkedin.com/company/oppswell/" onClick={() => { setExpanded(false) }} target="_blank">
                                                                            {/* <Link to='https://www.linkedin.com/company/oppswell/' className="socialLinks"> */}
                                                                            <FadeInImage src={images.Linkedin} alt="LinkedIn" />
                                                                            {/* </Link> */}
                                                                        </a>
                                                                }


                                                            </li>
                                                            <li className="socialItems">
                                                                {
                                                                    checkAccount === 1 ?
                                                                        <Link className="socialLinks" onClick={() => { setExpanded(false) }}>
                                                                            <FadeInImage src={images.Tiktok} alt="TikTok" />
                                                                        </Link>
                                                                        :
                                                                        <Link to='' className="socialLinks" onClick={() => { setExpanded(false) }}>
                                                                            <FadeInImage src={images.Tiktok} alt="TikTok" />
                                                                        </Link>
                                                                }
                                                            </li>
                                                            <li className="socialItems">
                                                                {
                                                                    checkAccount === 1 ?
                                                                        <a href="https://twitter.com/OppswellInc" onClick={() => { setExpanded(false) }} className="socialLinks" target="_blank">
                                                                            <FadeInImage src={images.twitterIcon} alt="Twitter" className="twitter" target="_blank" />
                                                                        </a>
                                                                        :
                                                                        <a href="https://twitter.com/OppswellInc" onClick={() => { setExpanded(false) }} to='' className="socialLinks" target="_blank">
                                                                            <FadeInImage src={images.twitterIcon} alt="Twitter" className="twitter" />
                                                                        </a>
                                                                }
                                                            </li>


                                                        </ul>
                                                    </div>
                                                    <div className="col-12 mt-5">
                                                        <Link to="/login" onClick={() => { setExpanded(false) }}>
                                                            <button className="mb-3 bgBlue__  w-100"><span className="me-3">Already a user? Login</span> <i className="las la-angle-right"></i></button>
                                                        </Link>
                                                        <Link to="/membership-choose-per-role" onClick={() => { setExpanded(false) }}>
                                                            <button className="primaryBtnOther w-100"><span className="me-3">Get Started for FREE</span> <i className="las la-angle-right"></i></button>
                                                        </Link>
                                                        <p className="thirtyDayTrial mt-3 text-center"><strong>30-day trial offer.</strong> <em>Limited time only!</em> </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {token && step && button ?
                                        <div className="loginDetails ms-lg-4 ms-0">
                                            <NavItem>
                                                {
                                                    checkAccount === 1 ?
                                                        <Nav.Link href="/settings/overview" className="primaryBtn">Dashboard</Nav.Link>
                                                        :
                                                        <Nav.Link href="/dashboard" className="primaryBtn">Dashboard</Nav.Link>
                                                }
                                            </NavItem>
                                        </div> : button ?
                                            <div className="loginDetails ms-lg-4 ms-0 d-block d-md-flex">
                                                <Nav.Item>
                                                    <Nav.Link href="/login"><b>Login Account</b></Nav.Link>
                                                </Nav.Item>
                                            </div> : complete_step ?
                                                <div className="loginDetails ms-lg-4 ms-0">
                                                    <NavItem>
                                                        {
                                                            checkAccount === 1 ?
                                                                <Nav.Link href="/settings/overview" className="primaryBtn">Dashboard</Nav.Link>
                                                                :
                                                                <Nav.Link href="/dashboard" className="primaryBtn">Dashboard</Nav.Link>
                                                        }
                                                    </NavItem>
                                                </div> :
                                                <div className="loginDetails ms-lg-4 ms-0 d-block d-md-flex loginButton">
                                                    <Nav.Item>
                                                        <Link to="/login"><b>Login Account</b></Link>
                                                    </Nav.Item>
                                                </div>
                                    } */}
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </Navbar>
                    </div>
                </>
            }
        </>
    )

}

export default MainNav;