import React from 'react'
import FadeInImage from '../../../utilities/FadeInImage';
import * as Images from "../../../utilities/images";

const UserCertificate = ({existingCertificate}) => {
    return (
        <>
            {existingCertificate?.length >
                0 ? (
                <div className="containerBoxright mt-3">
                    <div className="deviserCompany_">
                        <div className="currentTop">
                            <h4 className="headingTitle_">
                                <b>Certifications</b>
                            </h4>
                        </div>
                        <div className="certificationContainer_">
                            {existingCertificate
                                ?.length > 0 ? (
                                <>
                                    {existingCertificate
                                        ?.filter(
                                            (data) =>
                                                data?.existing_certificates_details?.name !==
                                                "Other"
                                        )
                                        ?.map((filteredData, index) => {
                                            return (
                                                <>
                                                    <div
                                                        className="posts justify-content-between mt-3"
                                                        key={index}
                                                    >
                                                        <FadeInImage
                                                            src={Images.certificateIcon_}
                                                            className="certifyBadge me-3"
                                                            alt="UserImage"
                                                        />
                                                        <div className="postsDetails">
                                                            <h5 className="headingTitleSmall_ d-flex     justify-content-between mb-1">
                                                                <span className="listHead_ cursor_text">
                                                                    {
                                                                        filteredData
                                                                            ?.existing_certificates_details
                                                                            ?.name
                                                                    }
                                                                    <span className="docText"></span>
                                                                </span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    )
}

export default UserCertificate