import React, { useState, useEffect } from "react";

const SubmitEndrosment = () => {
    return (
        <>
            <span className="text-center d-block">Acclamation Sent</span>
        </>
    )
}

export default SubmitEndrosment