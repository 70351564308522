import userSaga from "./user";
import { all, fork, spawn } from "redux-saga/effects";
import authSaga from "./auth";
import dashboardSaga from "./dashboard";
import notificationSaga from "./notification";
import connectionSaga from "./connection";
import advanceSearchSaga from "./advanceSearch";
import opportunitySaga from "./opportunity";
import archiveOpportunitySaga from "./opportunity/archiveOpportunity";
import documentSaga from "./documents";
import archiveDocumentSaga from "./documents/archiveDocuments";
import recentlyDeleteOpportunitySaga from "./opportunity/recentlyDeleteOpportunity";
import recentlyDeletedDocumentSaga from "./documents/recentlyDeleteDocuments";
import companyInformationSaga from "./companyInformation";
// import socketSaga from "./socket";
import messageSaga from "./messages";
import resourceSaga from "./resource";
import userContactUsSaga from "./contactus";
import userSupportSaga from "./support";
import manageUsersSaga from './mangeUsers'
import userCertification from "./certification";
import paymentPlanSaga from "./getPaymentPlan";
import stripeIntegrationSaga from "./stripeIntegration";

export default function* rootSaga() {
    yield all([
        spawn(userSaga),
        spawn(authSaga),
        spawn(dashboardSaga),
        spawn(notificationSaga),
        spawn(connectionSaga),
        spawn(advanceSearchSaga),
        spawn(opportunitySaga),
        spawn(archiveOpportunitySaga),
        spawn(documentSaga),
        spawn(archiveDocumentSaga),
        spawn(recentlyDeleteOpportunitySaga),
        spawn(recentlyDeletedDocumentSaga),
        spawn(companyInformationSaga),
        // spawn(socketSaga),
        spawn(messageSaga),
        spawn(resourceSaga),
        spawn(userContactUsSaga),
        spawn(userSupportSaga),
        spawn(manageUsersSaga),
        spawn(userCertification),
        spawn(paymentPlanSaga),
        spawn(stripeIntegrationSaga)
        // saga1 can also yield [ fork(actionOne), fork(actionTwo) ]
        // fork(saga2),
    ]);
}
