import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  diversityCertification: {},
  diversityOwned:{},
  loading: false,
};

export const certificationSlice = createSlice({
  name: "certification",
  initialState,
  reducers: {
    diversityCertification: (state) => {
      state.loading = true;
    },
    setDiversityCertification: (state, action) => {
      state.loading = false;
      state.diversityCertification = action.payload;
    },
    getDiversityCertification: (state) => {
      state.loading = true;
    },
    setGetDiversityCertification: (state, action) => {
      state.loading = false;
    },
    diversityOwned: (state) => {
      state.loading = true;
    },
    setDiversityOwned: (state, action) => {
      state.loading = false;
    },
    diverseStatusEmpty: (state) => {
      state.loading = true;
    },
    setDiverseStatusEmpty: (state, action) => {
      state.loading = false;
      state.diversityCertification = {};
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
    diversityCertification,
    setDiversityCertification,
    getDiversityCertification,
    setGetDiversityCertification,
    diversityOwned,
    setDiversityOwned,
    onErrorStopLoad,
    setDiverseStatusEmpty
} = certificationSlice.actions;

export default certificationSlice.reducer;
