import { useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";

export const useUserProfileNavigation = () => {
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const navigateToUserProfile = (name, id) => {
    let fixedname = name.replace("-null", "").replaceAll("/","").replaceAll("?","");
    fixedname = fixedname.replaceAll(" ", "");
    if (authSelector?.userInfo?.user?.id === id) {
      navigate(`/${fixedname}`);
      return;
    }
    navigate(`/${fixedname}/${id}`);
  };

  return navigateToUserProfile;
};

export const useUserNavigation = () => {
  const navigate = useNavigate();
  const authSelector = useAuthSelector();

  const getFixedName = (name) => name.replace("-null", "").replaceAll(" ", "").replaceAll("/","").replaceAll("?","");


  const navigateToUserProfile = (user, company = false) => {
    if (!user) return;
    const id = user?.id;
    const loggedCompanyInfo = authSelector?.companyProfileInfo?.userDetails?.is_invited !== 2
      ? authSelector?.companyProfileInfo?.userDetails?.user_company_information
      : authSelector?.companyProfileInfo?.userDetails?.user_selected_company_information?.user_company_information;

    let name;
    if (user?.business_type === 2) { // Only run if the business type is 2 
      name = user?.is_invited === 2
        ? user?.user_selected_company_information?.user_company_information?.company_name
        : user?.user_company_information?.company_name;
      const fixedname = getFixedName(name);
      navigate(authSelector?.userInfo?.user?.id === id ? `/${fixedname}` : `/${fixedname}/${id}`);
      return;
    }
    if (company !== "company" || company == 'user') { // run when business type is 1 and company is false 
      name = `${user?.user_profile?.first_name ?? ""}-${user?.user_profile?.last_name ?? ""}`;
      const fixedname = getFixedName(name);
      navigate(authSelector?.userInfo?.user?.id === id ? `/${fixedname}` : `/${fixedname}/${id}`);
      return;
    }

    const UserCompany = user?.is_invited === 2
      ? user?.user_selected_company_information?.user_company_information
      : user?.user_company_information;
    name = UserCompany.company_name;
    let companyId = UserCompany.id
    const fixedname = getFixedName(name);
    navigate(loggedCompanyInfo?.id === UserCompany.id ? `/company/${fixedname}` : `/company/${fixedname}/${companyId}`);
  };

  return navigateToUserProfile;
};

export const useUserCompanyNavigation = () => {
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const navigateToUserProfile = (name, id) => {
    name = name.replaceAll(" ", "-").replaceAll("/","").replaceAll("?","");

    // const loggedCompanyInfo = authSelector?.userInfo?.user?.is_invited === 2 ? authSelector?.userInfo?.user?.user_selected_company_information?.user_company_information : authSelector?.userInfo?.user?.user_company_information
    const loggedCompanyInfo =
      authSelector?.companyProfileInfo?.userDetails?.is_invited != 2
        ? authSelector?.companyProfileInfo?.userDetails
          ?.user_company_information
        : authSelector?.companyProfileInfo?.userDetails
          ?.user_selected_company_information?.user_company_information;
    if (loggedCompanyInfo?.id === id) {
      navigate(`/company/${name}`);
      return;
    }
    navigate(`/company/${name}/${id}`);
  };

  return navigateToUserProfile;
};

export const checkUrl = (value = "") => {
  const urlRegex =
    /\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/g;
  const urls = value.match(urlRegex) ?? "";

  for (let i = 0; i < urls.length; i++) {
    if (urls[i].startsWith("https://")) {
      continue;
    } else if (urls[i].startsWith("https:/")) {
      urls[i] = urls[i].replace("https:/", "https://");
    } else if (urls[i].startsWith("https:")) {
      urls[i] = urls[i].replace("https:", "https://");
    } else if (urls[i].startsWith("https")) {
      urls[i] = urls[i].replace("https", "https://");
    } else {
      urls[i] = "https://" + urls[i];
    }
  }
  return urls;
};
