import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../../redux/selector/auth";
import { getMemberShipPlans, getPaymentSession, getPaymentSessionUpgradeDegrade } from "../../../redux/slices/dashboard";
import * as Images from "../../../utilities/images";
import FadeInImage from "../../../utilities/FadeInImage";

const Plans = () => {

    const authSelector = useAuthSelector();
    const Businesstype = authSelector?.userInfo?.user?.business_type;

    const [newMembershipPlans, setNewMembershipPlans] = useState([]);
    const [memberShip, setMemberShip] = useState(null);
    
    const [upgradeStatus, setUpgradeStatus] = useState(null);
    
    const [totalMembershipPrice, setTotalMembershipPrice] = useState('');
    const [totalMembershipPriceInteger, setTotalMembershipPriceInteger] = useState(0);

    const [loading,setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (Businesstype) {
        getMemberShipPlansAction(Businesstype);
        }
    }, [Businesstype]);

    const getMemberShipPlansAction = (type) => {
        const params = {
        type: type,
        };

        dispatch(
        getMemberShipPlans({
            ...params,
            cb(res) {
            if (res.status == 200) {
                setNewMembershipPlans(res?.data?.payload);
            } else {
                toast.warning("Something went wrong");
            }
            },
        })
        );
    };

    //select membership
    const handleMembership = (event, plan) => {
        
        setMemberShip(event.target.value)

        // is_upgrade_downgrade == 1  for payment plan upgrade and   is_upgrade_downgrade == 2  for plan downgrade
        if(authSelector?.userInfo?.user?.membership_details?.later_cost > plan.later_cost){
            setUpgradeStatus(2)
            setTotalMembershipPrice(formatNumberToDollars(plan.later_cost ?? 0))
            setTotalMembershipPriceInteger(plan.later_cost ?? 0)
        }
        else{
            setUpgradeStatus(1)

            let expDate  = moment(authSelector?.userInfo?.user?.expiry_date)
            let dateNow  = moment()
          
            const duration = moment.duration(expDate.diff(dateNow)); // Calculate the duration between dates
            let months = duration.months(); // Get the difference in months
            let days = duration.days(); // Get the remaining days

            if(days>15){
                months++
            }

            let newCost = plan.later_cost
            
            if(months != 12){
                let monthlyCost = parseFloat((plan.later_cost / 12).toFixed(2)) 
                newCost =  plan.later_cost - Math.floor(monthlyCost*months)
            }

            setTotalMembershipPrice(formatNumberToDollars(newCost ?? 0))
            setTotalMembershipPriceInteger(newCost ?? 0)
        }
              
    }

    function formatNumberToDollars(number) {
        // Convert the number to a string
        const numberString = number.toString();
        // Add commas to the number for thousands
        const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // Add a "$" symbol in front of the formatted number
        return '$' + formattedNumber;
    }


    const handleProceedPayment = () => {

        setLoading(true)
        const currentUrl = window.location.href;
        const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        let params = {
            authentication_time: 15,
            amount: totalMembershipPriceInteger,
            description: upgradeStatus == 1 ? 'Upgrade Membership': 'Degrade Membership',
            returnUrl: `${currentUrl}?active=subscription`,
            cancelUrl: `${baseUrl}/paymentCancelled?fromInside=true`,
            timeoutUrl: `${baseUrl}/paymentCancelled?fromInside=true`,
            membership_id: memberShip,
            is_upgrade_downgrade: upgradeStatus
        }
        dispatch(getPaymentSessionUpgradeDegrade(({
            ...params, cb(res) {
                if (res.status == 200) {
                    window.location.href = res.data?.payload?.url ?? currentUrl
                }
                else {
                    toast.warning('Something went wrong')
                }
            }
        })))

    }

    return (
        <>
        <form className="registrationForm row mt-3">
            <div className="membershipRegistration_">
            {newMembershipPlans && newMembershipPlans.length > 0 ? (
                <>
                {newMembershipPlans.map((plan, index) => {
                    return (
                    <div className="checkmark_ memberCheck_ " key={index}>

                        {authSelector?.userInfo?.user?.membership_details?.id != plan.id ?
                        <>
                        <input
                            value={plan.id}
                            onChange={(e) => handleMembership(e, plan)}
                            checked={memberShip ? (memberShip == plan.id) : (authSelector?.userInfo?.user?.membership_details?.id == plan.id)}
                            type="radio"
                            id={`member${plan.id}`}
                            name="radio-group"
                            className="businessType_"
                        />
                         <label htmlFor={`member${plan.id}`} className="labelTxt_ cursor-pointer">
                            <div className="growMainbox_">
                                <div className="growbusinessLeft_">
                                <div className="growbusinessAbout_">
                                    <h3 className="memberblueHead_">
                                    {plan.name ?? ""}
                                    <div className="infoiconText">
                                        <FadeInImage
                                        className="info"
                                        src={Images.information}
                                        />
                                        <span className="showinfoText">
                                            <ul>
                                            <p className="m-0">
                                                {plan.name} Features:
                                            </p>
                                            {plan.extra_data.map((eData)=>{
                                                return (
                                                <li>
                                                    <span className="listHead_">
                                                        {eData.is_bold == 0 ?
                                                        <> 
                                                        {eData.info}
                                                        </>
                                                        :
                                                        <>
                                                        <strong>
                                                        {eData.info}
                                                        </strong>
                                                        </>
                                                        }
                                                    </span>
                                                </li>
                                                )
                                            })}
                                            </ul>
                                        </span>
                                    </div>
                                    </h3>
                                    <p className="memberblueSub_">
                                    {plan.description ?? ""}{" "}
                                    </p>
                                </div>
                                </div>
                                <div className="growbusinessRight_">
                                <p className="yearBadge text-end">
                                    {formatNumberToDollars(plan.later_cost)} / yearly
                                </p>
                                </div>
                            </div>
                        </label>
                        </> 
                        :
                        <>
                         <div className="growMainbox_ cursor-not-allowed">
                             <div className="growbusinessLeft_">
                             <div className="growbusinessAbout_">
                                 <h3 className="memberblueHead_">
                         
                                 {plan.name ?? ""} (Current plan)
                                 <div className="infoiconText">
                                     <FadeInImage
                                     className="info"
                                     src={Images.information}
                                     />
                                     <span className="showinfoText">
                                         <ul>
                                         <p className="m-0">
                                             {plan.name} Features:
                                         </p>
                                         {plan.extra_data.map((eData)=>{
                                             return (
                                             <li>
                                                 <span className="listHead_">
                                                     {eData.is_bold == 0 ?
                                                     <> 
                                                     {eData.info}
                                                     </>
                                                     :
                                                     <>
                                                     <strong>
                                                     {eData.info}
                                                     </strong>
                                                     </>
                                                     }
                                                 </span>
                                             </li>
                                             )
                                         })}
                                         </ul>
                                     </span>
                                 </div>
                                 </h3>
                                 <p className="memberblueSub_">
                                 {plan.description ?? ""}{" "}
                                 </p>
                             </div>
                             </div>
                             <div className="growbusinessRight_">
                             <p className="yearBadge text-end">
                                {formatNumberToDollars(plan.later_cost)} / yearly
                             </p>
                             </div>
                         </div>
                        </>
                        }

                       
                    </div>
                    );
                })}
                </>
            ) : null}
            </div>
            <div className="row justify-content-center my-3">
            {
            upgradeStatus && 
            <>
                {upgradeStatus ==  1 ? 
                    <>
                        <p><strong>Note: </strong>The plan will be upgraded until {moment(authSelector?.userInfo?.user?.expiry_date).format("MMMM D, YYYY")}, you have to pay {totalMembershipPrice} for remaining months </p>
                    </>
                :
                    <>
                        <p><strong>Note: </strong>The plan will start after your current plan</p>
                    </>
                }
            </>
            }
            <div className="col-md-6">
                {loading ?
                    <>
                        <div className='text-center mt-5'>
                            <div className="spinner-border text-success" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </>
                    :
                    <>
                    {memberShip && authSelector?.userInfo?.user?.membership_details?.id != memberShip && 
                        <button className="submitopportunity w-100" onClick={()=>{
                            handleProceedPayment()
                        }}>Pay {totalMembershipPrice}</button>
                    }
                    </>
                }
            </div>
            </div>
        </form>
        </>
    );
};

export default Plans;
