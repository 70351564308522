import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import * as Containers from "../app/containers";
import * as Layouts from "../app/layouts"
import PrivateCheck from "../app/layouts/PrivateCheck";


const Router = () => {
    const location = useLocation();
    const pathName = location.pathname;

    return (
        <>
            <Routes>
                <Route element={<Layouts.MainLayout />}>
                    <Route path="/" element={<Containers.Home />} />
                    <Route path="/about" element={<Containers.AboutUs />} />
                    <Route path="/membership-choose-per-role" element={<Containers.MemberShipChoosePerRole />} />
                    <Route path="/small-business" element={<Containers.Membership />} />
                    <Route path="/enterprise-business" element={<Containers.EnterpriseBusiness />} />
                    <Route path="/terms-of-service" element={<Containers.TermsCondition />} />
                    <Route path="/community-standards" element={<Containers.CommunityStandards />} />
                    <Route path="/privacy-policy" element={<Containers.PrivacyPolicy />} />
                    <Route path="/contact-us" element={<Containers.ContactUs />} />
                    <Route path="/faqs" element={<Containers.FrequentQuestion />} />
                    <Route path="/resource" element={<Containers.Resource />} />
                    <Route path="/certificate" element={<Containers.Certificate />} />
                    <Route path="/help-desk" element={<Containers.HelpDesk />} />
                    <Route path="/category-info/:id" element={<Containers.CategoryInfo />} />
                    <Route path="/category-data" element={<Containers.CategorySearchData />} />
                    <Route path="/article-info/:id" element={<Containers.ArticleInfo />} />
                    <Route path="/help-desk-question" element={<Containers.HelpDeskQuestion />} />
                    <Route path="/settings/cookies" element={<Containers.Cookies />} />

                </Route>

                <Route element={<Layouts.AuthLayout />}>
                    <Route element={<PrivateCheck auth={false} />}>
                        <Route path="/login" element={<Containers.Login />} />
                        <Route path="/signup" element={<Containers.Signup />} />
                        <Route path="/enterprise-signup" element={<Containers.EnterpriseSignUp />} />
                        <Route path="/forget-password" element={<Containers.ForgetPassword />} />
                        <Route path="/forget-password-verify" element={<Containers.ForgetPasswordVerification />} />
                        <Route path="/reset-password" element={<Containers.ResetPassword />} />
                        <Route path="/choose-roles-for=signup" element={<Containers.ChooseRoles />} />
                        <Route path="/verification" element={<Containers.Verification />} />
                        {/* <Route path="/business-need" element={<Containers.BusinessNeed />} /> */}
                        <Route path="/supplier-business-need" element={<Containers.supplierBusinessNeed />} />
                    </Route>

                    <Route element={<PrivateCheck auth={true} />}>
                        {/* {pathName === "/settings/overview" || pathName === "/settings/edit-profile" || pathName === "/settings/manage-subscription" || pathName === "/settings/manage-users" || pathName === "/settings/support" ? */}

                        {pathName === "/settings/overview" || pathName === "/settings/edit-profile" || pathName === "/settings/manage-subscription" || pathName === "/settings/support" || pathName === "/settings/cookies" || pathName === "/settings/manage-users" ?

                            <Route path="/:settings/:name" element={<Containers.Setting />} /> :
                            <>
                                <Route path="/featured-company" element={<Containers.CompanyfrontProfile />} />

                                <Route path="/company/:membercompanyname/:id" element={<Containers.CompanyfrontProfile />} />
                            </>
                        }
                        <Route path="/dashboard" element={<Containers.AccountDashboard />} />
                        <Route path="/dashboard/post/:id" element={<Containers.AccountDashboard />} />
                        <Route path="/registration" element={<Containers.Registration />} />
                        <Route path="/settings/edit-profile" element={<Containers.Setting />} />
                        <Route path="/settings" element={<Containers.Setting />} />
                        <Route path="/:user" element={<Containers.Users />} />
                        <Route path="/:user/:id" element={<Containers.Users />} />
                        <Route path="/notification" element={<Containers.Notifications />} />
                        <Route path="/suppliers-search" element={<Containers.Business />} />
                        <Route path="/business-search" element={<Containers.Business />} />
                        <Route path="/opportunities" element={<Containers.Business />} />
                        <Route path="/:opportunities/:id/:name" element={<Containers.Business />} />
                        <Route path="/industry-query" element={<Containers.Business />} />
                        <Route path="/view-profile" element={<Containers.ViewProfile />} />
                        <Route path="/resources" element={<Containers.Resources />} />
                        <Route path="/opportunity/:id/apply" element={<Containers.ApplyNow />} />
                        <Route path="/query/:id/apply" element={<Containers.ApplyNow />} />
                        <Route path="/apply/:id" element={<Containers.ApplyNow />} />
                        <Route path="/view-profile/:id" element={<Containers.ViewProfile />} />
                        <Route path="/opportunity/:id/overview" element={<Containers.ViewProfile />} />
                        <Route path="/query/:id/overview" element={<Containers.ViewProfile />} />
                        {/* <Route path="/resources" element={<Containers.Resources />} /> */}
                        <Route path="/company/:mycompanyname" element={<Containers.MyCompanyProfile />} />
                        <Route path="/all-connections" element={<Containers.AllConnections />} />
                        <Route path="/create-opportunities" element={<Containers.Opportunities />} />
                        <Route path="/create-industry-query" element={<Containers.Opportunities />} />
                        <Route path="/create-industry-query/:name" element={<Containers.Opportunities />} />
                        <Route path="/create-opportunities/:name" element={<Containers.Opportunities />} />
                        {/* <Route path="/:create-industry-query/:name" element={<Containers.Opportunities />} /> */}
                        <Route path="/document-view" element={<Containers.DocumentView />} />
                        <Route path="/post-opportunity" element={<Containers.PostOpportunity />} />
                        <Route path="/account-message" element={<Containers.AccountMessage />} />
                        {/* <Route path="/side-bar" element={<Containers.MessageSideBar />} /> */}
                        <Route path="/sent-message" element={<Containers.SentMessages />} />
                        <Route path="/trash" element={<Containers.Trash />} />
                        <Route path="/drafts" element={<Containers.Drafts />} />
                        <Route path="/message-requests" element={<Containers.MessageRequests />} />
                        <Route path="/account-message/:id" element={<Containers.Conversation />} />
                        <Route path="/edit-profile" element={<Containers.Editprofile />} />
                        <Route path="/certificate-application" element={<Containers.CertificateApplication />} />
                        <Route path="/certificate-step" element={<Containers.CertificateStep />} />
                        <Route path="/opportunity-list" element={<Containers.OpportunitiesList />} />

                    </Route>
                    <Route path="/payment-cancelled" element={<Containers.PaymentFailurePage />} />
                </Route>
            </Routes>
        </>
    );
}

export default Router;