import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import ReactCrop, { convertToPixelCrop } from "react-image-crop";
import FadeInImage from '../../../../utilities/FadeInImage';

const dogImg =
  'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'

const ImageCropper = (props) => {
  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedImage, setCroppedImage] = useState(null)
  const [DNone, setDnone] = useState(true)
  const containerRef = useRef(null)

  // useEffect(()=>{
  //   if (containerRef.current) {
  //     props?.setDisplayWidth(containerRef.current.clientWidth);
  //     props?.setDisplayHeight(containerRef.current.clientHeight);
  //   }
  // })
  const handleImageLoaded = (image) => {
    if (containerRef.current) {
      props?.setDisplayWidth(containerRef.current.clientWidth);
      props?.setDisplayHeight(containerRef.current.clientHeight);
    }
  };




  return (<>
    <div>
      {/* <div>
        {DNone ? <Cropper
          image={props?.imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={props?.onCropComplete}
          onZoomChange={setZoom}
        />: <></>}
        
      </div> */}
      <ReactCrop
        crop={crop}
        onComplete={props?.onCropComplete}
        onChange={(newCrop) => setCrop(newCrop)}
        aspect={props?.aspectRatio ?? 1}
      >
        <div className="d-flex justify-content-center align-items-center">
          <img src={props?.imageSrc} onLoad={handleImageLoaded} ref={containerRef} alt="image" className='corpImage_' />
        </div>

      </ReactCrop>

      {/* <FadeInImage src={croppedImageBlob ? URL.createObjectURL(croppedImageBlob) : ""}/> */}
    </div>
    {/* <button style={{position:'absolute', zIndex:"99", top:"-30px"}} onClick={()=>{makeClientCrop(props?.fileBlob,croppedAreaPixels);setDnone(false)}}>Done</button> */}
  </>


  )
}

export default ImageCropper;