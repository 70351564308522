import { lazy } from "react";
import Users from "./UserProfile/index"
const Home = lazy(() => import("./home"));
const Login = lazy(() => import("./auth/login"));
const ChooseRoles = lazy(() => import("./auth/chooseRoles"));
const Signup = lazy(() => import("./auth/signup"));
const BusinessNeed = lazy(() => import("./auth/businessNeed"));
const supplierBusinessNeed = lazy(() => import("./auth/supplierBusinessNeed"));
// const Test = lazy(() => import("./test"));
const AboutUs = lazy(() => import("./aboutUs"));
const AccountDashboard = lazy(() => import("./dashboard"));
const Registration = lazy(() => import("./registration"));
const Verification = lazy(() => import("./auth/verification"));
const Setting = lazy(() => import("./setting"));
const Notifications = lazy(() => import("./notification"));
const Business = lazy(() => import("./business"));
const ViewProfile = lazy(() => import("./business/viewProfile"));
const Membership = lazy(() => import("./membership"));
const MemberShipChoosePerRole = lazy(() => import("./membership/membershipChoosePerRole"));
const Resources = lazy(() => import("./resources"));
const TermsCondition = lazy(() => import("./termsCondition"));
const PrivacyPolicy = lazy(() => import("./privacyPolicy"));
const ContactUs = lazy(() => import("./contactUs"));
const FrequentQuestion = lazy(() => import("./frequentQuestion"));
const CompanyfrontProfile = lazy(() => import("./companyfrontProfile"));
const MyCompanyProfile = lazy(() => import("./myCompanyProfilePage"));
const ForgetPassword = lazy(() => import("./auth/forgetPassword"));
const ForgetPasswordVerification = lazy(() => import("./auth/forgetPasswordVerification"));
const ResetPassword = lazy(() => import("./auth/resetPassword"));
const AllConnections = lazy(() => import("./allConnections"));
const Opportunities = lazy(() => import("./opportunities"));
const DocumentView = lazy(() => import("./documentView"));
const PostOpportunity = lazy(() => import("./postOpportunity"));
const AccountMessage = lazy(() => import("./accountMessage"));
const EnterpriseBusiness = lazy(() => import("./membership/enterpriseBusiness"));
const Editprofile = lazy(() => import("./editProfile/editprofile"));
const Resource = lazy(() => import("./resources/resource"));
const Subscription = lazy(() => import("./membership/newSubscription"));
const PaymentFailurePage = lazy(() => import("./registration/paymentFailure/paymentFaliour"));
const Certificate = lazy(() => import("./certificate/index"));
const CertificateApplication = lazy(() => import("./certificationApp/index"));
const CertificateStep = lazy(() => import("./certificationApp/steps"));
const ApplyNow = lazy(() => import("./business/applyNow"));
const PrivacySettings = lazy(() => import("./privacySettings"));
const HelpDesk = lazy(() => import("./helpDesk/index"));
const CommunityStandards = lazy(() => import("./communityStandards"));
const OpportunitiesList = lazy(() => import("./opportunityList"));
const Conversation = lazy(() => import("./accountMessage/conversation"));
const SentMessages = lazy(() => import("./sentMessages"));
const Trash = lazy(() => import("./messageTrash"));
const Drafts = lazy(() => import("./messageDrafts"));
const MessageRequests = lazy(() => import("./messageRequests"));
const CategoryInfo = lazy(() => import("./categoryInfo"));
const CategorySearchData = lazy(() => import("./categorySearchData"));
const ArticleInfo = lazy(() => import("./articleInfo"));
const EnterpriseSignUp = lazy(() => import("./auth/enterpriseSignUp"));
const HelpDeskQuestion = lazy(() => import("./helpDeskQuesiton/help-desk-question"));
const Cookies = lazy(() => import("./cookies/cookies"));
// const Users = lazy(() => import("./UserProfile/index"));


export {
    Login,
    Home,
    ChooseRoles,
    Signup,
    // Test,
    AboutUs,
    AccountDashboard,
    Registration,
    Verification,
    Setting,
    Notifications,
    Business,
    ViewProfile,
    Membership,
    MemberShipChoosePerRole,
    Resources,
    TermsCondition,
    PrivacyPolicy,
    ContactUs,
    FrequentQuestion,
    CompanyfrontProfile,
    MyCompanyProfile,
    AllConnections,
    Opportunities,
    DocumentView,
    PostOpportunity,
    AccountMessage,
    BusinessNeed,
    supplierBusinessNeed,
    ForgetPassword,
    ForgetPasswordVerification,
    ResetPassword,
    EnterpriseBusiness,
    Editprofile,
    Resource,
    Subscription,
    PaymentFailurePage,
    Certificate,
    CertificateApplication,
    CertificateStep,
    ApplyNow,
    PrivacySettings,
    HelpDesk,
    CommunityStandards,
    OpportunitiesList,
    Conversation, 
    SentMessages,
    Trash,
    Drafts,
    MessageRequests,
    CategoryInfo,
    CategorySearchData,
    ArticleInfo,
    EnterpriseSignUp,
    HelpDeskQuestion,
    Cookies,
    Users
}