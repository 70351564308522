import React from 'react'

const SpinLoader = () => {
    return (
        <div className="row">
            <div className="col-sm-12 text-center pt-5 pb-5">
                <div
                    className="spinner-grow text-success"
                    role="status"
                ></div>
            </div>
        </div>
    )
}

export default SpinLoader